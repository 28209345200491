import { getUsers, createUser, getAllSeminaries, getSeminaryData } from "@api/Vocabulary";
import { useSeminariesService } from "../../services/SeminariesService";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import VocabularySeminaryCard from "./components/VocabularySeminaryCard/VocabularySeminaryCard";
import { language, languagesArrays, Quizzes } from "interfaces/Interfaces";
import LastQuizzes from "./components/LastQuizzes/LastQuizzes";
import "./VocabularyStyles.scss";
import { languagesEnglish, seminariesNumber } from "functions/DefaultValues";
import { StreakModal } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/StreakModal";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { BackArrowIcon, ClockIcon, ListIconVocabulary, StreakFlameIcon } from "assets/Imports";
import firebase from "firebase/compat/app";
import { StreakFlame } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/components/StreakFlame";
import Maintenance from "@components/Maintenance/Maintenance";
import SeminariesCardsContainer from "./components/SeminariesCardsContainer/SeminariesCardsContainer";
import { useStreakContext } from "contexts/VocabularyStreakContext";

interface propsI {
	user?: any;
}

function VocabularyContainer({ user }: propsI) {
	const [loading, setLoading] = useState<boolean>(true);
	const [seminaries, setSeminaries] = useState<Array<any>>(new Array());
	const [lastQuizzes, setLastQuizzes] = useState<Array<Quizzes>>(new Array());
	const [page, setPage] = useState<number>(0);
	const [maxPages, setMaxPages] = useState<number>(0);
	const [loadingLastQuizzes, setLoadingLastQuizzes] = useState<boolean>(true);
	const [totalHours, setTotalHours] = useState<number>(0);
	const [key, setKey] = useState<string>("");
	const [countRefresh, setCountRefresh] = useState<number>(0);
	const [words, setWords] = useState<languagesArrays>();
	const { isLoading, vocabularyStreak, studiedDays } = useStreakContext();

	const getKey = async () => {
		let tryKey = await firebase.functions().httpsCallable("getAudio");
		tryKey().then((res) => {
			setKey(res.data);
		});
	};

	const manageError = (error, where, message) => {
		console.log(error);
		if (window.confirm(message)) {
			switch (where) {
				case "seminaries":
					getSeminaries();
					break;
				case "createUser":
					createUser(user.id, user.name);
					break;
				default:
					break;
			}
		}
	};

	const getSeminaries = async (userId?) => {
		let temp: any = [];
		let errorMessage = "";
		let prevStateSeminary: any = seminaries;

		await getAllSeminaries(userId !== undefined ? userId : user.vocabularyUserId)
			.then(async (res) => {
				errorMessage =
					"Ha habido un error al cargar la información de los seminarios.\n¿Deseas intentar de nuevo?";

				const promises = res.seminaries.map(async (seminary, i) => {
					const res = await getSeminaryData(
						userId !== undefined ? userId : user.vocabularyUserId,
						seminary.seminario
					);
					const wordsArray = res.seminaries.map((word) => ({
						id: word.id,
						category: word.categoria.split("|"),
						singular_category: word.singular_category,
						sub_category: word.sub_categorias !== null ? word.sub_categorias.split("|") : [null],
						dominated: word.dominada,
						language: word.idioma,
						native_word: word.palabra,
						optional_word: word.palabra_opcional,
						meaning: word.significados.split("|"),
						word_id: word.word_id,
						warning: word.advertencia,
						times_answered: word.veces_respondida === null ? 0 : parseFloat(word.veces_respondida),
						times_correct_answer:
							word.veces_respondida_correcta === null
								? 0
								: parseFloat(word.veces_respondida_correcta),
						custom_difficulty: parseFloat(word.dificultadP),
						firstStudied: word.firstStudied,
						lastStudied: word.lastStudied,
						seminarios: word.seminarios,
						memory: word.memoria,
						grammar: word.gramatica,
						advice: word.consejo,
						fake_friend: word.falso_amigo,
						similarity: word.similitud,
					}));

					const wordsByLanguage = wordsArray.reduce((acc, word) => {
						acc[word.language] = acc[word.language] || [];
						acc[word.language].push(word);
						return acc;
					}, {});

					return {
						...seminary,
						french: wordsByLanguage.french || [],
						german: wordsByLanguage.german || [],
						italian: wordsByLanguage.italian || [],
						japanese: wordsByLanguage.japanese || [],
						chinese: wordsByLanguage.chinese || [],
						russian: wordsByLanguage.russian || [],
						english: wordsByLanguage.english || [],
						portuguese: wordsByLanguage.portuguese || [],
						allWords: Object.values(wordsByLanguage).flat(),
					};
				});

				const newSeminaries = await Promise.all(promises);

				setSeminaries([...prevStateSeminary, ...newSeminaries.sort(compareNum)]);
				setLoading(false);
				setCountRefresh(0);
			})
			.then(async () => {
				errorMessage =
					"Ha habido un error al cargar las últimas rondas de estudio.\n¿Deseas intentar de nuevo?";
				if (lastQuizzes.length === 0) {
					//  obtener los ultimos 18 rondas de estudio
					const q = query(
						collection(db, "quiz"),
						where("userId", "==", user.id),
						orderBy("createdAt", "desc"),
						limit(18)
					);
					const snap = await getDocs(q);

					if (snap.size > 0) {
						let temp = new Array();
						await Promise.all(
							snap.docs.map((quiz) => {
								temp.push({ id: quiz.id, ...quiz.data() });
							})
						);

						setMaxPages(Math.ceil(snap.size / 6) - 1);
						setLastQuizzes(temp);
					}
				}
			})
			.catch((error) => {
				console.log(error);
				manageError(error, "seminaries", errorMessage);
			});
	};

	function compareNum(a, b) {
		if (a.seminario < b.seminario) {
			return -1;
		}
		if (a.seminario > b.seminario) {
			return 1;
		}
		return 0;
	}

	const getTimes = async () => {
		try {
			let totalSeconds = 0;
			const promises = seminariesNumber.map(async (seminaryNum) => {
				const timeRef = doc(db, "quizTimes", `${user.id}-${seminaryNum}`);
				const snapDoc = await getDoc(timeRef);
				if (snapDoc.exists()) {
					const data = snapDoc.data();

					totalSeconds +=
						data.english +
						data.japanese +
						data.russian +
						data.italian +
						data.german +
						data.chinese +
						data.portuguese +
						data.french;
				}
			});

			await Promise.all(promises);

			setTotalHours(parseFloat((totalSeconds / 60 / 60).toFixed(1)));
		} catch (error) {
			console.error(error);
			// Manejar errores aquí si es necesario
		}
	};

	const handleNextPage = () => {
		setLoadingLastQuizzes(true);
		setPage(page + 1);
	};

	const handlePrevPage = () => {
		setLoadingLastQuizzes(true);
		setPage(page - 1);
	};

	const loadMore = async () => {
		if ((user.userType === 2 && seminaries.length < 61) || seminaries.length < 60) getSeminaries();
	};
	useEffect(() => {
		const fetchData = async () => {
			getKey();
			if (user.vocabularyUser === undefined || user.vocabularyUser === false) {
				try {
					const res = await createUser(user.id, user.name);

					// Actualizar el usuario solo si se creó correctamente y no está ya actualizado
					if (!user.vocabularyUser) {
						const userRef = doc(db, "users", user.id);
						const batch = writeBatch(db);
						batch.update(userRef, {
							vocabularyUser: true,
							vocabularyUserId: res.insertId,
						});
						await batch.commit();
					}
				} catch (error) {
					console.log(error);
					manageError(
						error,
						"createUser",
						"Ha habido un error al cargar tu información.\n¿Deseas intentar de nuevo?"
					);
					return;
				}
			}
			await Promise.all([getSeminaries(user.vocabularyUserId), getTimes()]);
		};
		fetchData();
	}, [user]);

	useEffect(() => {
		setLoadingLastQuizzes(false);
	}, [page]);

	useEffect(() => {
		if (countRefresh > 0) {
			getSeminaries();
			getTimes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countRefresh]);

	if (loading)
		return (
			<div
				style={{
					height: "60vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<div className="vocabulary-main-container">
			{/* <StreakModal show={streakModal} onHide={() => setStreakModal(false)} vocabularyStreak={studiedDays} /> */}
			<div className="header-container">
				<div className="title-and-time">
					<h1 className="d-flex align-items-center">
						<img src={ListIconVocabulary} alt="icono de lista" />
						Vocabulario{" "}
						<span
							style={{
								fontSize: "0.875rem",
								color: "#ca5050",
								alignSelf: "end",
							}}
						>
							BETA
						</span>
					</h1>
					<div className="time-container">
						<StreakFlame
							user={user}
							vocabularyStreak={vocabularyStreak}
							studiedDays={studiedDays}
							isLoading={isLoading}
						/>
						{/* <div className="streak-container" onClick={() => setStreakModal(true)}>
							<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
							{vocabularyStreak !== undefined && <h2>{vocabularyStreak.length}</h2>}
						</div> */}
						<h3>
							<img src={ClockIcon} alt="icono de relog" />
							{totalHours} {totalHours == 1 ? "hora" : "horas"}
						</h3>
						<p>Tiempo total de estudio</p>
					</div>
				</div>
				<p>
					En esta sección puedes estudiar el vocabulario de cada seminario y mejorar tus habilidades
					para cada idioma.
				</p>
			</div>
			{lastQuizzes.length > 0 && (
				<div className="last-quizzes-main-container">
					<div className="header d-flex justify-content-between mb-4 align-items-center">
						<div style={{ minWidth: 20 }}>
							{page > 0 && (
								<img
									src={BackArrowIcon}
									alt="arrow icon"
									style={{ cursor: "pointer" }}
									onClick={handlePrevPage}
								/>
							)}
						</div>
						<h2 className="m-0">Rondas de estudio más recientes</h2>
						<div style={{ minWidth: 20 }}>
							{page < maxPages && lastQuizzes.slice(6 * page, 6 * (page + 1)).length === 6 && (
								<img
									src={BackArrowIcon}
									alt="arrow icon"
									style={{ transform: "rotate(180deg)", cursor: "pointer" }}
									onClick={handleNextPage}
								/>
							)}
						</div>
					</div>
					<div className="last-quizzes-cards-container" style={{ minHeight: 300 }}>
						{!loadingLastQuizzes &&
							lastQuizzes.slice(6 * page, 6 * (page + 1)).map((quiz, index) => {
								return <LastQuizzes user={user} quiz={quiz} key={"last-quiz-component-" + index} />;
							})}
					</div>
				</div>
			)}
			{seminaries !== undefined && (
				<SeminariesCardsContainer
					seminaries={seminaries}
					gKey={key}
					setCountRefresh={setCountRefresh}
					countRefresh={countRefresh}
				/>
			)}
		</div>
	);
}

export default VocabularyContainer;
