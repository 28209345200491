import { blockDate, repeatQuiz } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { capitalizeFirstLetter, returnFlag } from "functions/Functions";
import { db } from "initFirebase";
import { finishedQuiz, Quizzes } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { getCorrectWord, getUniqueTotalWords, getUniqueTotalWordsArray, getWord } from "../Test/functions/TestFunctions";
import "./HistorySeminaryVocabulary.scss";
import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { getWordSeminaryInfo } from "@api/Vocabulary";
import { AiFillMinusCircle } from "react-icons/ai";
import { ClockIcon, CreateIcon, Trash } from "assets/Imports";
import { useVocabularySeminaryContext } from "contexts/VocabularySeminaryContext";

function HistorySeminaryVocabulary(props) {
	const [quiz, setQuiz] = useState<Quizzes>(props.quiz);
	const [name, setName] = useState<string>(quiz.name);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [editName, setEditName] = useState<boolean>(false);
	const [isBlocked, setIsBlocked] = useState<boolean>(false);

	// BLOCK DATES
	const date1: Date = blockDate("date1");
	const date2: Date = blockDate("date2");
	const date3: Date = blockDate("date3");

	const repeatIncorrects = () => {
		props.repeatIncorrects(quiz);
	};

	const modalEditName = async () => {
		setEditName(true);
		setModalShow(true);
	};

	const deleteNoDefaultQuiz = async (quizId) => {
		await deleteDoc(doc(db, "quiz", quizId));
	};

	const createNewDefault = async () => {
		const index = props.defaultQuizzes.findIndex((e) => e.name === quiz.name);
		if (index !== -1) {
			const copyQuiz = props.defaultQuizzes[index];

			const docRef = doc(db, "quiz", quiz.id);
			await updateDoc(docRef, {
				answerType: copyQuiz.answerType,
				difficult: new Array(),
				quizWords: copyQuiz.quizWords,
				userId: props.user.id,
				language: quiz.language,
				name: copyQuiz.name,
				seminaryNum: parseInt(copyQuiz.seminary),
				correctWords: new Array(),
				incorrectWords: new Array(),
				index: 0,
				actualSegment: 0,
				finished: false,
				createdAt: new Date(),
				finishedAt: null,
				default: true,
				finishedDefault: false,
				defaultVersion: copyQuiz.version,
				defaultId: copyQuiz.id,
				errorsWords: new Array(),
			}).then(() => {
				props.setTestId(quiz.id);
				props.setView(3);
			});
		} else {
			props.setView(4);
		}
	};

	useEffect(() => {
		const date = new Date(props.quiz.createdAt.seconds * 1000);

		if (date < date3 || (date < date1 && date > date2)) {
			setIsBlocked(true);
			if (quiz.default === undefined) {
				deleteNoDefaultQuiz(quiz.id);
			}
		}
		if (props.location.state !== undefined) {
			if (props.location.state.finished && props.location.state.lastQuiz === quiz.id) {
				setModalShow(true);
				props.location.state = undefined;
			}
		}
	}, []);

	return (
		<>
			<DetailsModal
				gKey={props.gKey}
				name={name}
				setName={setName}
				editName={editName}
				setEditName={setEditName}
				show={modalShow}
				onHide={() => setModalShow(false)}
				quiz={quiz}
				setQuiz={setQuiz}
				countRefresh={props.countRefresh}
				setCountRefresh={props.setCountRefresh}
			/>
			<div className="history-test-seminary-card">
				<div className="texts-container">
					<div className="title-container">
						<h3>
							{returnFlag(quiz.language)} {name}{" "}
							{!isBlocked && (
								<span>
									<img src={CreateIcon} alt="icono de pluma para editar" onClick={() => modalEditName()} />
								</span>
							)}
						</h3>
						<span>{new Date(quiz.createdAt.seconds * 1000).toLocaleDateString("es-ES")}</span>
					</div>
					<div className="info-test-row">
						<p>
							Estado:{" "}
							{!quiz.finished ? <span className="in-progress">En progreso</span> : <span className="completed">Completado</span>}
						</p>
					</div>
					<div className="info-test-row">
						<p>
							Calificación:{" "}
							{!quiz.finished ? "Aún sin calificar" : `${quiz.correctWords.length}/${getUniqueTotalWords(quiz.quizWords)}`}
						</p>
					</div>
					{!isBlocked && (
						<div className="info-test-row details" onClick={() => setModalShow(true)}>
							<p>Detalles de la ronda de estudio</p>
						</div>
					)}
				</div>
				{!isBlocked && (
					<div className="buttons-container">
						{!quiz.finished ? (
							<button className="primary-btn continue" onClick={() => props.continueTest(quiz.id)}>
								Continuar ronda
							</button>
						) : (
							<>
								{quiz.incorrectWords.length > 0 && (
									<button className="secondary-btn" style={{ width: 150, maxWidth: 150 }} onClick={() => repeatIncorrects()}>
										Repasar incorrectas
									</button>
								)}
								<button className="primary-btn" onClick={() => props.repeat(quiz)}>
									Reiniciar ronda
								</button>
							</>
						)}
						<div style={{ cursor: "pointer" }} onClick={() => props.deleteQuiz(quiz.id)}>
							<img src={Trash} alt="" />
						</div>
					</div>
				)}
				{isBlocked && (
					<div className="block-card-quiz">
						<h3 className="block-quiz-title">Esta ronda de estudio no está disponible de momento.</h3>
						<div className="d-flex justify-content-center flex-column align-items-center" style={{ gap: 5 }}>
							{!props.loadingDefault && (
								<button onClick={() => createNewDefault()} className="primary-btn">
									Crear nueva ronda
								</button>
							)}
							<div style={{ cursor: "pointer" }} onClick={() => props.deleteQuiz(quiz.id)}>
								<img src={Trash} alt="" />
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

function DetailsModal(props: any) {
	const [quiz, setQuiz] = useState<finishedQuiz>(props.quiz);
	const [load, setLoad] = useState<boolean>(false);
	const { words } = useVocabularySeminaryContext();

	const saveAndExit = async () => {
		if (quiz.name !== props.name) {
			const quizRef = doc(db, "quiz", quiz.id);

			await updateDoc(quizRef, {
				name: props.name,
			}).then(() => {
				props.quiz.name = props.name;
				props.setEditName(false);
				props.onHide();
				setLoad(false);
			});
		} else {
			props.setName(quiz.name);
			props.setEditName(false);
			props.onHide();
			setLoad(false);
		}
	};

	function comparePlace(a, b) {
		if (a.place < b.place) {
			return -1;
		}
		if (a.place > b.place) {
			return 1;
		}
		return 0;
	}
	const getUpdatedInfo = async () => {
		const segments = [0, 1, 2, 3, 4, 5, 6, 7];
		let tempQuiz = JSON.parse(JSON.stringify(props.quiz));
		await Promise.all(
			segments.map(async (actualSegment) => {
				await Promise.all(
					tempQuiz.quizWords[actualSegment].map(async (word, index) => {
						if (tempQuiz.quizWords[actualSegment].length > 0) {
							await getWordSeminaryInfo(word.id)
								.then(async (wordInfo: Array<any>) => {
									if (wordInfo.length > 0) {
										let infoData: any = {
											singular_category: wordInfo[0].singular,
											native_word: wordInfo[0].native_word,
											category: [wordInfo[0].family],
											meaning: wordInfo[0].meanings.split("|"),
											warning: wordInfo[0].warning,
											warning_type: wordInfo[0].warning_type,
											optional_word: wordInfo[0].optional_word,
										};

										tempQuiz.quizWords[actualSegment][index].meaning = infoData.meaning;
										tempQuiz.quizWords[actualSegment][index].category = infoData.category;
										tempQuiz.quizWords[actualSegment][index].singular_category = infoData.singular_category;
										tempQuiz.quizWords[actualSegment][index].native_word = infoData.native_word;
										tempQuiz.quizWords[actualSegment][index].optional_word = infoData.optional_word;
										tempQuiz.quizWords[actualSegment][index].warning = infoData.warning;
										tempQuiz.quizWords[actualSegment][index].warning_type = infoData.warning_type;
									}
								})
								.catch((error) => {
									console.log(error);
								});
						}
					})
				);
			})
		);

		setQuiz(tempQuiz);
		setLoad(false);
	};

	useEffect(() => {
		if (props.editName) {
			const element = document.getElementById("edit-name-label");

			if (element !== null) {
				element.click();
			}
		}
	}, [props.editName]);

	useEffect(() => {
		if (props.quiz !== undefined && props.show === true && load === false) {
			setLoad(true);
			getUpdatedInfo();
		}
	}, [props.quiz, props.show, words]);
	return (
		<Modal
			show={props.show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="details-seminary-vocabulary-modal"
			onHide={saveAndExit}
		>
			<Modal.Header>
				<div className="header-container">
					<div className="title">
						<div>
							<input
								type="text"
								id="quiz-name"
								name="quiz-name"
								value={props.name}
								onChange={(e) => props.setName(e.target.value)}
							/>
							<label htmlFor="quiz-name" id="edit-name-label" style={{ cursor: "pointer" }}>
								<img src={CreateIcon} alt="" />
							</label>
						</div>
						<p>Resumen de la ronda</p>
					</div>
					<div className="result">
						<h2 className="ml-auto">
							{quiz.correctWords.length}/{getUniqueTotalWords(quiz.quizWords)}
						</h2>
						<p>Califcación de la ronda</p>
						<p className="text-end ml-auto">
							<img src={ClockIcon} alt="imagen del reloj" />
							{Math.ceil(quiz.timeToAnswer / 60).toFixed(0) === "NaN" ? "0" : Math.ceil(quiz.timeToAnswer / 60).toFixed(0)}
							{parseInt(Math.ceil(quiz.timeToAnswer / 60).toFixed(0)) === 1 ? " minuto" : " minutos"}
						</p>
					</div>
				</div>

				<AiFillMinusCircle className="close-modal" onClick={saveAndExit} />
			</Modal.Header>
			<Modal.Body>
				<div className="title">
					<h2>Puntaje de la ronda</h2>
				</div>
				<div className="history-progress-word-row">
					<h3>{quiz.finished ? "Términos contestados" : "Número de términos"}</h3>
					<div className="words-progress-bar-container">
						<div className="words-progress-bar">
							<div className="words-progress" style={{ width: "100%" }}></div>
						</div>
						<p>{getUniqueTotalWords(quiz.quizWords)}</p>
					</div>
				</div>
				<div className="history-progress-word-row">
					<h3>Términos correctos</h3>
					<div className="words-progress-bar-container">
						<div className="words-progress-bar">
							<div
								className="words-progress"
								style={{ width: (quiz.correctWords.length / getUniqueTotalWords(quiz.quizWords)) * 100 + "%" }}
							></div>
						</div>
						<p>{quiz.correctWords.length}</p>
					</div>
				</div>
				<div className="history-progress-word-row">
					<h3>Términos incorrectos</h3>
					<div className="words-progress-bar-container">
						<div className="words-progress-bar">
							<div
								className="words-progress"
								style={{ width: (quiz.incorrectWords.length / getUniqueTotalWords(quiz.quizWords)) * 100 + "%" }}
							></div>
						</div>
						<p>{quiz.incorrectWords.length}</p>
					</div>
				</div>
				<div className="words-to-learn-container">
					<div className="title">
						<div className="title-containe">
							<h2>Términos de la ronda de estudio</h2>
						</div>
					</div>
					<div className="words-container">
						{getUniqueTotalWordsArray(quiz.quizWords).map((word, index) => {
							return (
								<WordRowCard
									word={word}
									language={props.quiz.language}
									answerType={props.quiz.answerType}
									index={index}
									gKey={props.gKey}
									key={"word-middle-screen-" + index}
								/>
							);
						})}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button onClick={saveAndExit}>Entendido</button>
			</Modal.Footer>
		</Modal>
	);
}

export default HistorySeminaryVocabulary;
