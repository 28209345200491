import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import firebase from "firebase/compat/app";
import chip from './images/chip-card.png'
import visaLogo from './images/visa-icon-1.png';
import mastercardLogo from './images/mastercard-logo.png';
import americanLogo from './images/american-express.png';
import poweredConekta from './images/powered_by_conekta.svg';
import securePayment from './images/Secure-Payment-Icon.png';
import $ from "jquery";
import './styles.scss'
import { ppid } from "process";
import InputMask from 'react-input-mask';
import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";
import { db } from "initFirebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { reduceEachTrailingCommentRange } from "typescript";
import { LogoBig } from "assets/Imports";

declare let window: any;

const conektaPB = process.env.REACT_APP_CONEKTA_PUBLIC_KEY;


function CheckoutForm(props) {


    const history = useHistory();
    const [firstPay, setFirstPay] = useState(false);
    const [gen, setGen]: any = useState(null)

    const user = useAuth().user;
    let userId;
    if (user !== null) {
        userId = getAuth().currentUser!.uid;
    }

    const initialStateValues = {
        name: props.name,
        email: props.email,
        phone: props.phone,
        cardName: props.name,
        cardNumber: "",
        exp_month: "",
        exp_year: "",
        cvc: ""
    };

    const estudiante = {
        name: props.name,
        email: props.email
    }
    const [cards, setCards] = useState([]);

    const isFirstPay = async () => {
        const q = await query(collection(db, "paymentHistory"), where("verified", "==", true), where("userId", '==', userId));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
            setFirstPay(true);
            getGen()
        }
    }

    const getGen = async () => {
        const q = await query(collection(db, "generations"), where("active", "==", true));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setGen(doc.data());
        })
    }

    useEffect(() => {
        window.Conekta.setPublicKey(conektaPB)
        if (user !== null) {
            let customerConektaInfo = firebase.functions().httpsCallable("getCustomer");
            customerConektaInfo({
                customerId: user.conektaId
            }).then((res) => {
                if (res.data.payment_sources !== undefined) {
                    setCards(res.data.payment_sources.data);
                }
                setLoading(false)
            })
            isFirstPay();
        }
    }, [])
    let dropdownCardValues: any = [];
    cards.forEach((card) => {
        dropdownCardValues.push(card)
    });


    const [values, setValues] = useState(initialStateValues);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState("Pago en una exhibición");
    const [selectedCard, setSelectedCard] = useState("new");
    const precio = props.precio;
    const [checked, setChecked] = useState(true);
    const [selectChecked, setSelectChecked] = useState("Sí");

    let checkedValues: any = [];
    let dropdownValues: any = [];
    if (props.msi == 6) {
        dropdownValues = ["Pago en una exhibición", "3 Meses sin intereses", "6 Meses sin intereses"];
    } else if (props.msi == 3) {
        dropdownValues = ["Pago en una exhibición", "3 Meses sin intereses"];
    }


    checkedValues = ["Sí", "No"]

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const successCallback = async (token) => {

        let msi;

        if (selected == "3 Meses sin intereses") {
            msi = 3;
        } else if (selected == "6 Meses sin intereses") {
            msi = 6;
        } else {
            msi = 0;
        }



        const client = {
            name: values.name,
            email: values.email,
            phone: values.phone
        }

        estudiante.email = client.email;

        let cardId;

        if (user !== null) {
            const tryNewCard = await firebase.functions().httpsCallable("newCard");
            await tryNewCard({
                customerId: user.conektaId,
                token_id: token.id
            }).then((res) => {
                cardId = res.data;
            })
        }

        let tryToPay = await firebase.functions().httpsCallable("payConecta");
        tryToPay({
            client: user !== null ? user : client,
            token: user !== null ? cardId : token,
            msi: msi,
            nombre: props.nombre,
            precio: precio
        }).then(async (res) => {
            if (res.data.object == "error") {
                if (res.data.details[0].code == "conekta.errors.processing.bank.insufficient_funds") {
                    alert("Fondos insuficientes, intente con otro método de pago.");
                } else if (res.data.details[0].code == "conekta.errors.processing.bank.declined") {
                    alert("Tarjeta declinada, favor de contactarse con su banco para mayor información.")
                } else if (res.data.details[0].code == "conekta.errors.parameter_validation.charge.monthly_installments_not_supported_for_debit") {
                    alert("Tarjetas de debito no permiten meses sin intereses.")
                } else if (res.data.details[0].code == "conekta.errors.processing.charge.card_payment.suspicious_behaviour") {
                    alert("Pago declinado favor de verificar sus datos o intentar con otro método de pago")
                } else if (res.data.details[0].code.indexOf("conekta.errors.parameter_validation.customer_info") !== -1) {
                    alert("Hace falta nombre, correo o celular.")
                } else {
                    alert("Hubo un error con el pago, favor de intentar con otro método de pago.")
                }
                setRefresh(false)
            } else {
                setValidated(false);
                setRefresh(!refresh)
                if (user !== null) {
                    const date = new Date(Date.now());
                    const payAt = new Date(Date.now());
                    const data = {
                        name: user.name,
                        amount: precio / 100,
                        date: payAt.valueOf(),
                        image: "",
                        concept: "Pago módulos",
                        verified: true,
                        medio: "Tarjeta",
                        createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
                        email: estudiante.email,
                        checked: true,
                        userId: userId,
                        orderId: res.data.id
                    }
                    await db.collection("paymentHistory").add(data);
                    await db.collection("notifications").add({
                        name: user.name,
                        text: "Pago automatizado por Conekta de $" + data.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " MXN",
                        date: new Date(),
                        userId: "admin",
                        userType: 1,
                        seen: false,
                        profileColor: user.color,
                        type: "conektaAutomaticPayment",
                        sectionId: "NYKrjrHTHAj4Cp1KF32A"
                    });
                    if (firstPay === true) {
                        const splitGenDate = gen.initialDate.split('/')
                        const date = new Date();
                        const q = query(collection(db, 'users'), where('generationId', '==', gen.id));
                        const querySnapshot = await getDocs(q);
                        date.setDate(splitGenDate[0]);
                        date.setMonth(splitGenDate[1] - 1);
                        date.setFullYear(splitGenDate[2]);

                        let num = querySnapshot.size + 1;

                        let matricula = '';
                        let year = "" + date.getFullYear();

                        if (num < 10) {
                            if (date.getMonth() < 9) {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "00" + num;
                            } else {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "00" + num;
                            }
                        } else if (num >= 10 && num < 100) {
                            if (date.getMonth() < 9) {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "0" + num;
                            } else {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "0" + num;
                            }
                        } else {
                            if (date.getMonth() < 9) {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "" + num;
                            } else {
                                matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "" + num;
                            }
                        }

                        let isDifferent = false;

                        do {
                            const q0 = query(collection(db, 'users'), where('matricula', '==', matricula));
                            const querySnapshot0 = await getDocs(q0);
                            if (querySnapshot0.size > 0) {
                                matricula = "" + (parseInt(matricula) + 1);
                            } else {
                                isDifferent = true;
                            }
                        } while (isDifferent === false);


                        await db.collection('users').doc(userId).update({
                            generationId: gen.id,
                            matricula: matricula,
                            schedule: gen.schedule,
                            addedAt: new Date()
                        });
                    }
                }
                setTimeout(() => history.push({
                    pathname: "/gracias",
                    state: {
                        order: res.data,
                        student: estudiante,
                        firstPay: firstPay
                    }
                }), 1000);
            }
        })
    };
    const errorCallback = (data) => {
        alert(data.message_to_purchaser);
        setRefresh(false);
    };

    const handleSubmit = async (e) => {
        setRefresh(true);
        const form = e.currentTarget;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            if (selectedCard === "new") {
                const card = {
                    card: {
                        number: values.cardNumber,
                        name: values.cardName,
                        exp_year: values.exp_year,
                        exp_month: values.exp_month,
                        cvc: values.cvc
                    }
                }

                
                await window.Conekta.Token.create(card, successCallback, errorCallback);
            } else {
                let msi;

                if (selected == "3 Meses sin intereses") {
                    msi = 3;
                } else if (selected == "6 Meses sin intereses") {
                    msi = 6;
                } else {
                    msi = 0;
                }
                const tryNewCard = await firebase.functions().httpsCallable("newDefaultCard");
                await tryNewCard({
                    customerId: user.conektaId,
                    card_id: selectedCard
                }).then(async (res) => {
                    let tryToPay = await firebase.functions().httpsCallable("payConecta");
                    tryToPay({
                        client: user,
                        token: selectedCard,
                        msi: msi,
                        nombre: props.nombre,
                        precio: precio
                    }).then(async (res) => {
                        if (res.data.object == "error") {
                            if (res.data.details[0].code == "conekta.errors.processing.bank.insufficient_funds") {
                                alert("Fondos insuficientes, intente con otro método de pago.");
                            } else if (res.data.details[0].code == "conekta.errors.processing.bank.declined") {
                                alert("Tarjeta declinada, favor de contactarse con su banco para mayor información.")
                            } else if (res.data.details[0].code == "conekta.errors.parameter_validation.charge.monthly_installments_not_supported_for_debit") {
                                alert("Tarjetas de debito no permiten meses sin intereses.")
                            } else if (res.data.details[0].code == "conekta.errors.processing.charge.card_payment.suspicious_behaviour") {
                                alert("Pago declinado favor de verificar sus datos o intentar con otro método de pago")
                            } else if (res.data.details[0].code.indexOf("conekta.errors.parameter_validation.customer_info") !== -1) {
                                alert("Hace falta nombre, correo o celular.")
                            } else {
                                alert("Hubo un error con el pago, favor de intentar con otro método de pago.")
                            }
                            setRefresh(false)
                        } else {
                            setValidated(false);
                            setRefresh(!refresh)
                            estudiante.email = values.email;
                            if (user !== null) {
                                const date = new Date(Date.now());
                                const payAt = new Date(Date.now());
                                const data = {
                                    name: user.name,
                                    amount: precio / 100,
                                    date: payAt.valueOf(),
                                    image: "",
                                    concept: props.nombre,
                                    verified: true,
                                    medio: "Tarjeta",
                                    createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
                                    email: estudiante.email,
                                    userId: userId,
                                    checked: true,
                                    orderId: res.data.id
                                }
                                await db.collection("paymentHistory").add(data);
                                await db.collection("notifications").add({
                                    name: user.name,
                                    text: "Pago automatizado por Conekta de $" + data.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " MXN",
                                    date: new Date(),
                                    userId: "admin",
                                    userType: 1,
                                    seen: false,
                                    profileColor: user.color,
                                    type: "conektaAutomaticPayment",
                                    sectionId: "NYKrjrHTHAj4Cp1KF32A"
                                });
                                if (firstPay === true) {
                                    const splitGenDate = gen.initialDate.split('/')
                                    const date = new Date();
                                    const q = query(collection(db, 'users'), where('generationId', '==', gen.id));
                                    const querySnapshot = await getDocs(q);
                                    date.setDate(splitGenDate[0]);
                                    date.setMonth(splitGenDate[1] - 1);
                                    date.setFullYear(splitGenDate[2]);

                                    let num = querySnapshot.size + 1;

                                    let matricula = '';
                                    let year = "" + date.getFullYear();

                                    if (num < 10) {
                                        if (date.getMonth() < 9) {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "00" + num;
                                        } else {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "00" + num;
                                        }
                                    } else if (num >= 10 && num < 100) {
                                        if (date.getMonth() < 9) {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "0" + num;
                                        } else {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "0" + num;
                                        }
                                    } else {
                                        if (date.getMonth() < 9) {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "0" + (date.getMonth() + 1) + "" + num;
                                        } else {
                                            matricula = year[2] + "" + year[3] + "" + date.getDate() + "" + (date.getMonth() + 1) + "" + num;
                                        }
                                    }

                                    let isDifferent = false;

                                    do {
                                        const q0 = query(collection(db, 'users'), where('matricula', '==', matricula));
                                        const querySnapshot0 = await getDocs(q0);
                                        if (querySnapshot0.size > 0) {
                                            matricula = "" + (parseInt(matricula) + 1);
                                        } else {
                                            isDifferent = true;
                                        }
                                    } while (isDifferent === false);

                                    await db.collection('users').doc(userId).update({
                                        generationId: gen.id,
                                        matricula: matricula,
                                        schedule: gen.schedule,
                                        addedAt: new Date()
                                    });
                                }
                            }
                            setTimeout(() => history.push({
                                pathname: "/gracias",
                                state: {
                                    order: res.data,
                                    student: estudiante,
                                    firstPay: firstPay
                                }
                            }), 1000);
                        }
                    })
                })
            }
        }


    };

    useEffect(() => {
        if (!refresh) return setLoading(false);
        setLoading(!loading);
    }, [refresh])

    const handleSelect = (e) => {
        setSelected(e);
    }
    const handleSelectCard = (e) => {
        const { value } = e.target;
        if (value === "new") {
            setSelectedCard(value);
        } else {
            setSelectedCard(value)
        }
    }

    const handleChecked = (e) => {
        if (e == "Sí") {
            setChecked(true);
            setSelectChecked(e);
            values.cardName = initialStateValues.cardName;
            values.email = initialStateValues.email;
        } else {
            setChecked(false)
            setSelectChecked(e)
            values.cardName = "";
            values.email = "";
        }
    }



    return (
        <div className="d-flex flex-column mt-5 checkout-main-container" style={{ marginBottom: "80px", flexWrap: "wrap", width: "90%", marginRight: "auto", marginLeft: "auto" }}>
            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: "2rem", flexWrap: "wrap" }}>
                <h1>Proceso de compra</h1>
                <h2 className="total-pay-card">Total a pagar: <a>{props.precio / 100}</a></h2>
            </div>
            <div className="d-flex form-card-container" style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                <Form className="w-50" noValidate validated={validated} onSubmit={handleSubmit} id="card-form">
                    <div className="visible-card">
                        <div className="card" style={{ borderRadius: "15px" }}>
                            <p className="top-left-card">Academia de Lenguas</p>
                            <div className="red-circle"></div>
                            <div className="yellow-circle"></div>
                            <img src={chip} alt="" />
                            <InputMask className="card-number" placeholder="XXXX&emsp;XXXX&emsp;XXXX&emsp;XXXX" mask='9999 9999 9999 99999' maskChar="" value={values.cardNumber} disabled></InputMask>
                            <input className="input-name" placeholder="Nombre" value={values.cardName} type="text" disabled />
                            <input className="expire-date" placeholder="mm/yy" value={values.exp_month + "/" + values.exp_year} type="text" disabled />
                        </div>
                        <div className="cards-icons">
                            <img className="icon" src={visaLogo} alt="" />
                            <img className="icon" src={mastercardLogo} alt="" />
                            <img className="icon" src={americanLogo} alt="" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <span className="ml-1" style={{ fontWeight: 500 }}>¿El nombre del estudiante es el mismo que el titular de tarjeta?</span>
                        <DropdownButton
                            alignRight
                            title={selectChecked}
                            id="dropdown-menu-align-right"
                            onSelect={handleChecked}
                            style={{ marginTop: ".5rem" }}
                        >
                            {checkedValues.map((item, index) => (
                                <Dropdown.Item eventKey={item} className="w-100">{item}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>

                    {!checked && (
                        <>
                            <Row className="input-row">
                                <Col className="w-100 p-0">
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>
                                            <b>Nombre completo</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInputChange}
                                            type="text"
                                            name="cardName"
                                            value={values.cardName}
                                            placeholder="Luke Skywalker"
                                            data-conekta="card[name]"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa un nombre.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="input-row">
                                <Col className="w-100 p-0">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            <b>Correo electrónico</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInputChange}
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            placeholder="ejemplo@ejemplo.com"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa un número de tarjeta válido.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className="input-row">
                        <Col className="w-100 p-0">
                            <label style={{ fontWeight: 'bolder' }} htmlFor="">Seleccionar tarjeta</label>
                            <select
                                className="select-card"
                                name="card"
                                style={{ borderRadius: "10px" }}
                                onChange={handleSelectCard}
                            >
                                <option value="new">Nueva</option>
                                {

                                    dropdownCardValues.map((pm) => (
                                        <option value={pm.id}>
                                            {pm.brand} •••• {pm.last4}
                                        </option>
                                    ))
                                }
                            </select>
                        </Col>
                    </Row>
                    {selectedCard === "new" && (
                        <>
                            <Row className="input-row">
                                <Col className="w-100 p-0">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            <b>Número de tarjeta</b>
                                        </Form.Label>
                                        <InputMask
                                            required
                                            className="form-control"
                                            onChange={handleInputChange}
                                            type="text"
                                            name="cardNumber"
                                            value={values.cardNumber}
                                            placeholder="4444 2222 3333 8888"
                                            mask="9999 9999 9999 9999 999"
                                            maskChar=""
                                        >
                                        </InputMask>

                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa un número de tarjeta válido.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="input-row">
                                <Col className="w-100 pr-3 pl-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>
                                            <b>Mes de expiración</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInputChange}
                                            type="number"
                                            name="exp_month"
                                            value={values.exp_month}
                                            placeholder="09"
                                            data-conekta="card[exp_month]"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa un número de 2 dígitos.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col className="w-100 pl-3 pr-3" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>
                                            <b>Año de expiración</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInputChange}
                                            type="number"
                                            name="exp_year"
                                            value={values.exp_year}
                                            placeholder="25"
                                            data-conekta="card[exp_year]"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Porf avor ingresa un número de 2 dígitos.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col className="w-100 pl-3 pr-0" style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>
                                            <b>CVC</b>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            onChange={handleInputChange}
                                            type="text"
                                            maxLength={4}
                                            name="cvc"
                                            value={values.cvc}
                                            placeholder="345"
                                            data-conekta="card[cvc]"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor, ingresa un número de 3 o 4 dígitos.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className="input-row">
                        <Col className="w-100 p-0">
                            <Form.Group >
                                <Form.Label>
                                    <b>Meses sin intereses</b>
                                </Form.Label>
                                <DropdownButton
                                    alignRight
                                    title={selected}
                                    id="dropdown-menu-align-right"
                                    onSelect={handleSelect}
                                >
                                    {dropdownValues.map((item, index) => (
                                        <Dropdown.Item eventKey={item} className="w-100">{item}</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="card-subscription-container">
                    <div className="caracteristicas-card" style={{ position: 'relative' }}>
                        <h3>Academia de Lenguas Internacionales</h3>
                        <h3 className="title-card-sub">{props.nombre}</h3>
                        <img src={LogoBig} style={{ position: 'absolute', right: 0, width: 250 }} />
                        {/* <ul>
                            <li>Costo por seminario:</li>
                            <li className="m-0">• {props.costo}.</li>
                            <li>Duración del programa:</li>
                            <li className="m-0">• {props.duracion}.</li>
                            {props.pagos !== "" && (
                                <li className="">• {props.pagos}.</li>
                            )}
                        </ul>
                        <table className="card-table">
                            <tr>
                                <th scope="col">Servicio</th>
                                <th scope="col">Precio</th>
                            </tr>
                            <tr>
                                <td scope="row">{props.nombre}</td>
                                <td className="text-right">{props.inversion}</td>
                            </tr>
                        </table>
                        <table className="card-table-2">
                            <tr className="d-flex justify-content-between">
                                <th>Total:</th>
                                <th className="">{props.inversion}</th>
                            </tr>
                        </table>
                        <div className="checkout-secure-image-container">
                            <img src={securePayment} alt="" />
                        </div>
                        <div className="checkout-conekta-image-container">
                            <img src={poweredConekta} alt="" />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="button__container text-center" style={{ height: "55px", marginTop: "30px" }}>
                {!loading && <button className="tertiary checkout-inscribirme-btn" type="submit" onClick={handleSubmit}>Inscribirme</button>}
                {loading &&
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
            </div>
        </div>
    );
}

export default CheckoutForm;