import React, { useState, useRef, useEffect, useMemo } from "react";
import { ReactComponent as ArrowDown } from "@assets/img/ArrowDownModules.svg";
import { ReactComponent as ArrowUp } from "@assets/img/ArrowUpModules.svg";
import VocabularySeminaryCard from "../VocabularySeminaryCard/VocabularySeminaryCard";
import { useAuth } from "@hooks/useAuth";
import { useSeminariesService } from "@services/SeminariesService";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { romanize } from "functions/Functions";
import PercentageBar from "@components/PercentageBar/PercentageBar";

interface SeminariesCardsContainerPropsI {
	seminaries: Array<any>;
	gKey: any;
	countRefresh: number;
	setCountRefresh: React.Dispatch<React.SetStateAction<number>>;
}

const SeminariesCardsContainer = ({
	gKey,
	seminaries,
	setCountRefresh,
	countRefresh,
}: SeminariesCardsContainerPropsI) => {
	const { user } = useAuth();
	const { paidSeminaries, nextSeminaries }: any = useSeminariesService();
	const lastElementRef = useRef(null); // Ref for the last element
	const [loading, setLoading] = useState<boolean>(false);
	const [openSeminaryCard, setOpenSeminaryCard] = useState<{ [key: number]: boolean }>({});
	const [SeminarioZero, setSeminarioZero] = useState<any>(null);

	function isNextClass(seminaryNum: number) {
		if (user.userType === 2) {
			return false;
		} else if (nextSeminaries.length === 0) {
			return false;
		} else if (seminaryNum > nextSeminaries[0].num) {
			return true;
		} else {
			return false;
		}
	}

	const InfoModules = [
		{ number: 1, startSeminary: 1, endSeminary: 10 },
		{ number: 2, startSeminary: 11, endSeminary: 25 },
		{ number: 3, startSeminary: 26, endSeminary: 40 },
		{ number: 4, startSeminary: 41, endSeminary: 50 },
		{ number: 5, startSeminary: 51, endSeminary: 60 },
	];
	function shouldBlockOrNextClass(moduleSeminaries: any[]) {
		let block = false;
		let nextClass = false;

		for (const seminary of moduleSeminaries) {
			block = seminary.seminario > paidSeminaries && user.userType !== 2;
			nextClass = isNextClass(seminary.seminario);

			if (block || nextClass) {
				break; // Salir del bucle si se cumple la condición
			}
		}

		return { block, nextClass };
	}
	useEffect(() => {
		const seminario0 = seminaries.find((seminary) => seminary.seminario === 0);
		setSeminarioZero(seminario0);
		InfoModules.forEach((module) => {
			const filterSeminariesByModules = seminaries.filter((seminary) => {
				return (
					seminary.seminario >= module.startSeminary && seminary.seminario <= module.endSeminary
				);
			});

			const { block, nextClass } = shouldBlockOrNextClass(filterSeminariesByModules);

			if (block || nextClass) {
				setOpenSeminaryCard((prev) => ({ ...prev, [module.number]: true }));
			}
		});
	}, [seminaries, paidSeminaries, nextSeminaries, user.userType]);

	const filteredModules = useMemo(() => {
		return InfoModules.map((module) => {
			const filterSeminariesByModules = seminaries.filter((seminary) => {
				return (
					seminary.seminario >= module.startSeminary && seminary.seminario <= module.endSeminary
				);
			});

			const totalWordsForModule = filterSeminariesByModules.reduce((total, seminario) => {
				return total + parseInt(seminario.total_palabras, 10);
			}, 0);

			const AllDominated = filterSeminariesByModules.reduce((total, seminario) => {
				return (
					total +
					seminario.allWords.filter((word) => word.dominated === 3 && word.dominated !== null)
						.length
				);
			}, 0);

			return {
				module,
				filterSeminariesByModules,
				totalWordsForModule,
				AllDominated,
			};
		});
	}, [InfoModules, seminaries]);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "33px" }}>
			{SeminarioZero && (user.userType === 2 || user.userType === 4) && (
				<div>
					<VocabularySeminaryCard
						block={SeminarioZero.seminario > paidSeminaries && user.userType !== 2 ? true : false}
						seminary={SeminarioZero}
						nextClass={isNextClass(SeminarioZero.seminario)}
						gKey={gKey}
						setCountRefresh={setCountRefresh}
						countRefresh={countRefresh}
						lastElementRef={lastElementRef}
					/>
				</div>
			)}
			{filteredModules.map((filteredModule) => (
				<div
					key={filteredModule.module.number}
					className={`vocabulary-seminary-cards-container`}
					style={{ display: "grid", gap: "20px", position: "relative", minHeight: "200px" }}
					onClick={() => {
						setOpenSeminaryCard((prev) => ({
							...prev,
							[filteredModule.module.number]: !prev[filteredModule.module.number],
						}));
					}}
				>
					<ArrowDown
						className={openSeminaryCard[filteredModule.module.number] ? "arrow-icon" : ""}
						style={{
							position: "absolute",
							top: "9px",
							right: "23px",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
						}}
					/>
					<div
						style={{
							position: "absolute",
							top: "40px",
							right: "23px",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
						}}
					>
						<div style={{ padding: "10px", textAlign: "right" }}>
							<h2 style={{ fontSize: "15px", margin: 0 }}>Progreso del módulo</h2>
							<h3
								style={
									(parseFloat(filteredModule.AllDominated) /
										parseFloat(filteredModule.totalWordsForModule)) *
										100 ===
									100
										? { color: "#ca5050" }
										: {}
								}
							>
								{Math.floor(
									(parseFloat(filteredModule.AllDominated) /
										parseFloat(filteredModule.totalWordsForModule)) *
										100
								)}
								%
							</h3>
							<PercentageBar
								percentage={
									(parseFloat(filteredModule.AllDominated) /
										parseFloat(filteredModule.totalWordsForModule)) *
									100
								}
								ViewPercentage={false}
							/>
						</div>
					</div>
					<div
						className="Info-card-modules"
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<h2>Módulo {romanize(filteredModule.module.number)}</h2>
						<p style={{ fontSize: "20px", fontWeight: "500", fontFamily: "auto" }}>
							({romanize(filteredModule.module.startSeminary)}-
							{romanize(filteredModule.module.endSeminary)})
						</p>
						<h5
							style={{
								fontWeight: "400",
								whiteSpace: "initial",
								overflow: "hidden",
								position: "relative",
								top: "17px",
							}}
						>
							{`${filteredModule.AllDominated}/${filteredModule.totalWordsForModule}`} Términos
							dominados{" "}
						</h5>
					</div>
					{openSeminaryCard[filteredModule.module.number] ? (
						<div
							style={{ width: "100%", backgroundColor: "#f9f9f9" }}
							onClick={(e) => e.stopPropagation()}
						>
							{filteredModule.filterSeminariesByModules.length > 0 &&
								filteredModule.filterSeminariesByModules.map((seminary, index) => (
									<VocabularySeminaryCard
										block={
											seminary.seminario > paidSeminaries && user.userType !== 2 ? true : false
										}
										seminary={seminary}
										nextClass={isNextClass(seminary.seminario)}
										gKey={gKey}
										key={"vocabulary-seminary-" + index}
										setCountRefresh={setCountRefresh}
										countRefresh={countRefresh}
										lastElementRef={index === seminaries.length - 1 ? lastElementRef : null}
									/>
								))}
							{loading && <SpinnerCustom height={100} />}
						</div>
					) : null}
				</div>
			))}
		</div>
	);
};

export default SeminariesCardsContainer;
