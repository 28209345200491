import { httpsCallable } from "firebase/functions";
import { processConektaPaymentI, processStripePaymentI } from "./interfaces";

const processConektaPayment = async (params: processConektaPaymentI) => {
	const {
		e,
		setRefresh,
		setConektaCardError,
		setValidated,
		values,
		handlePay,
		selectedCard,
		successCallback,
		errorCallback,
		setLoading,
	} = params;

	try {
		setRefresh(true);
		setConektaCardError(false);
		const form = e.currentTarget;

		const [exp_month, exp_year] = values.expDate.split("/");

		e.preventDefault();
		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
		} else {
			if (selectedCard === "new") {
				const card = {
					card: {
						number: values.cardNumber,
						name: values.cardName,
						exp_year: exp_year,
						exp_month: exp_month,
						cvc: values.cvc,
					},
				};

				await window.Conekta.Token.create(card, successCallback, errorCallback);
			} else {
				handlePay(selectedCard);
			}
		}
	} catch (error) {
		setLoading(false);
	}
};

const processStripePayment = async (params: processStripePaymentI) => {
	const {
		e,
		stripe,
		elements,
		setError,
		setLoading,
		CardCvcElement,
		CardExpiryElement,
		CardNumberElement,
		functions,
		msiSelected,
		price,
		itemName,
		student,
		handleSuccessPayment,
		setOpenModalStripe,
		isASuspiciousBehaviour,
		setConektaCardError,
		user,
		iscountryRestricted,
	} = params;


	e.preventDefault();
	if (!stripe || !elements) {
		console.error("Stripe.js aún no se ha cargado");
		setError("Stripe no está disponible.");
		return;
	}

	try {
		setLoading(true);
		const cardNumberElement = elements.getElement(CardNumberElement);
		const cardExpiryElement = elements.getElement(CardExpiryElement);
		const cardcvcElement = elements.getElement(CardCvcElement);
		if (!cardNumberElement || !cardExpiryElement || !cardcvcElement) {
			throw new Error("Elementos de Stripe no cargados.");
		}

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardNumberElement,
		});

		if (error) throw new Error(error.message);
		if (!paymentMethod) throw new Error("No se pudo crear el método de pago.");

		const processPayment = httpsCallable(functions, "processPayment");

		let msi;

		if (msiSelected == "3 Meses sin intereses" && iscountryRestricted === false) {
			msi = 3;
		} else if (msiSelected == "6 Meses sin intereses" && iscountryRestricted === false) {
			msi = 6;
		} else {
			msi = 0;
		}

		const result: any = await processPayment({
			paymentMethodId: paymentMethod.id,
			amount: price,
			itemName: itemName,
			student: student,
			msi: msi,
			user: user,
		});

		if (result.data.error) throw new Error(result.data.error.raw.message);
		if (result.data.rawType === "card_error") throw new Error(result.data.code);
		if (result.data.object === "error") throw new Error(result.data.message);

		handleSuccessPayment(result.data);
	} catch (error: any) {
		let errorMessage = error.message || "Ha ocurrido un error al intentar hacer el pago.";
		setConektaCardError(true);
		switch (error.message) {
			case "generic_decline":
				errorMessage = "Tarjeta declinada. Por favor, intente con otro método de pago.";
				break;
			case "insufficient_funds":
				errorMessage = "Fondos insuficientes. Intente con otra tarjeta o método de pago.";
				break;
			case "lost_card":
				errorMessage = "Tarjeta reportada como perdida. Por favor, contacte a su banco.";
				break;
			case "stolen_card":
				errorMessage = "Tarjeta reportada como robada. Por favor, contacte a su banco.";
				break;
			case "card_declined":
				errorMessage =
					"Tarjeta declinada. Favor de contactarse con su banco para mayor información.";
				break;
			case "payment_intent_invalid_parameter":
				errorMessage = "El plan de pagos seleccionado no es compatible con este método de pago.";
				break;
			case "fraudulent":
				errorMessage =
					"No ah sido posible procesar el cargo. Por favor, intente con otro método de pago.";
				break;
			default:
				errorMessage = `${error.message}`;
		}

		setError(errorMessage);

		if (errorMessage !== "fraudulent" && !isASuspiciousBehaviour) {
			setOpenModalStripe(false);
		}

		setLoading(false);
	}
};

// Función para procesar pagos dependiendo del tipo
export const processConektaOrStripePayment = async (typePayment: string, paymentData: any) => {
	const paymentProcessors: { [key: string]: Function } = {
		conekta: processConektaPayment,
		stripe: processStripePayment,
	};

	const processPayment = paymentProcessors[typePayment];

	if (!processPayment) {
		throw new Error("Método de pago no definido.");
	}
	return processPayment(paymentData);
};
