import { normalizeWord } from "functions/Functions";
import { Nullable, quizWords, segmentsQuiz } from "interfaces/Interfaces";
import { ignoredSings, ignoreWords } from "./DefaultCharacters";
import parse from "html-react-parser";

export function getWord(quizType, word: quizWords, audio?: boolean) {
	if (quizType === "normal") {
		if (audio === undefined) {
			const regex = /\((.*?)\)/g;
			let highlightedString: string = word.native_word.replace(regex, `<span style='color: #999999;'>($1)</span>`);
			if (highlightedString.includes('"')) {
				highlightedString = `<i>${highlightedString.replaceAll('"', "")}</i>`;
			}
			return parse(highlightedString);
			// let wordTemp = word.native_word.replace(/ *\([^)]*\) */g, " ");
			// let parentesis = word.native_word;
			// wordTemp.split(" ").map((wt, index) => {
			// 	parentesis = parentesis.replace(wt, "");
			// });
			// let parentesisIndex = word.native_word.indexOf(parentesis);
			// return (
			// 	<>
			// 		{parentesisIndex === 0 && parentesis.length > 0 && (
			// 			<>
			// 				<span style={{ whiteSpace: "initial", color: "#999999" }}>{parentesis}</span>
			// 				<br className="parentesis-br" />
			// 			</>
			// 		)}
			// 		{word.native_word.includes('"') ? <i>{wordTemp.replace(/"/g, "")}</i> : wordTemp}
			// 		{parentesisIndex > 0 && parentesis.length > 0 && (
			// 			<>
			// 				<br className="parentesis-br" />
			// 				<span style={{ whiteSpace: "initial", color: "#999999" }}>{parentesis}</span>
			// 			</>
			// 		)}
			// 	</>
			// );
		} else {
			if (word.language === "chinese") {
				return word.optional_word;
			} else {
				return word.native_word;
			}
		}
	} else {
		if (audio) {
			return word.meaning[0].replace(/ *\([^)]*\) */g, "").replace(/"/g, " ");
		} else {
			const regex = /\((.*?)\)/g;
			let highlightedString: string = word.meaning[0].replace(regex, `<span style='color: #999999;'>($1)</span>`);
			if (highlightedString.includes('"')) {
				highlightedString = `<i>${highlightedString.replaceAll('"', "")}</i>`;
			}
			return parse(highlightedString);
			// let wordTemp = word.meaning[0].replace(/ *\([^)]*\) */g, " ");
			// let parentesis = word.meaning[0];
			// wordTemp.split(" ").map((wt, index) => {
			// 	console.log(wt)
			// 	parentesis = parentesis.replace(wt, "");
			// });
			// let parentesisIndex = word.meaning[0].indexOf(parentesis.trim());
			// return (
			// 	<>
			// 		{parentesisIndex === 0 && parentesis.length > 0 && (
			// 			<>
			// 				{console.log("see word", parentesis)}
			// 				<span style={{ whiteSpace: "initial", color: "#999999" }}>{parentesis}</span>
			// 				{/* <br className="parentesis-br" /> */}
			// 			</>
			// 		)}
			// 		{word.meaning[0].includes('"') ? <i>{wordTemp.replace(/"/g, "")}</i> : wordTemp}
			// 		{parentesisIndex > 0 && parentesis.length > 0 && (
			// 			<>
			// 				<br className="parentesis-br" />
			// 				<span style={{ whiteSpace: "initial", color: "#999999" }}>{parentesis}</span>
			// 			</>
			// 		)}
			// 	</>
			// );
		}
	}
}

export function getCorrectWord(quizType, word: quizWords, justText?: boolean) {
	if (quizType === "normal") {
		let t = "";
		word.meaning.map((wordT, index) => {
			t +=
				(wordT.includes('"') ? `<i>${wordT.replace(/"/g, "")}</i>` : wordT) +
				(word.meaning.length <= 1 || index === word.meaning.length - 1 ? "" : index === word.meaning.length - 2 ? " o " : `, `);
		});
		if (justText) {
			return t.replaceAll("<i>", "").replaceAll("</i>", "");
		}
		return t;
	} else if (quizType === "selected-lang") {
		return word.native_word.replace(/"/g, "");
	} else {
		if (word.optional_word !== null && word.optional_word !== "") {
			return word.optional_word!.replace(/"/g, "");
		} else {
			return word.native_word.replace(/"/g, "");
		}
	}
}

function compare(a, b) {
	if (a > b) {
		return -1;
	}
	if (a < b) {
		return 1;
	}
	// a debe ser igual b
	return 0;
}

function returnNormalizeCheckWord(word) {
	let check = "";
	let deleteIndex: any = [];

	word
		.replace(/ *\([^)]*\) */g, " ")
		.split(" ")
		.map((word, index) => {
			if (
				ignoreWords.includes(
					word
						.normalize("NFD")
						.toLocaleLowerCase()
						.replace(/[\u0300-\u036f]/g, "")
				)
			) {
				deleteIndex.push(index);
			}
		});

	deleteIndex.sort(compare);
	if (deleteIndex.length > 0) {
		let tempWord = word.split(" ");

		deleteIndex.map((i) => {
			tempWord.splice(i, 1);
		});
		tempWord.map((word, index) => {
			check += (index > 0 ? " " : "") + word;
		});
		check.trim();
	} else {
		check = word;
	}

	return check;
}

export function isCorrect(language: string, quizType: string, word: quizWords, answerText: string) {
	const answer = answerText
		.replace(/"/g, "")
		.replace(/ *\([^)]*\) */g, " ")
		.replace(/[´’]/g, "'")
		.trim();
	if (quizType === "normal") {
		return word.meaning.some((e) => {
			const check = returnNormalizeCheckWord(e);
			const check2 = returnNormalizeCheckWord(answer);
			return (
				check
					.normalize("NFD")
					.toLocaleLowerCase()
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/\s/g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.trim() ===
				check2
					.normalize("NFD")
					.toLocaleLowerCase()
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/\s/g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
			);
		});
	} else if (quizType === "selected-lang" || language === "japanese") {
		if (language === "chinese") {
			return (
				word.native_word
					.replace(/"/g, "")
					.replace(/ĭ/g, "ǐ")
					.replace(/ă/g, "ǎ")
					.replace(/\s/g, "")
					.replace(/ê/g, "e")
					.replace(/ě/g, "e")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.replace(/[´’]/g, "'")
					.toLowerCase()
					.trim() ===
				answer
					.replace(/\s/g, "")
					.replace(/ĭ/g, "ǐ")
					.replace(/ă/g, "ǎ")
					.replace(/ê/g, "e")
					.replace(/ě/g, "e")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.replace(/[´’]/g, "'")
					.toLowerCase()
					.trim()
			);
		} else if (language === "german") {
			if (consultGermanDictionary(word.sub_category)[0] !== undefined) {
				const temp = consultGermanDictionary(word.sub_category)[0].replace(/[()]/g, "");

				return (
					word.native_word
						.replace(/"/g, "")
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/[´’]/g, "'")
						.replace(temp, "")
						.trim() ===
					answer
						.replace(temp, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.trim()
				);
			} else {
				return (
					word.native_word
						.replace(/"/g, "")
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/[´’]/g, "'")
						.trim() === answer.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "").trim()
				);
			}
		} else if (language === "japanese") {
			return (
				word.native_word
					.replace(/"/g, "")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.trim() === answer.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "").trim() ||
				(word.optional_word !== null &&
					word.optional_word !== "" &&
					word.optional_word
						.replace(/"/g, "")
						.replace(/ *\([^)]*\) */g, "")
						.replace(/\s/g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/[´’]/g, "'")
						.trim() ===
						answer
							.replace(/\s/g, "")
							.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
							.trim())
			);
		} else {
			return (
				word.native_word
					.replace(/"/g, "")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.replace(/[´’]/g, "'")
					.trim() === answer.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "").trim()
			);
		}
	} else {
		if (word.optional_word !== null && word.optional_word !== "") {
			return (
				word
					.optional_word!.replace(/"/g, "")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.replace(/\s/g, "")
					.replace(/[´’]/g, "'")
					.trim() === answer.replace(/\s/g, "")
			);
		} else {
			return (
				word.native_word
					.replace(/"/g, "")
					.replace(/ *\([^)]*\) */g, "")
					.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
					.replace(/\s/g, "")
					.replace(/[´’]/g, "'")
					.trim() === answer
			);
		}
	}
}

export function getLangCode(quizType: string, word: quizWords) {
	if (quizType === "selected-lang" || quizType === "kanji") {
		return "es-ES";
	} else {
		if (word.language === "french") {
			return "fr-FR";
		} else if (word.language === "italian") {
			return "it-IT";
		} else if (word.language === "portuguese") {
			return "pt-BR";
		} else if (word.language === "russian") {
			return "ru-RU";
		} else if (word.language === "german") {
			return "de-DE";
		} else if (word.language === "japanese") {
			return "ja-JP";
		} else if (word.language === "chinese") {
			return "cmn-CN";
		} else if (word.language === "english") {
			return "en-US";
		}
	}
}

export function getVoiceLangName(quizType: string, word: quizWords) {
	if (quizType === "selected-lang" || quizType === "kanji") {
		return "es-ES-Wavenet-C";
	} else {
		if (word.language === "french") {
			return "fr-FR-Wavenet-E";
		} else if (word.language === "italian") {
			return "it-IT-Wavenet-A";
		} else if (word.language === "portuguese") {
			return "pt-BR-Wavenet-A";
		} else if (word.language === "russian") {
			return "ru-RU-Standard-A";
		} else if (word.language === "german") {
			return "de-DE-Standard-A";
		} else if (word.language === "japanese") {
			return "ja-JP-Standard-A";
		} else if (word.language === "chinese") {
			return "cmn-CN-Wavenet-D";
		} else if (word.language === "english") {
			return "en-US-Standard-A";
		}
	}
}

export function getInitialSegmentWord(segment: number) {
	switch (segment) {
		case 0:
			return 0;
			break;
		case 1:
			return 8;
			break;
		case 2:
			return 16;
			break;
		case 3:
			return 24;
			break;
		case 4:
			return 32;
			break;
		case 5:
			return 40;
			break;
		case 6:
			return 48;
			break;
		case 7:
			return 56;
			break;
		default:
			return 0;
			break;
	}
}
export function getFinalSegmentWord(segment: number) {
	switch (segment) {
		case 0:
			return 8;
			break;
		case 1:
			return 16;
			break;
		case 2:
			return 24;
			break;
		case 3:
			return 32;
			break;
		case 4:
			return 40;
			break;
		case 5:
			return 48;
			break;
		case 6:
			return 56;
			break;
		case 7:
			return 64;
			break;
		default:
			return 8;
			break;
	}
}

export function getNumberOfSegments(quizwords: segmentsQuiz) {
	let count: Array<number> = [];
	for (let index = 0; index < 8; index++) {
		if (quizwords[index].length > 0) {
			count.push(index);
		}
	}
	return count;
}

export function getTotalWords(quizwords: segmentsQuiz) {
	let uniques = new Array();

	for (let i = 0; i < 8; i++) {
		for (let j = 0; j < quizwords[i].length; j++) {
			uniques.push(quizwords[i][j]);
		}
	}

	return uniques.length;
}

export function getUniqueTotalWords(quizwords: segmentsQuiz) {
	let uniques = new Array();

	for (let i = 0; i < 8; i++) {
		for (let j = 0; j < quizwords[i].length; j++) {
			if (!uniques.includes(quizwords[i][j].id)) {
				uniques.push(quizwords[i][j].id);
			}
		}
	}

	return uniques.length;
}

export function getUniqueTotalWordsArray(quizwords: segmentsQuiz) {
	let temp = [
		...quizwords[0],
		...quizwords[1],
		...quizwords[2],
		...quizwords[3],
		...quizwords[4],
		...quizwords[5],
		...quizwords[6],
		...quizwords[7],
	];
	let uniques = new Array();

	temp.map((word) => {
		delete word.audio;
		let index = uniques.findIndex((e) => e.id === word.id);
		if (index === -1) {
			uniques.push(word);
		} else {
			uniques[index].dominated = word.dominated;
			uniques[index].times_answered = word.times_answered;
			uniques[index].times_correct_answer = word.times_correct_answer;
		}
	});

	return uniques;
}

const germanDictionary = {
	"sg.m": "(der)",
	"sg.f": "(die)",
	"sg.nt": "(das)",
	pl: "(die)",
};

export function consultGermanDictionary(keys: any) {
	return keys.map((key) => {
		if (germanDictionary[key]) {
			return germanDictionary[key];
		}
	});
}

// if(answerType === "normal" || (answerType === "selected-lang" && language === "chinese")) {
//     word = words[0].replace(/\s/g, "").split("")
//     ans = answer[0].replace(/\s/g, "").split("")
// } else {
//     word = words[0].split("");
//     ans = answer[0].split("");
// }

function sortByIndex(a, b) {
	if (a.index < b.index) {
		return -1;
	} else if (a.index > b.index) {
		return 1;
	} else {
		return 0;
	}
}

export async function getCorrectChars(
	quizWord: quizWords,
	answerType: string,
	language: string,
	words: Array<Nullable<string>>,
	answer: Array<string>,
	typeReturn: string,
	subCatColores: any,
	correctsAnswers?: Array<Nullable<string>>
) {
	words = words.filter((e) => e !== null && e !== "");
	let finalRet = "";
	let arrayRet: Array<any> = [];
	let founded = {
		correct: false,
		indexFounded: -1,
	};

	try {
		await Promise.all(
			words.map(async (cWord, index) => {
				let ret: string = "";
				let word: Array<string>;
				let ans;
				let ansValidation: string = "";
				let wordValidation: string = "";
				let parentesisAns: string = "";
				let parentesisAns2: string = "";
				let preText: string = "";
				let spacesWord = new Array();
				let parentesisWord = new Array();
				let answerParentesis = new Array();
				let spacesAns = new Array();
				let correctGen: string = "";
				let correctGen2: string = "";
				let indexForPretext = new Array();
				let indexForPretext2 = new Array();

				let germanSubCategory = "";
				let wordAccuracy = 0;
				let colorAccuracy = 0;
				spacesWord = [];
				preText = "";
				wordAccuracy = 0;

				if (
					answerType === "normal" ||
					(answerType === "selected-lang" && language === "chinese") ||
					(answerType === "kanji" && language === "japanese")
				) {
					let pA = answer[0].replace(/ *\([^)]*\) */g, " ").replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "");
					let pA2 = cWord!.replace(/ *\([^)]*\) */g, " ").replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "");
					let check = returnNormalizeCheckWord(answer[0]);
					let check2 = returnNormalizeCheckWord(cWord);

					// preText = pA.replace(check.trim(), "");
					pA.split(" ").map((word, index) => {
						if (check.indexOf(word) === -1 && ignoreWords.indexOf(word) === -1) {
							indexForPretext.push({
								index: pA.indexOf(word),
								word: (index > 0 ? " " : "") + word + (index < pA.split(" ").length - 1 ? " " : ""),
							});
						}
					});
					pA2.split(" ").map((word, index) => {
						if (check2.indexOf(word) === -1 && ignoreWords.indexOf(word) === -1) {
							indexForPretext2.push({
								index: pA2.indexOf(word),
								word: (index > 0 ? " " : "") + word + (index < pA2.split(" ").length - 1 ? " " : ""),
							});
						}
					});

					if (check.length > pA.length) {
						let parentesisMatch = check.match(/\((.*?)\)/);
						let parentesisText = parentesisMatch ? parentesisMatch[0] : "";
						parentesisAns = parentesisText.trim();
					}

					if (check2.length > pA2.length) {
						// Extract the full text including parentheses
						let parentesisMatch = check2.match(/\((.*?)\)/);
						let parentesisText = parentesisMatch ? parentesisMatch[0] : "";

						parentesisAns2 = parentesisText; // Use the full text including parentheses

					}

					const notIgnoredWord: any = [];

					//	se buscan todos los caracteres que se van a ignorar, y se guarda su ubhicacion en el array para despues ser reubicados
					//	esto es para los significados de la palabra
					await Promise.all(
						cWord!
							.replace(/ *\([^)]*\) */g, " ")
							.trim()
							.split(" ")
							.map(async (word, i) => {
								const tW: any = word;
								const tA = cWord!;
								if (ignoreWords.indexOf(tW.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1) {
									const indexesW = tA
										.split(" ")
										.map((w, j) => (w === tW ? j : " "))
										.filter((e) => typeof e === "number");

									try {
										await Promise.all(
											indexesW.map((iW: any, iR) => {
												if (spacesWord.filter((e) => e.char === tW && e.index === iW).length === 0) {
													let tA = cWord!
														.replace(/ *\([^)]*\) */g, "")
														.trim()
														.split(" ");

													spacesWord.push({
														char: tW,
														index: iW,
														...(tA[iW - 1] !== undefined &&
															ignoreWords.indexOf(tA[iW - 1]) === -1 &&
															iW > 0 && {
																plus: notIgnoredWord.length - iW < 0 ? notIgnoredWord.length + iW : notIgnoredWord.length - iW,
															}),
													});
												}
											})
										);
									} catch (error) {}
								} else {
									notIgnoredWord.push(...tW.split(""));
								}
							})
					);

					await Promise.all(
						cWord!
							.replace(parentesisAns2, "")
							.split("")
							.map((char, index) => {
								if (ignoredSings.indexOf(char) !== -1) {
									spacesWord.push({ char: char, index: index });
								}
							})
					);

					const notIgnoredAns: any = [];
					//	se buscan todos los caracteres que se van a ignorar, y se guarda su ubhicacion en el array para despues ser reubicados
					await Promise.all(
						answer[0]
							.replace(/ *\([^)]*\) */g, " ")
							.trim()
							.split(" ")
							.map(async (word, i) => {
								const tW: any = word;
								const tA = answer[0];
								if (ignoreWords.indexOf(tW.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1) {
									const indexesW = tA
										.split(" ")
										.map((w, j) => (w === tW ? j : " "))
										.filter((e) => typeof e === "number");

									await Promise.all(
										indexesW.map((iW: any, iR) => {
											if (spacesAns.filter((e) => e.char === tW && e.index === iW).length === 0) {
												let tA = answer[0]
													.replace(/ *\([^)]*\) */g, "")
													.trim()
													.split(" ");

												spacesAns.push({
													char: tW,
													index: iW,
													...(tA[iW - 1] !== undefined &&
														ignoreWords.indexOf(tA[iW - 1]) === -1 &&
														iW > 0 && {
															plus: notIgnoredAns.length - iW < 0 ? notIgnoredAns.length + iW : notIgnoredAns.length - iW,
														}),
												});
											}
										})
									);
								} else {
									notIgnoredAns.push(...tW.split(""));
								}
							})
					);

					await Promise.all(
						answer[0]
							.replace(parentesisAns, "")
							.replace(correctGen, "")
							.split("")
							.map((char, index) => {
								if (ignoredSings.indexOf(char) !== -1) {
									spacesAns.push({ char: char, index: index });
								}
							})
					);

					console.log(parentesisAns2);

					if (cWord!.indexOf(parentesisAns2) !== -1 && parentesisAns2.length > 0) {
						parentesisWord.push({
							char: "<b style='white-space: nowrap; color: #949494;'>" + parentesisAns2 + "</b>",
							index: cWord!.indexOf(parentesisAns2),
						});
					}

					console.log(cWord);
					console.log(spacesWord);
					if (answer[0].indexOf(parentesisAns) !== -1 && parentesisAns.length > 0) {
						answerParentesis.push({
							char: "<b style='white-space: nowrap; color: #949494;'>" + parentesisAns + "</b>",
							index: answer[0].indexOf(parentesisAns),
						});
					}
					word = check2
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/ĭ/g, "ǐ")
						.replace(/ă/g, "ǎ")
						.replace(/\s/g, "")
						.trim()
						.split("");

					ans = check
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/ĭ/g, "ǐ")
						.replace(/ă/g, "ǎ")
						.replace(/\s/g, "")
						.trim()
						.split("");

					wordValidation = check2
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/ĭ/g, "ǐ")
						.replace(/ă/g, "ǎ")
						.replace(/\s/g, "")
						.trim();

					ansValidation = check
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/ĭ/g, "ǐ")
						.replace(/ă/g, "ǎ")
						.replace(/\s/g, "")
						.trim();
				} else {
					if (
						quizWord !== undefined &&
						consultGermanDictionary(quizWord.sub_category)[0] !== undefined &&
						language === "german" &&
						quizWord.singular_category === "sustantivo" &&
						!answer[0].includes("eine Million") &&
						!cWord!.includes("eine Million")
					) {
						let valuesDictionary = Object.values(germanDictionary);
						let answerSplit = answer[0].split(" ");
						correctGen = valuesDictionary.some((e) => e.replace(/[()]/g, "") === answerSplit[0].toLowerCase())
							? answerSplit[0].replace(/[()]/g, "")
							: "";
						let wordSplit = cWord!.split(" ");
						correctGen2 = valuesDictionary.some((e) => e.replace(/[()]/g, "") === wordSplit[0])
							? wordSplit[0].replace(/[()]/g, "")
							: "";
						preText = consultGermanDictionary(quizWord.sub_category)[0].replace(/[()]/g, "") + " ";
						let subCatGerTemp = quizWord.sub_category[0]!.replace(/[()]/g, "").split(".");
						germanSubCategory = subCatGerTemp[subCatGerTemp.length - 1];
					}

					let check = correctGen.length > 0 ? answer[0].replace(correctGen, "").trim() : answer[0].trim();

					let check2 = correctGen2.length > 0 ? cWord!.replace(correctGen2, "").trim() : cWord!.trim();

					let pA = answer[0].replace(/ *\([^)]*\) */g, " ");
					let pA2 = cWord!.replace(/ *\([^)]*\) */g, " ");

					if (check.length > pA.length) {
						let tempCheck = check;
						pA.split(" ").map((pa) => {
							tempCheck = tempCheck.replace(pa, "");
						});
						parentesisAns = tempCheck.trim();
					}

					if (check2.length > pA2.length) {
						let tempCheck2 = check2;
						pA2.split(" ").map((pa2) => {
							tempCheck2 = tempCheck2.replace(pa2, "");
						});
						parentesisAns2 = tempCheck2.trim();
					}

					const notIgnoredWord: any = [];
					await Promise.all(
						cWord!
							.replace(/ *\([^)]*\) */g, "")
							.trim()
							.split(" ")
							.map(async (word, i) => {
								const tW: any = word;
								const tA = cWord!;

								notIgnoredWord.push(...tW.split(""));
							})
					);

					await Promise.all(
						cWord!
							.replace(parentesisAns2, "")
							.replace(language === "german" ? preText : "", "")
							.replace(language === "german" ? correctGen2 : "", "")
							.split("")
							.map((char, index) => {
								if (ignoredSings.indexOf(char) !== -1) {
									spacesWord.push({ char: char, index: index });
								}
							})
					);

					const notIgnoredAns: any = [];
					await Promise.all(
						answer[0]
							.replace(/ *\([^)]*\) */g, "")
							.split("")
							.map(async (word, i) => {
								const tW: any = word;
								const tA = answer[0];

								notIgnoredAns.push(...tW.split(""));
							})
					);

					await Promise.all(
						answer[0]
							.replace(parentesisAns, "")
							.replace(language === "german" ? preText : "", "")
							.replace(language === "german" ? correctGen : "", "")
							.split("")
							.map((char, index) => {
								if (ignoredSings.indexOf(char) !== -1) {
									spacesAns.push({ char: char, index: index });
								}
							})
					);

					if (check2.indexOf(parentesisAns2) !== -1 && parentesisAns2.length > 0) {
						parentesisWord.push({
							char: "<b style='white-space: nowrap; color: #949494;'>" + parentesisAns2 + "</b>",
							index: check2.indexOf(parentesisAns2),
						});
					}
					if (check.indexOf(parentesisAns) !== -1 && parentesisAns.length > 0) {
						answerParentesis.push({
							char: "<b style='white-space: nowrap; color: #949494;'>" + parentesisAns + "</b>",
							index: check.indexOf(parentesisAns),
						});
					}

					word = check2
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/\s/g, "")
						.trim()
						.split("");
					ans = check
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/\s/g, "")
						.trim()
						.split("");

					wordValidation = check2
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/\s/g, "")
						.trim();

					ansValidation = check
						.replace(/ *\([^)]*\) */g, "")
						.replace(/[¿?¿!¡+*}{¡¨*\]\[_:"\-\\]/g, "")
						.replace(/\s/g, "")
						.trim();
				}

				let str = new Array();

				if (
					((ansValidation
						.toLowerCase()
						.normalize("NFD")
						.toLocaleLowerCase()
						.replace(/[\u0300-\u036f]/g, "") ===
						wordValidation
							.toLowerCase()
							.normalize("NFD")
							.toLocaleLowerCase()
							.replace(/[\u0300-\u036f]/g, "") &&
						(answerType === "normal" || (answerType === "selected-lang" && language === "chinese"))) ||
						ansValidation === wordValidation) &&
					!founded.correct
				) {
					founded.correct = true;
					founded.indexFounded = index;
				}

				if (
					typeReturn === "answer" &&
					((founded.correct && founded.indexFounded === index) || (!founded.correct && words.length > 1) || words.length === 1)
				) {
					ret = "";
					for (let i = 0; i < ans.length; i++) {
						if (
							word[i] !== undefined &&
							(ans[i] === word[i] ||
								(ans[i].toLowerCase() === word[i].toLowerCase() && answerType === "selected-lang" && language === "chinese") ||
								(ans[i] !== word[i] &&
									((ans[i] === "ê" && word[i] === "ě") || (ans[i] === "ě" && word[i] === "ê")) &&
									answerType === "selected-lang" &&
									language === "chinese"))
						) {
							str.push(`<span className='good'>${ans[i]}</span>`);
							wordAccuracy++;
						} else if (word[i] !== undefined && answerType === "normal" && ans[i].toLowerCase() === word[i].toLowerCase()) {
							str.push(`<span className='good'>${ans[i]}</span>`);
							wordAccuracy++;
						} else {
							str.push(`<span className='bad'>${ans[i]}</span>`);
						}
					}

					if (spacesAns.length > 0) {
						spacesAns.sort(sortByIndex).map((c) => {
							str.splice(c.index + (c.plus !== undefined ? c.plus : 0), 0, ...c.char.split(""));
						});
					}

					if (answerParentesis.length > 0) {
						answerParentesis.sort(sortByIndex).map((c) => {
							str.splice(c.index + (c.plus !== undefined ? c.plus : 0), 0, ...c.char.split(""));
						});
					}

					str.map((char) => {
						ret += char;
					});

					finalRet = ret;
				} else if (typeReturn === "correct") {
					for (let i = 0; i < word.length; i++) {
						if (
							ans[i] !== undefined &&
							(word[i] === ans[i] ||
								(word[i].toLowerCase() === ans[i].toLowerCase() && answerType === "selected-lang" && language === "chinese") ||
								(word[i] !== ans[i] &&
									((word[i] === "ê" && ans[i] === "ě") || (word[i] === "ě" && ans[i] === "ê")) &&
									answerType === "selected-lang" &&
									language === "chinese"))
						) {
							str.push(`<span className='good ${index}'>${word[i]}</span>`);
							wordAccuracy++;
						} else if (ans[i] !== undefined && answerType === "normal" && word[i].toLowerCase() === ans[i].toLowerCase()) {
							str.push(`<span className='good ${index}'>${word[i]}</span>`);
							wordAccuracy++;
						} else {
							str.push(`<span className='bad ${index}'>${word[i]}</span>`);
						}
					}
					if (spacesWord.length > 0)
						spacesWord.sort(sortByIndex).map((c) => {
							str.splice(c.index + (c.plus !== undefined ? c.plus : 0), 0, ...c.char.split(""));
						});

					if (parentesisAns2.length > 0) {
						parentesisWord.sort(sortByIndex).map((c) => {
							str.splice(c.index + (c.plus !== undefined ? c.plus : 0), 0, ...c.char.split(""));
						});
					}

					str.map((char) => {
						ret += char;
					});
				}

				if (
					(answerType === "normal" ||
						(answerType === "selected-lang" && language === "chinese") ||
						(answerType === "kanji" && language === "japanese")) &&
					ret.length > 0
				) {
					if (typeReturn === "answer") {
						ret = ret[0] === '"' ? `<i>${ret.replace(/"/g, "")}</i>` : ret;
						console.log(ret);
						finalRet = ret;
					} else {
						ret = ret[0] === '"' ? `<i>${ret.replace(/"/g, "")}</i>` : ret;
					}
				} else if (ret.length > 0) {
					if (typeReturn === "answer") {
						ret =
							(answer[0].includes(preText) && preText.trim() === correctGen.trim()
								? `<span className='good'>${correctGen}</span> `
								: correctGen.length === 0
								? ``
								: `<span className='bad'>${correctGen}</span> `) + (ret[0] === '"' ? `<i>${ret.replace(/"/g, "")}</i>` : ret);
						finalRet = ret;
					} else {
						ret =
							(answer[0].includes(preText) && preText.trim() === correctGen.trim()
								? `<span className='good'>${correctGen}</span> `
								: correctGen.length === 0
								? `<b style="color: ${subCatColores[germanSubCategory]}">${preText}</b> `
								: `<span className='bad'>${preText}</span> `) +
							(ret[0] === '"' ? `<i>${ret.replace(/"/g, "")}</i>` : ret) +
							(answerType === "kanji" && language === "chinese" ? " (" + quizWord.native_word + ")" : "");
					}
				}
				if (typeReturn === "correct") {
					arrayRet.push({ place: index, ret: ret });
				}
				colorAccuracy = wordAccuracy / (typeReturn === "answer" ? ansValidation.length : wordValidation.length);
				wordAccuracy = similarity(ansValidation, wordValidation);
			})
		);
	} catch (error) {
		return finalRet;
	}

	if (typeReturn === "correct") {
		await Promise.all(
			arrayRet
				.sort((a, b) => a.place - b.place)
				.map((ret, i) => {
					finalRet += ret.ret + (i < arrayRet.length - 1 ? ", " : "");
				})
		);
	}

	if (typeReturn === "correct" && words.length > 1 && founded.correct) {
		for (let i = 0; i < words.length; i++) {
			if (i !== founded.indexFounded) {
				finalRet = finalRet.replaceAll(`bad ${i}`, "black").replaceAll(`good ${i}`, "black");
			}
		}
	}

	return finalRet;
}

function similarity(s1, s2) {
	var longer = s1;
	var shorter = s2;
	if (s1.length < s2.length) {
		longer = s2;
		shorter = s1;
	}
	var longerLength = longer.length;
	if (longerLength == 0) {
		return 1.0;
	}
	return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();

	var costs = new Array();
	for (var i = 0; i <= s1.length; i++) {
		var lastValue = i;
		for (var j = 0; j <= s2.length; j++) {
			if (i == 0) costs[j] = j;
			else {
				if (j > 0) {
					var newValue = costs[j - 1];
					if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
					costs[j - 1] = lastValue;
					lastValue = newValue;
				}
			}
		}
		if (i > 0) costs[s2.length] = lastValue;
	}
	return costs[s2.length];
}

export async function cleanAudiosFirebase(quizWords) {
	let temp = JSON.parse(JSON.stringify(quizWords));
	await Promise.all(
		temp.map((word) => {
			if (word.audio !== undefined) {
				delete word.audio;
			}
		})
	);
	return temp;
}
