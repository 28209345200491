import "./SeminaryInfoModal.scss";
import Modal from "react-bootstrap/Modal";
import { getLangSpanishSimple } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { capitalizeFirstLetter, romanize } from "functions/Functions";
import parse from "html-react-parser";
import { getCorrectWord } from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { useEffect } from "react";
import { CheckmarkCircle } from "assets/Imports";
import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { language } from "../../../../interfaces/Interfaces";

export function SeminaryInfoModal(props) {
	if (props.words.length === 0) {
		return <></>;
	}
	return (
		<Modal
			className="see-seminary-info-modal"
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Seminario {romanize(props.num)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4 className="pl-3">
					<b>{capitalizeFirstLetter(getLangSpanishSimple(props.words[0].language))}</b>
				</h4>
				<div
					className="container m-0 p-0"
					style={{ display: "flex", flexWrap: "wrap", gap: "20px", flexDirection: "column", alignItems: "center" }}
				>
					{props.words.map((word, index) => {
						if (word.native_word !== "") {
							return (
								<WordRowCard
									word={word}
									language={word.language}
									answerType={"normal"}
									index={index}
									gKey={props.gKey}
									key={"word-middle-screen-" + index}
									setCountRefresh={props.setCountRefresh}
									countRefresh={props.countRefresh}
								/>
								// <div
								// 	className="row m-0 mb-2 flex-nowrap align-items-center"
								// 	key={"word-info-row-" + index}
								// 	style={index % 2 === 0 ? { background: "#ebecf3" } : {}}
								// >
								// 	<div className="col-sm text-center p-0">
								// 		<p
								// 			className={"m-0 " + (word.dominated === 3 ? "dominated" : word.optional_word !== null && word.optional_word !== "" ? "gray" : "")}
								// 		>
								// 			{word.dominated === 3 && <img src={CheckmarkCircle} alt="palomita" />}
								// 			{word.optional_word !== null && word.optional_word !== "" && (
								// 				<span className={word.dominated === 3 ? "" : "black"} style={{ fontSize: "0.875rem" }}>
								// 					{word.optional_word}
								// 				</span>
								// 			)}
								// 			{parse(
								// 				`<span>${
								// 					(word.optional_word !== null && word.optional_word !== "" && word.language === "japanese"
								// 						? "(" + word.native_word + ")"
								// 						: word.native_word) + `<sup>${word.dominated === null ? 0 : word.dominated}</sup>`
								// 				}<span>`
								// 			)}
								// 		</p>
								// 	</div>
								// 	<div className="col-sm text-center p-0">
								// 		<p className="m-0">{parse(getCorrectWord("normal", word))}</p>
								// 	</div>
								// </div>
							);
						}
					})}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="action-btn linear-bg" onClick={props.onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
