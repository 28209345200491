import PercentageBar from "@components/PercentageBar/PercentageBar";
import "./VocabularySeminaryStyles.scss";
import { getImage, romanize } from "functions/Functions";
import VocabularyDetailSeminaryCard from "../VocabularyDetailSeminaryCard/VocabularyDetailSeminaryCard";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Calendar, EyeBlock } from "assets/Imports";
import { useAuth } from "@hooks/useAuth";

function VocabularySeminaryCard({ block, seminary, nextClass, gKey, countRefresh, setCountRefresh, lastElementRef }) {
	const history = useHistory();

	const [openDetail, setOpenDetail] = useState<boolean>(false);

	const user = useAuth().user;

	return (
		<>
			{!block && !nextClass ? (
				<div className="vocabulary-card" ref={lastElementRef || null}>
					<div className="vocabulary-card-main-info" onClick={() => setOpenDetail(!openDetail)}>
						<div className="card-info-container">
							<div className="image-container">
								<img src={getImage(parseInt(seminary.seminario))} alt="seminary picture" />
							</div>
							<div className="name-and-progress-container">
								<h3>Seminario {romanize(seminary.seminario)}</h3>
								<p>
									{seminary.allWords.filter((e) => e.dominated === 3).length}/{seminary.allWords.length} términos dominados
								</p>
							</div>
						</div>
						<div className="percentage-container">
							<h3 className="percentage-of-seminary-text">Progreso del seminario</h3>
							<PercentageBar
								percentage={
									(parseFloat(seminary.allWords.filter((e) => e.dominated === 3).length) /
										parseFloat(seminary.allWords.length === 0 ? 1 : seminary.allWords.length)) *
									100
								}
							/>
						</div>
					</div>
					{openDetail && (
						<VocabularyDetailSeminaryCard
							setOpenDetail={setOpenDetail}
							openDetail={openDetail}
							seminary={seminary}
							gKey={gKey}
							setCountRefresh={setCountRefresh}
							countRefresh={countRefresh}
						/>
					)}
				</div>
			) : (
				<div className="vocabulary-card vocabulary-card-block" ref={lastElementRef || null}>
					<div className="vocabulary-card-main-info" onClick={() => setOpenDetail(!openDetail)}>
						<div className="card-info-container">
							<div className="image-container">
								<img src={getImage(parseInt(seminary.seminario))} alt="seminary picture" />
							</div>
							<div className="name-and-progress-container">
								<h3>Seminario {romanize(seminary.seminario)}</h3>
							</div>
						</div>
						{nextClass && (
							<div className="percentage-container">
								<p className="next-class-text">
									{" "}
									<img src={Calendar} alt="class icon" /> Clase próxima
								</p>
							</div>
						)}
						{block && (
							<div className="block">
								<div className="enroll-btn-container fill"></div>
								<div className="message-block-container">
									<img src={EyeBlock} alt="block eye image" />
									<p>Aún no tienes acceso a este módulo.</p>
								</div>
								<div className="enroll-btn-container">
									<button className="return-btn-style" onClick={() => history.push("/micuenta/historial-de-pagos")}>
										Inscríbete
									</button>
								</div>
							</div>
						)}
						{nextClass && !block && (
							<div className="block">
								<div className="enroll-btn-container fill2"></div>
								<FontAwesomeIcon icon={faLock as IconProp} style={{ color: "white" }} />
								<p>Aún no se lleva a cabo este seminario.</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default VocabularySeminaryCard;
