import { convertVideo, getStatus, lastStep, uploadVideo } from "@api/Videos/VideosApi";
import { addDoc, collection, getDocs, orderBy, query, doc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import "./VideosUpload.scss";
import "./components/SaveVideoModal.scss";
import { v4 as uuidv4 } from "uuid";
import { SaveVideoModal } from "./components/SaveVideoModal";
import { CheckmarkCircle, RefreshIcon } from "assets/Imports";
import GearIcon from "@img/gear-icon.svg";
import { Form } from "react-bootstrap";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";
export function VideosUpload(props) {
	const [totalBytesUploaded, setTotalBytesUploaded] = useState<number>(0);
	const [totalBytes, setTotalBytes] = useState<number>(0);
	const [percentage, setPercentage] = useState<number>(0);
	const [fileName, setFileName] = useState<string>("");
	const [videos, setVideos] = useState<Array<any>>(new Array());
	const [uploading, setUploading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveVideoUrl, setSaveVideoUrl] = useState<string>("");
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [showAdditionalGens, setShowAdditionalGens] = useState(false);
	//basta solo la instancia de este custom hook para cargar toda la logica de filtros de generaciones y obtener las propias generaciones
	const { gens, handleCheck, gensRecord } = useGenerations(compareGenerations);
	const [IwantAllGens, setAllGens] = useState(true);
	const [currentSeminary, setCurrentSeminary] = useState([]);
	const [numSeminaryVideo, setnumSeminaryVideo] = useState([]);
	const handleToggleShowAdditionalGens = (e) => {
		console.log(e.target.checked);
	};

	useEffect(() => {
		if (IwantAllGens) {
			handleCheck("active");
		} else {
			handleCheck("all");
		}
	}, [IwantAllGens]);

	const getVideos = async () => {
		const q = query(collection(db, "uploadedVideos"), orderBy("createdAt", "asc"));
		const snap = await getDocs(q);
		const temp: Array<any> = new Array();
		let error = false;
		if (snap.size > 0) {
			await Promise.all(
				snap.docs.map(async (doc) => {
					await getStatus(
						doc
							.data()
							.savepath.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/[()]/g, "") +
							"/" +
							doc.data().uuid +
							"/"
					)
						.then((res: any) => {
							if (res.code === "ERR_NETWORK" && !error) {
								error = true;
								alert("El servidor no responde.");
							} else if (res.data !== undefined) {
								temp.push({ id: doc.id, ...doc.data(), status: res.data.status });
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
			);
			temp.forEach((e) => {
				let date: Date = new Date(e.createdAt.seconds * 1000 + e.createdAt.nanoseconds / 1000000);
				let formatedDate: string = date.toLocaleDateString("es-MX");
				e.uploadDate = formatedDate;
			});
			setVideos(temp.sort(compare));
		}
	};

	function compare(a, b) {
		if (a.createdAt > b.createdAt) {
			return -1;
		}
		if (a.createdAt < b.createdAt) {
			return 1;
		}
		return 0;
	}

	const clean = () => {
		setTotalBytes(0);
		setTotalBytesUploaded(0);
		setFileName("");
		setVideos(new Array());
		setPercentage(0);
		getVideos();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.target;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			form.classList.add("was-validated");
		} else {
			setUploading(true);
			const file = new FormData();
			console.log(e.target.file.files[0]);
			file.append("file", e.target.file.files[0]);

			setTotalBytes(e.target.file.files[0].size);
			await uploadVideo(file, setTotalBytesUploaded)
				.then(async (res: any) => {
					if (res.status === 200) {
						const body = {
							savepath: form.savepath.value,
							uuid: uuidv4(),
							filename: fileName,
						};
						if (body.savepath[0] === "/") {
							body.savepath = body.savepath.replace("/", "");
						}
						await convertVideo(body)
							.then(async (res: any) => {
								if (res.status === 200) {
									await addDoc(collection(db, "uploadedVideos"), {
										savepath: body.savepath,
										filename: body.filename,
										uuid: body.uuid,
										createdAt: new Date(),
										lastStep: false,
									}).then(() => {
										alert(
											"El video ha comenzado el proceso de conversión.\n A continuación, podrás consultar su estado para ver cuando esté listo."
										);
										clean();
										e.target.reset();
										setUploading(false);
									});
								}
							})
							.catch((error) => {
								console.log(error);
								alert(
									"Ha ocurrido un error al procesar la solicitud, favor de validar que la información proporcionada es la correcta, que se tiene una conexión a internet o intentar de nuevo más tarde."
								);
							});
					}
				})
				.catch((error) => {
					console.log(error);
					alert("hubo un error al subir el video, favor de intentarlo de nuevo o más tarde.");
				});
		}
	};

	const handleChange = (e) => {
		setFileName(e.target.files[0].name);
	};

	const convert = async (video) => {
		const body = {
			savepath: video.savepath,
			uuid: video.uuid,
			filename: video.filename,
		};

		try {
			await convertVideo(body).then(async (res: any) => {
				if (res.status === 200) {
					alert(
						"El video ha comenzado el proceso de conversión.\n A continuación, podrás consultar su estado para ver cuando esté listo."
					);
					setUploading(false);
					refresh();
				}
			});
		} catch (error) {
			console.log(error);
			alert(
				"Hubo un error al intentar convertir el video, favor de intentarlo de nuevo o más tarde. Verifica tu conexión a internet."
			);
		}
	};

	const refresh = () => {
		setVideos(new Array());
		getVideos();
	};

	function getStatusColor(status: string) {
		switch (status) {
			case "not-started":
				return "#8B0000";
				break;
			case "in-progress":
				return "#FFC000";
				break;
			case "done":
				return "#00FF00";
				break;
			default:
				break;
		}
	}

	function getSpanishStatus(status: string) {
		switch (status) {
			case "not-started":
				return "Sin comenzar";
				break;
			case "in-progress":
				return "En progreso";
				break;
			case "done":
				return "Completado";
				break;
			default:
				break;
		}
	}
	const doLastStep = async (video) => {
		setSaveVideoUrl(
			video.savepath
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.replace(/[()]/g, "") +
				"/" +
				video.uuid +
				"/"
		);
		setSelectedVideo(video);

		setShowModal(true);
	};

	const getGeneration = (genId: string) => {
		if (genId !== undefined) {
			const generation = gens.find((e) => e.id === genId);
			if (generation) {
				return "Generación asignada: " + generation.name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "");
			}
		}
		return "";
	};

	function getPart(partText) {
		switch (partText) {
			case "videoUrl":
				return "Parte 1";
				break;
			case "videoUrl12":
				return "Parte 2";
				break;
			case "videoUrl13":
				return "Parte 3";
				break;

			default:
				return "";
				break;
		}
	}

	useEffect(() => {
		const per = Math.floor((totalBytesUploaded / totalBytes) * 100);
		setPercentage(isNaN(per) ? 0 : per);
	}, [totalBytesUploaded]);

	useEffect(() => {
		//	getGenerations();
		getVideos();
	}, []);

	useEffect(() => {
		if (!showModal) {
			setSaveVideoUrl("");
			setSelectedVideo(null);
			setVideos([]);
			getVideos();
		}
	}, [showModal]);

	
	return (
		<div className="videos-upload-main-container">
			<SaveVideoModal
				gens={gens}
				gensRecord={gensRecord}
				show={showModal}
				onHide={() => setShowModal(false)}
				videoUrl={saveVideoUrl}
				video={selectedVideo}
				videoData={currentSeminary}
				setCurrentSeminary={setCurrentSeminary}
				numSeminaryVideo={numSeminaryVideo}
			/>
			<div className="header">
				<h2>Subir una grabación</h2>
			</div>
			<div className="upload-form-container">
				<form className="needs-validation" onSubmit={handleSubmit} noValidate>
					<div className="form-group form-row d-flex">
						<div className="col-md-6">
							<label className="mb-2" htmlFor="savepath">
								Selecciona una generación
							</label>
						</div>
						<div className="col-md-6 d-flex justify-content-end">
							<Form className="translate-input-container mt-2">
								<Form.Check
									type="switch"
									id="custom-switch-lang"
									onChange={(e) => setAllGens(e.target.checked)}
									checked={IwantAllGens}
									label={"Mostrar generaciones activas"}
								/>
							</Form>
						</div>

						<select
							className="form-control mt-2 col-md-12"
							id="savepath"
							name="savepath"
							placeholder="Ejemplo: 2022/mayo/video_1"
							required
						>
							<option></option>
							{gens.map((gen, index) => (
								<option
									value={gen.name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "")}
									key={"opcion-gen-" + index}
								>
									{gen.name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "")}
								</option>
							))}
						</select>

						<div className="invalid-feedback col-md-12">Por favor selecciona una generación.</div>
					</div>

					<div className="form-group form-row file-upload-container">
						<label className="action-btn secondary-btn-bg" htmlFor="file">
							{fileName === "" ? "Seleccionar archivo" : fileName}
						</label>
						<input
							type="file"
							accept=".mp4"
							className="filetoupload form-control"
							onChange={handleChange}
							name="file"
							id="file"
							required
						/>
						<div className="invalid-feedback">Por favor selecciona un video.</div>
					</div>
					<div className="loading-upload-container">
						<p>Progreso de carga</p>
						{percentage < 100 ? (
							<div className="progress">
								<div
									className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
									role="progressbar"
									style={{ width: percentage + "%" }}
									aria-valuenow={100}
									aria-valuemin={0}
									aria-valuemax={100}
								>
									{percentage}%
								</div>
							</div>
						) : (
							<div className="d-flex align-items-center justify-content-center">
								<h3 className="mr-2">Completado</h3>
								<img src={CheckmarkCircle} alt="icono de palomita" />
							</div>
						)}
					</div>
					<div className="d-flex justify-content-center">
						<button className="action-btn linear-bg" type="submit" disabled={uploading}>
							Subir
						</button>
					</div>
				</form>
			</div>
			{videos.length > 0 && (
				<>
					<div className="uploaded-videos-actions-container">
						<button className="refresh-btn" onClick={refresh}>
							<img src={RefreshIcon} alt="refresh icon" />
						</button>
					</div>
					<div className="uploaded-videos-container">
						{videos.map((video, index) => {
							return (
								<div className="video-card" key={"video-card-" + index}>
									<p className="upload-date">Fecha de subida: {video.uploadDate}</p>
									<div className="top-content">
										{video.status === "done" && video.lastStep && (
											<img
												className="video-gear"
												src={GearIcon}
												alt="icono de engrane"
												role={"button"}
												onClick={() => {
													doLastStep(video);
													setnumSeminaryVideo(video.seminaryAssigned);
												}}
											/>
										)}
										<div className="video-info-container">
											<div>
												<h3>
													{!video.lastStep
														? video.savepath
														: video.generationAssigned
														? getGeneration(video.generationAssigned)
														: video.savepath}
												</h3>
											</div>
											<div>
												<h3>
													{!video.lastStep
														? video.filename
														: video.seminaryAssigned
														? "Seminario asignado: " + video.seminaryAssigned
														: video.filename}
												</h3>
											</div>
											{video.partAssigned && (
												<div>
													<h3>Parte asignada: {getPart(video.partAssigned)}</h3>
												</div>
											)}
										</div>
										<div className="video-status-container">
											<h3>Estado</h3>
											<div className="status-info-container">
												<h3>{getSpanishStatus(video.status)}</h3>
												<div className="status-color" style={{ backgroundColor: getStatusColor(video.status) }}></div>
											</div>
											{video.status === "done" && !video.lastStep && (
												<button
													className="action-btn linear-bg"
													onClick={() => {
														doLastStep(video);
														setnumSeminaryVideo(video.seminaryAssigned);
													}}
												>
													Guardar en
												</button>
											)}
											{video.status === "not-started" && (
												<button className="action-btn linear-bg convertir-btn" onClick={() => convert(video)}>
													Convertir
												</button>
											)}
										</div>
									</div>
									{video.status === "done" && (
										<div className="footer">
											<h3>
												https://videoacademia.academiadelenguasinternacionales.info/
												{new Date(video.createdAt.seconds * 1000) > new Date("09/03/2023") ? "mnt" : "media"}/
												{video.savepath
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.replace(/[()]/g, "") +
													"/" +
													video.uuid}
												/master.m3u8
											</h3>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
}
