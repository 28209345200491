import { getAuth } from "firebase/auth";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./VocabularyDetailSeminaryCard.scss";
import { db } from "initFirebase";
import { SeminaryInfoModal } from "../SeminaryInfoModal/SeminaryInfoModal";
import {
	BrazilFlagSVG,
	ChinaFlagSVG,
	FranceFlagSVG,
	GermanyFlagSVG,
	ItalyFlagSVG,
	JapanFlagSVG,
	NextArrow,
	RussiaFlagSVG,
	UnitedStatesFlagSVG,
} from "assets/Imports";

function VocabularyDetailSeminaryCard({ openDetail, setOpenDetail, seminary, gKey, countRefresh, setCountRefresh }) {
	const [quizzes, setQuizzes] = useState<number>(0);
	const [modalSeminaryInfo, setModalSeminaryInfo] = useState({ show: false, words: [] });
	const user = getAuth();

	const getNumberOfQuizzes = async () => {
		const q = query(
			collection(db, "quiz"),
			where("userId", "==", user.currentUser?.uid),
			where("seminaryNum", "==", seminary.seminario)
		);
		const qs = await getDocs(q);

		setQuizzes(qs.size);
	};

	useEffect(() => {
		getNumberOfQuizzes();
	}, []);
	return (
		<>
			{modalSeminaryInfo.show && (
				<SeminaryInfoModal
					show={modalSeminaryInfo.show}
					onHide={() => setModalSeminaryInfo({ show: false, words: [] })}
					words={modalSeminaryInfo.words}
					gKey={gKey}
					num={seminary.seminario}
					setCountRefresh={setCountRefresh}
					countRefresh={countRefresh}
				/>
			)}
			<div className={"vocabulary-detail-seminary-card-container" + (openDetail ? " open-vocabulary-detail" : "")}>
				<div className="content-container">
					<div className="header">
						<h3>Vista general</h3>
						<p className="test-progress-text">{quizzes} rondas de estudio</p>
					</div>
					<div className="languages-main-container">
						<div className="languages-container">
							{seminary.total_frances > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.french })}>
									<img src={FranceFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Francés</h3>
										<p
											className={
												(parseFloat(seminary.french.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.french.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.french.filter((e) => e.dominated === 3).length}/{seminary.french.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_german > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.german })}>
									<img src={GermanyFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Alemán</h3>
										<p
											className={
												(parseFloat(seminary.german.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.german.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.german.filter((e) => e.dominated === 3).length}/{seminary.german.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_japanese > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.japanese })}>
									<img src={JapanFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Japonés</h3>
										<p
											className={
												(parseFloat(seminary.japanese.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.japanese.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.japanese.filter((e) => e.dominated === 3).length}/{seminary.japanese.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_italian > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.italian })}>
									<img src={ItalyFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Italiano</h3>
										<p
											className={
												(parseFloat(seminary.italian.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.italian.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.italian.filter((e) => e.dominated === 3).length}/{seminary.italian.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_russian > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.russian })}>
									<img src={RussiaFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Ruso</h3>
										<p
											className={
												(parseFloat(seminary.russian.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.russian.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.russian.filter((e) => e.dominated === 3).length}/{seminary.russian.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_chinese > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.chinese })}>
									<img src={ChinaFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Chino</h3>
										<p
											className={
												(parseFloat(seminary.chinese.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.chinese.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.chinese.filter((e) => e.dominated === 3).length}/{seminary.chinese.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_portuguese > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.portuguese })}>
									<img src={BrazilFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Portugués</h3>
										<p
											className={
												(parseFloat(seminary.portuguese.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.portuguese.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.portuguese.filter((e) => e.dominated === 3).length}/{seminary.portuguese.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
							{seminary.total_english > 0 && (
								<div className="lang-container" onClick={() => setModalSeminaryInfo({ show: true, words: seminary.english })}>
									<img src={UnitedStatesFlagSVG} alt="" />
									<div className="lang-info">
										<h3>Inglés</h3>
										<p
											className={
												(parseFloat(seminary.english.filter((e) => e.dominated === 3).length) /
													parseFloat(seminary.english.length)) *
													100 ===
												100
													? "complete"
													: ""
											}
										>
											{seminary.english.filter((e) => e.dominated === 3).length}/{seminary.english.length}{" "}
											<span>términos dominados</span>
										</p>
									</div>
								</div>
							)}
						</div>
						<div className="number-of-tests-text-container">
							<p className="test-progress-text">{quizzes} rondas de estudio</p>
						</div>
					</div>
				</div>
				<div className="footer">
					<Link to={"/micuenta/vocabulario/" + seminary.seminario}>
						<p>
							Estudiar este seminario <img src={NextArrow} alt="flecha apuntando a la derecha" />
						</p>
					</Link>
				</div>
			</div>
		</>
	);
}

export default VocabularyDetailSeminaryCard;
