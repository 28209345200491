import "./Modes.scss";
import { ReactComponent as RightArrow } from "@assets/icons/right-arrow.svg";
import { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	CountdownReference,
	GenTypes,
	GenerationTypesToSpanishModalitiesTable,
	TGeneration,
	isGenOnline,
} from "interfaces/Generations";
import { getCurrentAnniversary } from "@utils/utilFunctions";
import { getActiveGenerations } from "@services/GenerationService";
import Countdown from "./utils/CountDown";
import { getCountDownReference, getEnrollmentLimitDate } from "@services/MiscellaneousServices";
import ModalitySwitch from "./utils/ModalitySwitch";
import { useCurrencyContext } from "contexts/CurrencyContext";

// Aniversario actual (string de 2 digitos)
const year: string = getCurrentAnniversary();

type RowConstructors = {
	headerName: string;
	renderingFunction: Function;
};

const MONTHS_IN_SPANISH = {
	1: "ENERO",
	2: "FEBRERO",
	3: "MARZO",
	4: "ABRIL",
	5: "MAYO",
	6: "JUNIO",
	7: "JULIO",
	8: "AGOSTO",
	9: "SEPTIEMBRE",
	10: "OCTUBRE",
	11: "NOVIEMBRE",
	12: "DICIEMBRE"
  };
// TODO: Agregar implementacion para tomar precios en USD de la generacion en cuestion (si es que lo tiene)
export const Modes = () => {
	const [activeGenerations, setActiveGenerations] = useState<Array<TGeneration>>();
	const [enrollmentLimitDate, setEnrollmentLimitDate] = useState<Date>();
	const [countdownReference, setCountdownReference] = useState<CountdownReference>();
	const [counter, setCounter] = useState<number>(0);
	const [selectedModality, setSelectedModality] = useState<number>(0);
	const responsiveDisplayedGeneration = activeGenerations && activeGenerations[selectedModality];
	const {currencyCountry} = useCurrencyContext();
	// Cargamos el path a la imagen de forma dinámica, dependiendo de en que aniversario nos encontramos
	// Y se crea el elemento img con su 'src' = al path
	const anniversaryImgPath = require(`assets/images/contenido_aniversarios/aniversarios_svg_2/${year}.svg`);
	const anniversaryImg = <img src={anniversaryImgPath} />;

	const history = useHistory();

	const getAndSetCountdownReference = async () => {
		try {
			const response = await getCountDownReference();
			if (response) {
				setCountdownReference(response);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getAndSetEnrollmentLimitDate = async () => {
		try {
			const response = await getEnrollmentLimitDate();
			response && setEnrollmentLimitDate(response);
		} catch (error) {
			console.log(error);
		}
	};

	function stringToDate(dateString) {
		const [day, month, year] = dateString.split("/");
		return new Date(year, month - 1, day);
	}

	const renderTimePerWeek = (generation: TGeneration) => {
		return `${generation.hoursPerWeek ?? "2"} HORAS`;
	};

	const renderClassDay = (generation: TGeneration) => {
		return generation.classDay.toUpperCase();
	};

	const renderClassSchedule = (generation: TGeneration) => {
		return `${generation.startHour} - ${generation.endHour}`;
	};

	const renderTotalCourseHours = (generation: TGeneration) => {
		return generation.totalHours ?? "120";
	};

	const renderInitialDate = (generation: TGeneration) => {
		let dateStringParts:string[] = []
		let monthNumber = 0
		const separator = <span className="separator"></span>
		if (generation.initialDate){
			dateStringParts = generation.initialDate.split("/")
			monthNumber = parseInt(dateStringParts[1])
		}
		return <>{dateStringParts[0]} {separator} {MONTHS_IN_SPANISH[`${monthNumber}`]} {separator} {dateStringParts[2]}</>;
	};

	const renderLocation = (generation: TGeneration) => {
		return generation.sede.toUpperCase();
	};

	const renderRegularPrice = (generation: TGeneration) => {
		if (currencyCountry === "mx"){
			return `$${generation.regularPrice ?? 300} MXN`;
		} else {
			return `$${generation.regularPriceUSD ?? 18} USD`
		}
	};

	const modesTableVerticalHeadings: Array<RowConstructors> = [
		{ headerName: "HORAS SEMANALES", renderingFunction: renderTimePerWeek },
		{ headerName: "DÍAS", renderingFunction: renderClassDay },
		{ headerName: "HORARIO", renderingFunction: renderClassSchedule },
		{ headerName: "TOTAL DE HORAS", renderingFunction: renderTotalCourseHours },
		{ headerName: "FECHA DE INICIO", renderingFunction: renderInitialDate },
		{ headerName: "SEDE", renderingFunction: renderLocation },
		{ headerName: "TARIFA REGULAR", renderingFunction: renderRegularPrice },
	];

	const generationTypeTextStyle = (type: GenTypes): CSSProperties => {
		switch (type) {
			case GenTypes.InPerson:
				return { color: "#cf072c" };
			case GenTypes.OnlineAM:
				return { color: "#ffa333" };
			case GenTypes.OnlinePM:
				return { color: "#c7b2d5" };
			case GenTypes.Online:
				return { color: "#c7b2d5" };
			default:
				return {};
		}
	};

	const fetchActiveGenerations = async () => {
		try {
			const response = await getActiveGenerations();
			setActiveGenerations(response);
		} catch (error) {
			console.error(error);
		}
	};

	// IMPROVE: Este intervalo sirve para volver a renderizar el tiempo destante de los countdown, podria mejorarse?
	useEffect(() => {
		const inPersonInterval = setInterval(() => {
			setCounter((prevState) => {
				if (prevState > 100) {
					return 0;
				} else return prevState + 1;
			});
		}, 1000);
		return () => {
			clearInterval(inPersonInterval);
		};
	}, []);

	useEffect(() => {
		fetchActiveGenerations();
		getAndSetEnrollmentLimitDate();
		getAndSetCountdownReference();
	}, []);

	return activeGenerations != null ? (
		<div className={`modes-main-container`}>
			<h2>Nuestras modalidades</h2>
			<ModalitySwitch
				selectedElement={selectedModality}
				setSelectedElement={setSelectedModality}
				generations={activeGenerations}
			/>
			<table className="modalities-table modalities-table-non-responsive">
				<tr>
					<th>
						<div id="dash"></div>
					</th>
					{activeGenerations?.map((e) => {
						const style = generationTypeTextStyle(e.type as GenTypes);
						const dashColor = e.type === "online-am" ? "#ffa333" : "#c7b2d5";
						return (
							<td style={style}>
								<p>{GenerationTypesToSpanishModalitiesTable[e.type]}</p>
								<div>{e.type === GenTypes.OnlineAM ? "MATUTINO" : e.type === GenTypes.OnlinePM ? "VESPERTINO" : ""}</div>
								{isGenOnline(e.type) ? <div id="background-dash" style={{ borderBottom: `2px solid ${dashColor}` }}></div> : null}
							</td>
						);
					})}
				</tr>
				{modesTableVerticalHeadings.map((e1) => {
					const scheduleHeaderClass = "schedule-header";
					return (
						<tr>
							<th className={e1.headerName === "HORARIO" ? scheduleHeaderClass : ""}>{e1.headerName}</th>
							{activeGenerations?.map((e2, index) => {
								return <td style={{ borderLeft: "4px solid #dae3ec" }}>{e1.renderingFunction(e2)}</td>;
							})}
						</tr>
					);
				})}
				<tr className="yellow-bg">
					<th>{anniversaryImg}</th>
					{activeGenerations?.map((e) => {
						return (
							<td style={{ borderColor: "#d6ac43" }} className="promo-row-data">
								<p>${currencyCountry === "mx" ? `${e.promoPrice ?? 250} MXN` : `${e.promoPriceUSD ?? 15} USD`}</p>
								<span>POR SEMINARIO</span>
							</td>
						);
					})}
				</tr>
				<tr>
					<th style={{ backgroundColor: "#cf072c", color: "#fbc754", fontFamily: "Times New Roman" }}>
						VÁLIDA POR TIEMPO LIMITADO
					</th>
					{activeGenerations?.map((e) => {
						return (
							<td className="inscribete" onClick={() => history.push("/inscripcion")}>
								¡INSCRÍBETE YA! <RightArrow />
							</td>
						);
					})}
				</tr>
				<tr>
					<th></th>
					{activeGenerations?.map((e) => {
						if (e.countdownEnabled === true && enrollmentLimitDate && countdownReference) {
							const chosenDate =
								countdownReference === CountdownReference.EnrollmentLimitDate ? enrollmentLimitDate : stringToDate(e.initialDate);
							return (
								<td>
									{" "}
									<Countdown date={chosenDate} />
								</td>
							);
						} else return <td></td>;
					})}
				</tr>
			</table>
			{responsiveDisplayedGeneration && (
				<table className="modalities-table-responsive modalities-table">
					<tr>
					{/** Row vacio para evitar cambios de estilos de primer fila (revisar Modes.scss) */}
					</tr>
					{modesTableVerticalHeadings.map((e1) => {
						const scheduleHeaderClass = "schedule-header";
						return (
							<tr>
								<th className={e1.headerName === "HORARIO" ? scheduleHeaderClass : ""}>{e1.headerName}</th>
								<td style={{ borderLeft: "4px solid #dae3ec" }}>{e1.renderingFunction(responsiveDisplayedGeneration)}</td>
							</tr>
						);
					})}
					<tr className="yellow-bg">
						<th>{anniversaryImg}</th>
						<td style={{ borderColor: "#d6ac43" }} className="promo-row-data">
							{/* <p>${responsiveDisplayedGeneration.promoPrice ?? 250} MXN</p> */}
							<p>${currencyCountry === "mx" ? `${responsiveDisplayedGeneration.promoPrice ?? 250} MXN` : `${responsiveDisplayedGeneration.promoPriceUSD ?? 15} USD`}</p>
							<span>POR SEMINARIO</span>
						</td>
					</tr>
					<tr>
						<th style={{ backgroundColor: "#cf072c", color: "#fbc754", fontFamily: "Times New Roman" }}>
							VÁLIDA POR TIEMPO LIMITADO
						</th>
						<td className="inscribete" onClick={() => history.push("/inscripcion")}>
							¡INSCRÍBETE YA! <RightArrow />
						</td>
					</tr>
					<tr>
						<th></th>
						{activeGenerations?.map((e) => {
							if (e.countdownEnabled === true && enrollmentLimitDate && countdownReference) {
								const chosenDate =
									countdownReference === CountdownReference.EnrollmentLimitDate
										? enrollmentLimitDate
										: stringToDate(e.initialDate);
								return (
									<td>
										{" "}
										<Countdown date={chosenDate} />
									</td>
								);
							} else return <td></td>;
						})}
					</tr>
				</table>
			)}
		</div>
	) : (
		<h3>Cargando generaciones...</h3>
	);
};
