import { useEffect, useState } from "react";
import TestProgressBar from "./components/TestProgressBar";
import Chart from "chart.js/auto";
import "./TestStyles.scss";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";
import {
	langTextTest,
	specialKeyboard,
	qualification,
	seeWords,
	quizWords,
	Quizzes,
} from "interfaces/Interfaces";
import { getImage, returnFlag, returnFlagByLanguage, romanize } from "functions/Functions";
import {
	getUniqueTotalWords,
	getCorrectWord,
	getLangCode,
	getNumberOfSegments,
	getVoiceLangName,
	getWord,
	isCorrect,
	getUniqueTotalWordsArray,
	consultGermanDictionary,
	getCorrectChars,
	getTotalWords,
	cleanAudiosFirebase,
} from "./functions/TestFunctions";
import { addUserProgress, getWordSeminaryInfo, isWordBookmarked } from "@api/Vocabulary";
import axios from "axios";
import firebase from "firebase/compat/app";
import { specialCharacters, spanishTest, langTest, proTips } from "./functions/DefaultCharacters";
import parse from "html-react-parser";
import { WordsModal } from "./components/WordsModal";
import QuizResults from "./components/QuizResults/QuizResults";
import { NoteMark } from "./components/NoteMark/NoteMark";
import {
	getLangSpanish,
	getLangSpanishSimple,
} from "../../../../components/Vocabulary/PersonalizedTest/functions/functions";
import { MiddleScreen } from "./components/MiddleScreen/MiddleScreen";
import Form from "react-bootstrap/esm/Form";
import { ProgressAnimation } from "./components/ProgressAnimation/ProgressAnimation";
import { LoaderTest } from "./components/Loader/LoaderTest";
import { EditWordModal } from "@components/Vocabulary/EditWordModal/EditWordModal";
import {
	returnWarningBorderColor,
	returnWarningTypeImage,
	returnWarningTypeTitle,
} from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { TestWordContainer } from "./components/TestWordContainer/TestWordContainer";
import { WarningTypeSelector } from "./components/WarningTypeSelector/WarningTypeSelector";
import { EditQuizModal } from "./components/EditQuizModal/EditQuizModal";
import { CommentModal } from "@components/Vocabulary/CommentModal/CommentModal";
import {
	ArrowRed,
	BookmarkIcon,
	CloseIcon,
	GearIcon,
	LogoBig,
	NextArrow,
	RepeatArrow,
	RocketIcon,
	VolumeIconWhite,
	WrenchIcon,
} from "assets/Imports";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";
import useAudios from "@hooks/useAudios";
import { useStreak } from "@hooks/useStreak";
import { useStreakContext } from "contexts/VocabularyStreakContext";

export interface alerModal {
	show: boolean;
	title: string;
	message: string;
}

function Test({
	setView,
	testId,
	user,
	num,
	repeatIncorrects,
	repeat,
	dictionarySubColor,
	allWordsUser,
}) {
	const [loading, setLoading] = useState<boolean>(true);
	const [checkWord, setCheckWord] = useState<boolean>(false);
	const [seeResults, setSeeResults] = useState<boolean>(false);
	const [seeScore, setSeeScore] = useState<boolean>(false);
	const [seeProgress, setSeeProgress] = useState<boolean>(false);
	const [quiz, setQuiz] = useState<Quizzes>();
	const [wordIndex, setWordIndex] = useState<number>(0);
	const [answerText, setAnswerText] = useState<string>("");
	const [qualify, setQualify] = useState<qualification>();
	const [correctWords, setCorrectWords] = useState<number>(0);
	const [incorrectWords, setIncorrectWords] = useState<number>(0);
	const [chart, setChart] = useState<any>(null);
	const [loadingGrade, setLoadingGrade] = useState<boolean>(false);
	const [actualSegment, setActualSegment] = useState<number>(0);
	const [totalWords, setTotalWords] = useState<number>(0);
	const [audio, setAudio] = useState<string>("");
	const [loadAudio, setLoadAudio] = useState<boolean>(false);
	const [endLoading, setEndLoading] = useState<boolean>(false);
	const [actualSegmentQuiz, setActualSegmentQuiz] = useState<Array<quizWords>>();
	const [startTime, setStartTime] = useState<Date>(new Date());
	const [timeToAnswer, setTimeToAnswer] = useState<number>(0);
	const [specialKeyboard, setSpecialKeyboard] = useState<specialKeyboard>({
		toUpper: false,
		active: false,
	});
	const [slow, setSlow] = useState<boolean>(false);
	const [autoPlay, setAutoPlay] = useState<boolean>(true);
	const [marked, setMarked] = useState<boolean>(false);
	const [seeWords, setSeeWords] = useState<seeWords>({
		show: false,
		words: new Array(),
		type: "",
	});
	const [realTotalWords, setRealTotalWords] = useState<number>(0);
	const [middleScreen, setMiddleScreen] = useState<boolean>(false);
	const [loadingMiddleScreen, setLoadingMiddleScreen] = useState<boolean>(false);
	const [insertIndexSpecialChar, setInsertIndexSpecialChar] = useState<number>(0);
	const notesType = [
		"warning",
		"memory",
		"grammar",
		"advice",
		"fake_friend",
		"similarity",
		"pronunciation",
		"did_YouKnow",
	];
	//	MODALS
	const [editWordModal, setEditWordModal] = useState({ show: false, word: {} });
	const [middleScreenEditWordModal, setMiddleScreenEditWordModal] = useState<boolean>(false);
	const [bookmarkModal, setBookmarkModal] = useState<boolean>(false);
	const [commentModal, setCommentModal] = useState({
		show: false,
		word_data_id: {},
		seminary: {},
		vocabularyUserId: {},
		user_id: {},
		user_name: {},
		user_color: {},
		word: {},
	});

	//	Esta variable sirve para que aparezca el texto de incorrecto, cuando se vuelve a ingresar la palabra que salio mal con anterioridad
	// 	y vuelve a salir mal
	const [isWrong, setIsWrong] = useState<boolean>(false);

	const [quizLangText, setQuizLangText] = useState<langTextTest>();
	const [seeInfo, setSeeInfo] = useState<boolean>(true);
	const [isRightText, setIsRightText] = useState<string>("");
	const [lastListen, setLastListen] = useState<number>(-1);

	const [translate, setTranslate] = useState<boolean>(true);

	const [dominated, setDominated] = useState<number>(0);
	const [lastDominated, setLastDominated] = useState<number>(0);

	const [colorWords, setColorWords] = useState({ answer: "", correct: "" });
	const [warningType, setWarningType] = useState<string>("warning");
	const [editQuizModal, setEditQuizModal] = useState<boolean>(false);
	const [countRefresh, setCountRefresh] = useState<number>(0); //Estados para actualizar UI (recargar componentes) cuando hay una edicion de palabra.
	const { addDateStreak } = useStreakContext();
	const { getGKey, playWordAudio } = useAudios();

	const manageError = (error, message?) => {
		console.log(error);
		alert(message === undefined ? "Ha habido un error de conexión." : message);
		setView(1);
	};

	const getQuiz = async (refresh?) => {
		let errorMessage = "";
		try {
			setLoadingGrade(true);
			const quizRef = doc(db, "quiz", testId);
			const docSnap = await getDoc(quizRef);

			if (docSnap.exists()) {
				const data = {
					id: docSnap.id,
					answerType: docSnap.data().answerType,
					finished: docSnap.data().finished,
					seminaryNum: docSnap.data().seminaryNum,
					userId: docSnap.data().userId,
					quizWords: docSnap.data().quizWords,
					createdAt: docSnap.data().createdAt,
					finishedAt: docSnap.data().finishedAt,
					difficult: docSnap.data().difficult,
					language: docSnap.data().language,
					correctWords: docSnap.data().correctWords,
					incorrectWords: docSnap.data().incorrectWords,
					index: docSnap.data().index,
					actualSegment: docSnap.data().actualSegment,
					name: docSnap.data().name,
					timeToAnswer: docSnap.data().timeToAnswer ? docSnap.data().timeToAnswer : 0,
					errorsCounter: docSnap.data().errorsCounter ? docSnap.data().errorsCounter : 0,
					acerteClicks: docSnap.data().acerteClicks ? docSnap.data().acerteClicks : 0,
					...(docSnap.data().default && { default: true }),
					...(docSnap.data().finishedDefault && {
						finishedDefault: docSnap.data().finishedDefault,
					}),
					errorsWords: docSnap.data().errorsWords ? docSnap.data().errorsWords : [],
					dominatedWords: docSnap.data().dominatedWords ? docSnap.data().dominatedWords : [],
					times_answered: docSnap.data().times_answered ? docSnap.data().times_answered : 0,
					times_correct_answer: docSnap.data().times_correct_answer
						? docSnap.data().times_correct_answer
						: 0,
					acerteWords: docSnap.data().acerteWords || [],
				};

				if (data.actualSegment > 0) {
					JSON.parse(JSON.stringify(data.incorrectWords)).map((word) => {
						if (data.quizWords[7].length < 8) {
							if (
								word.segment !== data.actualSegment &&
								JSON.parse(JSON.stringify(data.quizWords[data.actualSegment])).filter(
									(e) => e.id === word.id
								).length === 0 &&
								word.noMore !== true
							) {
								word.timesSeen =
									word.timesSeen === undefined ? actualSegment + 1 : word.timesSeen + 1;
								data.quizWords[data.actualSegment].push(word);
							}
							let difference = data.quizWords[data.actualSegment].length - 8;
							let moved = data.quizWords[data.actualSegment].splice(0, difference);

							let index = data.actualSegment;
							moved.map((wordM) => {
								for (let i = data.actualSegment; i < 8; i++) {
									if (data.quizWords[i].length < 8) {
										data.quizWords[i].push(wordM);
										break;
									}
								}
							});
						}
					});
				}

				const wordsToDelete: Array<number> = [];
				const segmentsToUpdate = data.finished ? [0, 1, 2, 3, 4, 5, 6, 7] : [data.actualSegment];
				try {
					await Promise.all(
						segmentsToUpdate.map(async (segment) => {
							await Promise.all(
								data.quizWords[segment].map(async (word, index: number) => {
									await getWordSeminaryInfo(word.id)
										.then(async (wordInfo: Array<any>) => {
											if (wordInfo[0] === undefined) {
												wordsToDelete.push(index);
												return null;
												//errorMessage = "El quiz cuenta con alguna palabra que ha sido eliminada.";
											} else {
												let infoData: any = {
													singular_category: wordInfo[0].singular,
													native_word: wordInfo[0].native_word,
													category: [wordInfo[0].family],
													meaning: wordInfo[0].meanings.split("|"),
													warning: wordInfo[0].warning,
													optional_word: wordInfo[0].optional_word,
													memory: wordInfo[0].memory,
													grammar: wordInfo[0].grammar,
													advice: wordInfo[0].advice,
													fake_friend: wordInfo[0].fake_friend,
													similarity: wordInfo[0].similarity,
													language_Id: wordInfo[0].language_Id,
													sub_category:
														wordInfo[0].sub_categorías === "sin subcategorías"
															? [null]
															: wordInfo[0].sub_categorías.split("|"),
													pronunciation: wordInfo[0].pronunciation,
													did_YouKnow: wordInfo[0].did_YouKnow,
												};

												const iIndex = data.incorrectWords.findIndex((e) => e.id === word.id);
												if (iIndex !== -1) {
													data.incorrectWords[iIndex].native_word = infoData.native_word;
													data.incorrectWords[iIndex].meaning = infoData.meaning;
												}
												const cIndex = data.correctWords.findIndex((e) => e.id === word.id);
												if (cIndex !== -1) {
													data.correctWords[cIndex].native_word = infoData.native_word;
													data.correctWords[cIndex].meaning = infoData.meaning;
												}

												data.quizWords[segment][index].meaning = infoData.meaning;
												data.quizWords[segment][index].category = infoData.category;
												data.quizWords[segment][index].singular_category =
													infoData.singular_category;
												data.quizWords[segment][index].native_word = infoData.native_word;
												data.quizWords[segment][index].optional_word = infoData.optional_word;
												data.quizWords[segment][index].warning = infoData.warning;
												data.quizWords[segment][index].memory = infoData.memory;
												data.quizWords[segment][index].grammar = infoData.grammar;
												data.quizWords[segment][index].advice = infoData.advice;
												data.quizWords[segment][index].fake_friend = infoData.fake_friend;
												data.quizWords[segment][index].similarity = infoData.similarity;
												data.quizWords[segment][index].sub_category = infoData.sub_category;
												data.quizWords[segment][index].pronunciation = infoData.pronunciation;
												data.quizWords[segment][index].did_YouKnow = infoData.did_YouKnow;
												data.quizWords[segment][index].language_Id = infoData.language_Id;
												getNoteType(infoData);
											}
										})
										.catch((err) => {
											console.log(err);
										});
								})
							);
						})
					);
				} catch (error) {
					console.log(error);
					manageError(error, errorMessage);
				}
				wordsToDelete
					.sort((a, b) => b - a)
					.map((index) => {
						data.quizWords[data.actualSegment].splice(index, 1);
					});

				// setQuizLangText(langTest[data.language] || spanishTest)
				updateTexts(data);
				setTotalWords(getUniqueTotalWords(data.quizWords));
				setRealTotalWords(getTotalWords(data.quizWords));
				setTimeToAnswer(data.timeToAnswer);
				setWordIndex(data.index);
				setCorrectWords(data.correctWords.length);
				setIncorrectWords(data.incorrectWords.length);
				setActualSegment(data.actualSegment);
				setActualSegmentQuiz(JSON.parse(JSON.stringify(data.quizWords[data.actualSegment])));
				setQuiz(data);
				if (
					(data.quizWords[data.actualSegment].length === 0 ||
						(data.actualSegment === 7 &&
							data.quizWords[data.actualSegment].filter((e) => e.checked === true).length ===
								data.quizWords[data.actualSegment].length)) &&
					!data.finished
				) {
					setEndLoading(true);
				}
				if (
					data.actualSegment === 0 &&
					data.quizWords[data.actualSegment].filter((e) => e.checked === true).length === 0 &&
					refresh === undefined
				) {
					setMiddleScreen(true);
				}
				setLoadingMiddleScreen(false);
				setLoadingGrade(false);
				setLoading(false);
			} else {
				alert("Ha ocurrido un error");
				setView(1);
			}
		} catch (error) {
			console.log(error);
			alert("Hubo un error al cargar tu ronda de estudio");
			setView(1);
		}
	};

	const repeats = async () => {
		setLoading(true);
		setSeeResults(false);
		setChart(null);
		setTimeToAnswer(0);
		setStartTime(new Date());
		await repeat(quiz!);
		setTimeout(() => {
			getQuiz();
		}, 500);
	};

	function comparePlace(a, b) {
		if (a.place < b.place) {
			return -1;
		}
		if (a.place > b.place) {
			return 1;
		}
		return 0;
	}

	const repeatI = async () => {
		setLoading(true);
		setSeeResults(false);
		setChart(null);
		setTimeToAnswer(0);
		setStartTime(new Date());
		await repeatIncorrects(quiz!);
		setTimeout(() => {
			getQuiz();
		}, 500);
	};

	const handleClickEventAudio = (e) => {
		switch (e.detail) {
			case 1:
				setAutoPlay(!autoPlay);
				break;
			case 2:
				setAutoPlay(!autoPlay);
				listenWord();
				break;
			default:
				break;
		}
	};

	const updateTexts = (data?) => {
		const qt = quiz !== undefined ? JSON.parse(JSON.stringify(quiz)) : data;
		setQuizLangText({
			correcto:
				langTest[qt.language] && translate
					? langTest[qt.language].correcto[
							Math.floor(Math.random() * langTest[qt.language].correcto.length)
					  ]
					: spanishTest.correcto[Math.floor(Math.random() * spanishTest.correcto.length)],
			incorrecto:
				langTest[qt.language] && translate
					? langTest[qt.language].incorrecto[
							Math.floor(Math.random() * langTest[qt.language].incorrecto.length)
					  ]
					: spanishTest.incorrecto[Math.floor(Math.random() * spanishTest.incorrecto.length)],
			termino:
				langTest[qt.language] && translate
					? langTest[qt.language].termino[
							Math.floor(Math.random() * langTest[qt.language].termino.length)
					  ]
					: spanishTest.termino[Math.floor(Math.random() * spanishTest.termino.length)],
			middleScreen:
				langTest[qt.language] && translate
					? langTest[qt.language].middleScreen[
							Math.floor(Math.random() * langTest[qt.language].middleScreen.length)
					  ]
					: spanishTest.middleScreen[Math.floor(Math.random() * spanishTest.middleScreen.length)],
			finalScreen:
				langTest[qt.language] && translate
					? langTest[qt.language].finalScreen[
							Math.floor(Math.random() * langTest[qt.language].finalScreen.length)
					  ]
					: spanishTest.finalScreen[Math.floor(Math.random() * spanishTest.finalScreen.length)],
			respuestaCorrecta:
				langTest[qt.language] && translate
					? langTest[qt.language].respuestaCorrecta
					: spanishTest.respuestaCorrecta,
			tuRespuesta:
				langTest[qt.language] && translate
					? langTest[qt.language].tuRespuesta
					: spanishTest.tuRespuesta,
			buttons: {
				acerte:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.acerte
						: spanishTest.buttons.acerte,
				continuar:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.continuar
						: spanishTest.buttons.continuar,
				revisar:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.revisar
						: spanishTest.buttons.revisar,
				nolose:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.nolose
						: spanishTest.buttons.nolose,
				repetir:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.repetir
						: spanishTest.buttons.repetir,
				terminar:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.terminar
						: spanishTest.buttons.terminar,
				saltar:
					langTest[qt.language] && translate
						? langTest[qt.language].buttons.saltar
						: spanishTest.buttons.saltar,
			},
			escribeTerminoCorrectamente:
				langTest[qt.language] && translate
					? langTest[qt.language].escribeTerminoCorrectamente
					: spanishTest.escribeTerminoCorrectamente,
			finalScreenSubtitle:
				langTest[qt.language] && translate
					? langTest[qt.language].finalScreenSubtitle[
							Math.floor(Math.random() * langTest[qt.language].finalScreenSubtitle.length)
					  ]
					: spanishTest.finalScreenSubtitle[
							Math.floor(Math.random() * spanishTest.finalScreenSubtitle.length)
					  ],
			terminosBloque:
				langTest[qt.language] && translate
					? langTest[qt.language].terminosBloque
					: spanishTest.terminosBloque,
			escribeEn:
				langTest[qt.language] && translate
					? langTest[qt.language].escribeEn
					: spanishTest.escribeEn,
		});
	};

	const gradeAnswer = async (e) => {
		e.preventDefault();
		setLoadingGrade(true);

		let endTime = new Date();
		const secondsToAnswer = (endTime.getTime() - startTime.getTime()) / 1000;
		let actualWord: quizWords = JSON.parse(JSON.stringify(actualSegmentQuiz![wordIndex]));
		let actualWord2: quizWords = JSON.parse(JSON.stringify(actualSegmentQuiz![wordIndex]));
		let correctAnswersArray: Array<quizWords> = new Array(
			...JSON.parse(JSON.stringify(quiz!.correctWords))
		);
		let incorrectAnswersArray: Array<quizWords> = JSON.parse(JSON.stringify(quiz!.incorrectWords));
		let quizWordsArray: Array<quizWords> = JSON.parse(JSON.stringify(actualSegmentQuiz!));
		let quizTemp: Quizzes = JSON.parse(JSON.stringify(quiz!));
		const answerType: string = JSON.parse(JSON.stringify(quiz!.answerType));

		actualWord.checked = true;
		actualWord.times_answered += 1;
		actualWord2.times_answered += 1;
		actualWord.answer = answerText;
		delete actualWord.audio;
		delete actualWord2.audio;

		if (isCorrect(quiz!.language, answerType, actualWord, answerText)) {
			actualWord.times_correct_answer += 1;
			if (actualWord.dominated === null) {
				actualWord.dominated = 1;
			} else {
				const index = incorrectAnswersArray.findIndex((e) => e.id === actualWord.id);

				if (index !== -1) {
					incorrectAnswersArray.splice(index, 1);
				}

				actualWord.dominated += 1;
			}
			correctAnswersArray.push(actualWord);
		} else {
			quizTemp.errorsCounter += 1;
			actualWord2.answer = answerText;
			quizTemp.errorsWords.push(actualWord2);

			if (actualWord.dominated === null) {
				actualWord.dominated = -1;
			} else if (actualWord.dominated !== null) {
				actualWord.dominated -= 1;
			}

			actualWord2.dominated = actualWord.dominated;
			actualWord2.segment = actualSegment;

			const index = incorrectAnswersArray.findIndex((e) => e.id === actualWord.id);
			if (index !== -1) {
				incorrectAnswersArray[index] = actualWord2;
			} else {
				incorrectAnswersArray.push(actualWord2);
			}
		}
		quizWordsArray[wordIndex] = actualWord;

		setActualSegmentQuiz(quizWordsArray);
		quizTemp.quizWords[actualSegment] = await cleanAudiosFirebase(quizWordsArray);
		quizTemp.correctWords = correctAnswersArray;
		quizTemp.incorrectWords = incorrectAnswersArray;
		quizTemp.timeToAnswer = timeToAnswer + (secondsToAnswer < 180 ? secondsToAnswer : 180);
		if (quiz!.answerType === "selected-lang" && autoPlay) {
			setTimeout(() => {
				listenWord("normal");
			}, 750);
		}

		await Promise.all(
			quizTemp.errorsWords.map((word) => {
				if (word.audio) {
					delete word.audio;
				}
			})
		);
		setTimeToAnswer(timeToAnswer + (secondsToAnswer < 180 ? secondsToAnswer : 180));
		setQuiz(quizTemp);

		setCorrectWords(correctAnswersArray.length);
		setIncorrectWords(incorrectAnswersArray.length);
		updateTexts();

		const quizRef = doc(db, "quiz", testId);
		await updateDoc(quizRef, {
			correctWords: correctAnswersArray,
			incorrectWords: incorrectAnswersArray,
			quizWords: quizTemp.quizWords,
			timeToAnswer: quizTemp.timeToAnswer,
			errorsCounter: quizTemp.errorsCounter,
			errorsWords: quizTemp.errorsWords,
		}).then(() => {
			setCheckWord(true);
			setLoadingGrade(false);
			setAnswerText("");
			setAudio("");
		});
	};

	const noMore = async () => {
		setLoadingGrade(true);
		const actualWord: quizWords = JSON.parse(JSON.stringify(actualSegmentQuiz![wordIndex]));
		const incorrectAnswersArray: Array<quizWords> = JSON.parse(
			JSON.stringify(quiz!.incorrectWords)
		);

		actualWord.noMore = true;

		const index = incorrectAnswersArray.findIndex((e) => e.id === actualWord.id);
		if (index !== -1) {
			incorrectAnswersArray[index] = actualWord;
		} else {
			incorrectAnswersArray.push(actualWord);
		}

		const quizRef = doc(db, "quiz", testId);
		await updateDoc(quizRef, {
			incorrectWords: incorrectAnswersArray,
		}).then(() => {
			getQuiz();
			nextAnswer();
		});
	};

	function getNextIndex(index: number) {
		let indexT = index;
		let found: boolean = false;
		let segmentWordsArray = JSON.parse(JSON.stringify(actualSegmentQuiz!));

		do {
			if (segmentWordsArray.filter((e) => e.checked === true).length === segmentWordsArray.length) {
				indexT = 0;
				found = true;
			} else {
				if (
					segmentWordsArray[indexT].checked === undefined ||
					segmentWordsArray[indexT].checked === false
				) {
					found = true;
				} else if (indexT === segmentWordsArray.length - 1) {
					indexT = 0;
				} else {
					indexT = indexT + 1;
				}
			}
		} while (!found);

		return indexT;
	}

	function getActualSegment() {
		let segmentWordsArray = JSON.parse(JSON.stringify(actualSegmentQuiz!));

		if (segmentWordsArray.filter((e) => e.checked === true).length === segmentWordsArray.length) {
			if (actualSegment === 7) {
				return actualSegment;
			} else {
				setLoadingMiddleScreen(true);
				setMiddleScreen(true);
				setChart(null);
				return actualSegment + 1;
			}
		} else {
			return actualSegment;
		}
	}

	const nextAnswer = async () => {
		if (actualSegmentQuiz![wordIndex].checked === true) {
			let nextIndex = getNextIndex(wordIndex);
			let nextSegment = getActualSegment();
			const quizRef = doc(db, "quiz", testId);
			await updateDoc(quizRef, {
				index: nextIndex,
				actualSegment: nextSegment,
			}).then(() => {
				setWordIndex(nextIndex);
				setActualSegment(nextSegment);
				if (
					actualSegmentQuiz!.filter((e) => e.checked === true).length === actualSegmentQuiz!.length
				) {
					getQuiz();
				} else if (autoPlay) {
					setTimeout(() => {
						listenWord(quiz!.answerType, nextIndex);
					}, 750);
				}
			});
		} else {
			setCheckWord(false);
		}
	};

	const isRight = async (word: quizWords) => {
		let index = quiz!.incorrectWords.findIndex((e) => e.id === word.id);
		const tempAcerteWords = [...quiz!.acerteWords];
		quiz!.incorrectWords.splice(index, 1);
		if (word.dominated! < 2) {
			word.dominated! += 2;
		} else if (word.dominated! >= 3) {
			word.dominated = 3;
		} else {
			word.dominated! += 1;
		}
		word.checked = true;
		word.times_correct_answer += 1;
		tempAcerteWords.push(word);
		quiz!.quizWords[actualSegment][wordIndex] = word;
		quiz!.correctWords.push(word);
		quiz!.acerteWords = tempAcerteWords;
		setCorrectWords(quiz!.correctWords.length);
		setIncorrectWords(quiz!.incorrectWords.length);
		setQuiz(quiz!);
		const quizRef = doc(db, "quiz", testId);
		await updateDoc(quizRef, {
			correctWords: quiz!.correctWords,
			incorrectWords: quiz!.incorrectWords,
			quizWords: quiz!.quizWords,
			acerteClicks: quiz!.acerteClicks + 1,
			acerteWords: tempAcerteWords,
		}).then(() => {
			const element2 = document.getElementById("next-word-btn");
			if (element2 !== null) {
				getDominated();
				setTimeout(() => {
					element2.click();
				}, 2500);
			}
		});
	};

	const tryAgain = async (word: quizWords) => {
		let index = quiz!.incorrectWords.findIndex((e) => e.id === word.id);
		word.dominated! += 1;
		word.checked = false;

		if (word.dominated === 0) {
			quiz!.incorrectWords.splice(index, 1);
		}
		setIncorrectWords(quiz!.incorrectWords.length);
		setQuiz(quiz!);

		const quizRef = doc(db, "quiz", testId);
		await updateDoc(quizRef, {
			incorrectWords: quiz!.incorrectWords,
			quizWords: quiz!.quizWords,
		}).then(() => {
			getDominated();
			setCheckWord(false);
		});
	};

	const isRightTextCorrect = () => {
		if (!isCorrect(quiz!.language, quiz!.answerType, actualSegmentQuiz![wordIndex], isRightText)) {
			setIsWrong(true);
			setTimeout(() => {
				setIsWrong(false);
			}, 6000);
		} else if (
			isCorrect(quiz!.language, quiz!.answerType, actualSegmentQuiz![wordIndex], isRightText)
		) {
			nextAnswer();
		}
	};

	const newChart = () => {
		const ctx: any = document.getElementById("myChart");
		const chart = new Chart(ctx, {
			type: "doughnut",
			data: {
				datasets: [
					{
						data: [incorrectWords, correctWords, totalWords - correctWords - incorrectWords],
						backgroundColor: ["#C4453F", "#02A377", "#D9DADF"],
						borderColor: ["#C4453F", "#02A377", "#D9DADF"],
					},
				],
			},
			options: {
				onClick: function (e, activeEls) {
					const index = activeEls[0].index;

					if (index === 1) {
						showSeeWords(quiz!.correctWords, "correctos");
					} else if (index === 0) {
						showSeeWords(quiz!.incorrectWords, "incorrectos");
					}
				},
			},
		});

		setChart(chart);
	};
	const endQuiz = async (final?) => {
		setEndLoading(true);

		const endQuiz = quiz !== undefined ? quiz : final;

		const finalWords = getUniqueTotalWordsArray(endQuiz.quizWords);
		const date = new Date();
		const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

		await addUserProgress(user.vocabularyUserId, finalWords, dateString, timeToAnswer)
			.then(async (res) => {
				const quizRef = doc(db, "quiz", testId);

				await updateDoc(quizRef, {
					finishedAt: new Date(),
					finished: true,
					...(quiz!.default && {
						finishedDefault:
							quiz!.incorrectWords.length === 0 || quiz!.finishedDefault === true ? true : false,
					}),
				}).then(async () => {
					const timeRef = doc(db, "quizTimes", user.id + "-" + num);
					const timeDoc = await getDoc(timeRef);
					await updateDoc(timeRef, {
						[endQuiz.language]: timeDoc.data()![endQuiz.language] + timeToAnswer,
					}).then(() => {
						setSeeResults(true);
						setEndLoading(false);
					});
				});
			})
			.catch((error) => {
				console.log(error);
				manageError(error);
			});
	};

	const listenWord = async (type?, dIndex?) => {
		setLoadAudio(true);
		if (dIndex !== undefined && dIndex !== lastListen) setAudio("");
		const listenIndex = dIndex !== undefined ? dIndex : wordIndex;
		const audioString =
			(dIndex !== undefined && dIndex !== lastListen) ||
			(type !== undefined && dIndex === undefined)
				? ""
				: audio;
		const inputElement = document.getElementById("answer");

		if (inputElement !== null) {
			inputElement.focus();
		}
		if (audioString === "") {
			const gKey = await getGKey();
			try {
				if (
					actualSegmentQuiz![listenIndex].audio === undefined ||
					actualSegmentQuiz![listenIndex].audio === null ||
					(slow && actualSegmentQuiz![listenIndex].audio!.length > 0)
				) {
					await axios
						.post(
							`https://texttospeech.googleapis.com/v1beta1/text:synthesize`,
							{
								audioConfig: {
									audioEncoding: "LINEAR16",
									pitch: 0,
									speakingRate: slow ? 0.7 : 1,
								},
								input: {
									text: `${getWord(
										type !== undefined ? type : quiz!.answerType,
										actualSegmentQuiz![listenIndex],
										true
									)}`,
								},
								voice: {
									languageCode: `${getLangCode(
										type !== undefined ? type : quiz!.answerType,
										actualSegmentQuiz![listenIndex]
									)}`,
									name: `${getVoiceLangName(
										type !== undefined ? type : quiz!.answerType,
										actualSegmentQuiz![listenIndex]
									)}`,
								},
							},
							{
								headers: {
									Authorization: `Bearer ${gKey}`,
									"X-Goog-User-Project": "academia-de-lenguas-f6f35",
									"Content-Type": "application/json",
								},
							}
						)
						.then(async (res) => {
							setLoadAudio(false);
							setLastListen(dIndex !== undefined ? dIndex : -1);

							setAudio(res.data.audioContent);
							playWordAudio(res.data.audioContent);
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					setLoadAudio(false);
					setAudio(actualSegmentQuiz![listenIndex].audio!);
					playWordAudio(actualSegmentQuiz![listenIndex].audio!);
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			setLastListen(dIndex !== undefined ? dIndex : -1);
			setLoadAudio(false);
			playWordAudio(audioString);
		}
	};

	const closeModals = () => {
		setSeeProgress(false);
		setSeeScore(false);
	};

	async function isMarked(wordId: number) {
		await isWordBookmarked(wordId)
			.then((res) => {
				if (res.bookmark.length > 0) {
					setMarked(true);
				} else {
					setMarked(false);
				}
			})
			.catch((error) => {
				console.log(error);
				manageError(error);
			});
	}

	const showSeeWords = (words: Array<quizWords>, type: string) => {
		setSeeWords({ show: true, words: words, type: type });
	};

	const handleSpecialKeyboard = (char: string, inputId: string) => {
		if (inputId === "answer") {
			let tempTextArray = answerText.split("");
			tempTextArray.splice(insertIndexSpecialChar, 0, char);
			let tempTextString = "";
			tempTextArray.map((char) => {
				tempTextString += char;
			});
			setAnswerText(tempTextString);
			const element: any = document.getElementById(inputId);
			if (element !== null) {
				element.focus();
				setTimeout(() => {
					let newSelectionIndex = insertIndexSpecialChar + 1;
					element.setSelectionRange(newSelectionIndex, newSelectionIndex);
					setInsertIndexSpecialChar(newSelectionIndex);
				}, 2);
			}
		} else {
			let tempTextArray = isRightText.split("");
			tempTextArray.splice(insertIndexSpecialChar, 0, char);
			let tempTextString = "";
			tempTextArray.map((char) => {
				tempTextString += char;
			});
			setIsRightText(tempTextString);
			const element: any = document.getElementById(inputId);
			if (element !== null) {
				element.focus();
				setTimeout(() => {
					let newSelectionIndex = insertIndexSpecialChar + 1;
					element.setSelectionRange(newSelectionIndex, newSelectionIndex);
					setInsertIndexSpecialChar(newSelectionIndex);
				}, 2);
			}
		}
	};

	const getNoteType = (word) => {
		notesType.map((note) => {
			if (word[note] !== "undefined" && word[note] !== undefined && word[note].length > 0) {
				setWarningType(note);
			}
		});
	};

	// 	FUNCION PARA VER EL NIVEL DE PROGRESO DE LA PALABRA DURANTE EL QUIZ Y COLOREAR LAS BOLITAS DE PROGRESO EN EL LUGAR CORRESPONDIENTE
	const getDominated = async () => {
		if (actualSegmentQuiz![wordIndex] !== undefined) {
			let index = allWordsUser[quiz!.language].findIndex(
				(e) => e.id === actualSegmentQuiz![wordIndex].id
			);
			if (index === -1)
				index = allWordsUser[quiz!.language].findIndex(
					(e) => e.word_id === actualSegmentQuiz![wordIndex].word_id
				);
			let total =
				(index !== -1 ? allWordsUser[quiz!.language][index].dominated : 0) +
				actualSegmentQuiz![wordIndex].dominated;

			if (index !== -1 && actualSegmentQuiz![wordIndex].checked) {
				total =
					allWordsUser[quiz!.language][index].dominated + actualSegmentQuiz![wordIndex].dominated;
				if (total === 3 && allWordsUser[quiz!.language][index].dominated < 3) {
					let temp = JSON.parse(JSON.stringify(quiz!.dominatedWords));
					if (temp.findIndex((e) => e.id === allWordsUser[quiz!.language][index].id) === -1) {
						temp.push(allWordsUser[quiz!.language][index]);
					}

					if (temp !== undefined) {
						const quizRef = doc(db, "quiz", testId);
						await updateDoc(quizRef, {
							dominatedWords: temp,
						}).then(() => {
							setQuiz({ ...quiz!, dominatedWords: temp });
						});
					}
				}
				let tempD = JSON.parse(JSON.stringify(dominated));
				if (total > 3) {
					total = 3;
				} else if (total < -3) {
					total = -3;
				}
				if (tempD > 3) {
					tempD = tempD / 10;
				} else if (tempD < -3) {
					tempD = tempD / 10;
				}

				if (total !== dominated) {
					setLastDominated(tempD);
					setDominated(dominated * 10);
					setDominated(total !== null ? total : 0);
				}
			} else {
				let tempD = dominated;
				if (total > 3) {
					total = 3;
				} else if (total < -3) {
					total = -3;
				}
				if (tempD > 3) {
					tempD = tempD / 10;
				} else if (tempD < -3) {
					tempD = tempD / 10;
				}
				setLastDominated(tempD);
				setDominated(total);
			}
		}
	};

	const getColorWords = async () => {
		const correct =
			quiz!.answerType === "normal"
				? await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						actualSegmentQuiz![wordIndex].meaning,
						[qualify?.answer!],
						"correct",
						qualify?.correctAnswer
				  )
				: quiz!.answerType === "selected-lang"
				? await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						quiz!.language === "japanese"
							? [
									actualSegmentQuiz![wordIndex].native_word!,
									actualSegmentQuiz![wordIndex].optional_word,
							  ]
							: [actualSegmentQuiz![wordIndex].native_word!],
						[qualify!.answer!],
						"correct",
						dictionarySubColor,
						quiz!.language === "japanese"
							? [
									actualSegmentQuiz![wordIndex].native_word!,
									actualSegmentQuiz![wordIndex].optional_word,
							  ]
							: [actualSegmentQuiz![wordIndex].native_word!]
				  )
				: await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						[
							actualSegmentQuiz![wordIndex].optional_word !== null
								? actualSegmentQuiz![wordIndex].optional_word!
								: actualSegmentQuiz![wordIndex].native_word!,
						],
						[qualify!.answer!],
						"correct",
						dictionarySubColor
				  );

		const answer =
			quiz!.answerType === "normal"
				? await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						actualSegmentQuiz![wordIndex].meaning,
						[qualify!.answer!],
						"answer",
						dictionarySubColor
				  )
				: quiz!.answerType === "selected-lang"
				? await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						quiz!.language === "japanese"
							? [
									actualSegmentQuiz![wordIndex].native_word!,
									actualSegmentQuiz![wordIndex].optional_word,
							  ]
							: [actualSegmentQuiz![wordIndex].native_word!],
						[qualify!.answer!],
						"answer",
						dictionarySubColor
				  )
				: await getCorrectChars(
						actualSegmentQuiz![wordIndex],
						quiz!.answerType,
						quiz!.language,
						[
							actualSegmentQuiz![wordIndex].optional_word !== null
								? actualSegmentQuiz![wordIndex].optional_word!
								: actualSegmentQuiz![wordIndex].native_word!,
						],
						[qualify!.answer!],
						"answer",
						dictionarySubColor
				  );

		setColorWords({ answer: answer, correct: correct });
	};

	useEffect(() => {
		if (checkWord && !seeResults) {
			getColorWords();
		} else {
			setColorWords({ answer: "", correct: "" });
		}
	}, [qualify]);

	useEffect(() => {
		if (quiz !== undefined && quiz!.finished === false) {
			getDominated();
		}
	}, [wordIndex, checkWord, middleScreen]);

	//	ACTUALIZAR TEXTOS CADA QUE SE ACTIVE O DESACTIVE EL TRADUCIR
	useEffect(() => {
		if (quiz !== undefined) {
			updateTexts();
		}
	}, [translate]);

	//	LISTENER FOR KEYSDOWN
	useEffect(() => {
		const handleKeyDown = (e) => {
			if (
				e.key === "1" &&
				bookmarkModal === false &&
				editWordModal.show === false &&
				commentModal.show === false &&
				seeWords.show === false
			) {
				const element = document.getElementById("isRight-btn");
				if (element !== null) {
					element.click();
				}
			} else if (e.key === "Enter") {
				if (
					!middleScreen &&
					bookmarkModal === false &&
					editWordModal.show === false &&
					commentModal.show === false &&
					seeWords.show === false
				) {
					const element = document.getElementById("next-word-btn");
					if (element !== null) {
						element.click();
					}
					const element2 = document.getElementById("qualify-word-btn");
					if (element2 !== null) {
						element2.click();
					}
					if (
						quiz!.incorrectWords.findIndex((e) => e.id === qualify?.word.id) !== -1 &&
						checkWord
					) {
						isRightTextCorrect();
					}
				} else if (middleScreen) {
					const element2 = document.getElementById("middle-screen-continue");
					if (element2 !== null) {
						element2.click();
					}
				}
			} else if (
				e.key === " " &&
				middleScreen &&
				editWordModal.show === false &&
				commentModal.show === false &&
				middleScreenEditWordModal === false
			) {
				const element2 = document.getElementById("middle-screen-continue");
				if (element2 !== null) {
					element2.click();
				}
			} else if (
				e.key === "2" &&
				editWordModal.show === false &&
				commentModal.show === false &&
				seeWords.show === false
			) {
				const element = document.getElementById("pass-word-btn");
				if (element !== null) {
					element.click();
				}
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		setAudio("");
		return function cleanUpListener() {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [
		slow,
		middleScreen,
		quiz,
		qualify,
		checkWord,
		isRightText,
		bookmarkModal,
		editWordModal,
		commentModal,
		middleScreenEditWordModal,
		seeWords.show,
	]);

	useEffect(() => {
		setIsRightText("");
		if (!endLoading && !seeResults) {
			if (
				actualSegmentQuiz !== undefined &&
				actualSegmentQuiz!.length > 0 &&
				actualSegmentQuiz![wordIndex].checked === true
			) {
				setQualify({
					correct: isCorrect(
						quiz!.language,
						quiz!.answerType,
						actualSegmentQuiz[wordIndex],
						actualSegmentQuiz[wordIndex].answer!
					),
					answer: actualSegmentQuiz[wordIndex].answer,
					correctAnswer: getCorrectWord(quiz!.answerType, actualSegmentQuiz[wordIndex]).split(" "),
					category: [actualSegmentQuiz[wordIndex].singular_category],
					subCategory: actualSegmentQuiz[wordIndex].sub_category.filter((e) => e !== null),
					nativeWord: actualSegmentQuiz[wordIndex].native_word,
					word: actualSegmentQuiz[wordIndex],
				});
				setCheckWord(true);
			} else {
				setCheckWord(false);
				setAudio("");
			}
		}
	}, [wordIndex, actualSegment, quiz]);

	useEffect(() => {
		if (chart !== null && !seeResults) {
			let charts = chart;

			charts.data.datasets[0].data[0] = incorrectWords;
			charts.data.datasets[0].data[1] = correctWords;
			charts.data.datasets[0].data[2] = totalWords - correctWords - incorrectWords;

			charts.options = {
				onClick: function (e, activeEls) {
					const index = activeEls[0].index;

					if (index === 1) {
						showSeeWords(quiz!.correctWords, "correctos");
					} else if (index === 0) {
						showSeeWords(quiz!.incorrectWords, "incorrectos");
					}
				},
			};

			charts.update();
			setChart(charts);
		}
	}, [incorrectWords, correctWords]);

	useEffect(() => {
		if (!loading && chart === null && !seeResults && !middleScreen && !endLoading) {
			newChart();
		}
	}, [loading, middleScreen]);

	useEffect(() => {
		setStartTime(new Date());
		setAudio("");
		if (actualSegmentQuiz !== undefined && !quiz!.finished) {
			isMarked(actualSegmentQuiz![wordIndex].word_id);

			getNoteType(actualSegmentQuiz![wordIndex]);
		}
	}, [wordIndex]);

	useEffect(() => {
		setLoadingMiddleScreen(true);
		getQuiz();
		setSeeWords({ ...seeWords, show: false }); //Esconder modal WordsModal al editar una palabra.
	}, [testId, actualSegment, countRefresh]);

	useEffect(() => {
		if (endLoading) {
			setMiddleScreen(false);
			endQuiz();
		}
	}, [endLoading]);

	useEffect(() => {
		if (quiz !== undefined && quiz!.answerType !== "normal") {
			const element = document.getElementById("answer");
			const element2 = document.getElementById("incorrect-input-text");
			const keyUpHandler = (e) => {
				setInsertIndexSpecialChar(parseInt((e.target! as any).selectionStart));
			};
			const clickHandler = (e) => {
				setInsertIndexSpecialChar(parseInt((e.target! as any).selectionStart));
			};
			if (element !== null) {
				element.addEventListener("keyup", keyUpHandler);
				element.addEventListener("click", clickHandler);
			}
			if (element2 !== null) {
				element2.addEventListener("keyup", keyUpHandler);
				element2.addEventListener("click", clickHandler);
			}
			// Cleanup function
			return () => {
				if (element !== null) {
					element.removeEventListener("keyup", keyUpHandler);
					element.removeEventListener("click", clickHandler);
				}
				if (element2 !== null) {
					element2.removeEventListener("keyup", keyUpHandler);
					element2.removeEventListener("click", clickHandler);
				}
			};
		}
	}, [checkWord, loading, middleScreen]);

	useEffect(() => {
		addDateStreak();
	}, []);

	return (
		<div className="test-main-container">
			{quiz !== undefined && (
				<EditQuizModal
					show={editQuizModal}
					onHide={() => setEditQuizModal(false)}
					quiz={quiz}
					getQuiz={getQuiz}
				/>
			)}
			<div
				className={"close-modals " + (seeScore || seeProgress ? "open-close-modal" : "")}
				onClick={closeModals}
			></div>
			<div className="top-container">
				<button className="goback-btn" onClick={() => setView(1)}>
					Atrás
				</button>
				<div className="logo-container">
					<img src={LogoBig} alt="logo academia de lenguas internacionales" />
				</div>
				<div className="obj"></div>
			</div>

			<div className="header-container">
				<div className="image-container">
					<img src={getImage(parseInt(num))} alt="profile-seminary image" />
				</div>
				<div className="title-container">
					<h2 style={{ color: "#ca5050" }}>
						Seminario <span style={{ fontFamily: "initial" }}>{romanize(num)}</span>
					</h2>
					{quiz?.default !== true && (
						<img
							src={GearIcon}
							alt="icono de engrane"
							role={"button"}
							onClick={() => setEditQuizModal(true)}
						/>
					)}
				</div>
			</div>

			{loading ? (
				<div className="loader-container">
					<div className="creating-test-row">
						<h3>Estamos creando tu ronda de estudio... ¡Prepárate!</h3>
					</div>
					<div className="rocket-row">
						<img src={RocketIcon} alt="nave espacial" />
					</div>
					<div className="progress-bar-row">
						<div className="spinner-border text-danger" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
					<div className="pro-tip-row">
						<h2>Pro tip:</h2>
						<h3>{parse(proTips[Math.floor(Math.random() * proTips.length)])}</h3>
					</div>
				</div>
			) : (
				quizLangText !== undefined && (
					<>
						<div className="quiz-name-container">
							<h2>{quiz!.name}</h2>
						</div>
						<div className="test-container">
							<div className="progress-score-btn-container">
								<button onClick={() => setSeeScore(!seeScore)}>Puntaje</button>

								{quiz?.default !== true && (
									<img
										src={GearIcon}
										alt="icono de engrane"
										role={"button"}
										onClick={() => setEditQuizModal(true)}
										style={{ width: "30px", height: "30px" }}
									/>
								)}

								<button onClick={() => setSeeProgress(!seeProgress)}>Progreso</button>
							</div>
							<div
								className={
									"test-progress-container " + (seeProgress ? "test-progress-container-open" : "")
								}
							>
								<div className="close-card" onClick={() => setSeeProgress(!seeProgress)}>
									<img src={ArrowRed} alt="flecha" />
								</div>
								{!seeResults && (
									<div className="test-progress-card">
										<div className="title-container">
											<div className="title">
												<h2
													className="quiz-name"
													style={{ fontSize: "1.25rem", textAlign: "center" }}
												>
													{quiz!.name}
												</h2>
												<div className="title-2">
													{quiz!.language !== "chinese" && quiz!.language !== "japanese" && (
														<Form className="translate-input-container mt-2">
															<Form.Check
																id="custom-switch-lang"
																checked={translate}
																onChange={(e) => setTranslate(e.target.checked)}
																label={returnFlagByLanguage("spanish")}
															/>
															<Form.Check
																type="switch"
																id="custom-switch-lang"
																checked={translate}
																onChange={(e) => setTranslate(e.target.checked)}
																label={returnFlagByLanguage(quiz!.language)}
															/>
														</Form>
													)}
												</div>
											</div>
											<div className="test-progress-info-container">
												<div style={{ display: "flex" }}>
													<h2>
														{incorrectWords + correctWords}/{totalWords}
													</h2>
												</div>

												<div className="segments-container justify-content-end">
													{getNumberOfSegments(quiz!.quizWords).map((segment, index) => {
														return (
															<div
																className={"segment " + (actualSegment >= index ? "active" : 0)}
																key={"segment-" + index}
															>
																<p>{index + 1}</p>
															</div>
														);
													})}
												</div>
											</div>
										</div>
										<div className="test-progress-bar-container">
											<TestProgressBar
												words={actualSegmentQuiz}
												setWordIndex={setWordIndex}
												index={wordIndex}
											/>
										</div>
									</div>
								)}
							</div>
							{endLoading ? (
								<LoaderTest />
							) : !seeResults &&
							  actualSegmentQuiz !== undefined &&
							  actualSegmentQuiz.length > 0 &&
							  !middleScreen ? (
								<>
									<WordsModal
										show={seeWords.show}
										quiz={quiz!}
										onHide={() => setSeeWords({ ...seeWords, show: false })}
										seeWords={seeWords}
										setCountRefresh={setCountRefresh}
										countRefresh={countRefresh}
									/>
									<div className="test-info-container">
										<div
											className={
												"test-score-card-container" + (seeScore ? " test-score-card-open" : "")
											}
										>
											<div className="close-icon-container" onClick={() => setSeeScore(!seeScore)}>
												<img src={CloseIcon} alt="close icon" />
											</div>
											<div className="test-score-card">
												<div className="title">
													<h3>Puntaje de la ronda de estudio</h3>
												</div>
												<div className="good-answers-row">
													<h3 onClick={() => showSeeWords(quiz!.correctWords, "correctos")}>
														Términos correctos
													</h3>
													<div className="words-progress-bar-container">
														<div className="words-progress-bar">
															<div
																className="words-progress"
																style={{
																	width: (correctWords / totalWords) * 100 + "%",
																}}
															></div>
														</div>
														<p>{correctWords}</p>
													</div>
												</div>
												<div className="incorrect-answers-row">
													<h3 onClick={() => showSeeWords(quiz!.incorrectWords, "incorrectos")}>
														Términos incorrectos
													</h3>
													<div className="words-progress-bar-container">
														<div className="words-progress-bar">
															<div
																className="words-progress"
																style={{
																	width: (incorrectWords / totalWords) * 100 + "%",
																}}
															></div>
														</div>
														<p>{incorrectWords}</p>
													</div>
												</div>
												<div className="donut-container">
													<h3>Progreso de la ronda</h3>
													<div className="chart-container">
														<h3 className="percentage">
															{(((correctWords + incorrectWords) / totalWords) * 100).toFixed(0)}%
														</h3>
														<canvas id="myChart" width="100%" height="auto"></canvas>
													</div>
												</div>
											</div>
										</div>
										<div className="test-words-container">
											<NoteMark
												show={bookmarkModal}
												isMarked={isMarked}
												wordId={actualSegmentQuiz![wordIndex].word_id}
												onHide={() => setBookmarkModal(false)}
											/>
											{/* <h4
												className="m-0 text-center"
												style={{
													position: "absolute",
													top: 5,
													right: 10,
													fontSize: "1rem",
												}}
											>
												#repetida{" "}
												{actualSegmentQuiz![wordIndex].timesSeen !== undefined ? actualSegmentQuiz![wordIndex].timesSeen : 1}
											</h4> */}
											{user.userType === 2 && (
												<div
													className="d-flex flex-column align-items-center justify-content-center"
													style={{ width: "5%" }}
												>
													{user.userType === 2 && !marked && (
														<img
															src={BookmarkIcon}
															alt="bookmark"
															onClick={() => setBookmarkModal(true)}
															style={{ cursor: "pointer" }}
														/>
													)}
													<img
														className="wrench-word mt-3"
														src={WrenchIcon}
														alt="icono de llave inglesa"
														onClick={() =>
															setEditWordModal({ show: true, word: actualSegmentQuiz![wordIndex] })
														}
													/>
												</div>
											)}

											{user.userType === 2 && (
												<EditWordModal
													show={editWordModal.show}
													onHide={() => setEditWordModal({ show: false, word: {} })}
													word={editWordModal.word}
													language={quiz!.language}
													getQuiz={getQuiz}
												/>
											)}
											{!checkWord ? (
												<>
													{!loadingGrade ? (
														<div style={{ marginTop: "auto" }}>
															<TestWordContainer
																actualSegmentQuiz={actualSegmentQuiz[wordIndex]}
																listenWord={listenWord}
																seeInfo={seeInfo}
																setSeeInfo={setSeeInfo}
																dictionarySubColor={dictionarySubColor}
																quiz={quiz!}
															/>
														</div>
													) : (
														<div className="w-100 h-100 d-flex justify-content-center align-items-center">
															<div className="spinner-border text-danger" role="status">
																<span className="sr-only">Loading...</span>
															</div>
														</div>
													)}
												</>
											) : (
												<>
													<div className="result-title">
														<h2
															className={
																qualify?.correct ||
																quiz!.correctWords.findIndex(
																	(e) => e.id === actualSegmentQuiz![wordIndex].id
																) !== -1
																	? "correct"
																	: "incorrect"
															}
														>
															{qualify?.correct ||
															quiz!.correctWords.findIndex(
																(e) => e.id === actualSegmentQuiz![wordIndex].id
															) !== -1
																? quizLangText.correcto
																: quizLangText.incorrecto}
														</h2>
														<p className="terms-check d-flex align-items-center justify-content-center">
															<span>{quizLangText.termino}&ensp;</span>
															<TestWordContainer
																actualSegmentQuiz={actualSegmentQuiz[wordIndex]}
																listenWord={listenWord}
																seeInfo={seeInfo}
																setSeeInfo={setSeeInfo}
																dictionarySubColor={dictionarySubColor}
																quiz={quiz!}
															/>
														</p>
													</div>
													<ProgressAnimation dominated={dominated} lastDominated={lastDominated} />
													<div className="info-word-container">
														{quiz!.incorrectWords.findIndex(
															(e) => e.id === actualSegmentQuiz![wordIndex].id
														) !== -1 && (
															<button
																className="action-btn try-again-btn"
																onClick={() => tryAgain(actualSegmentQuiz![wordIndex])}
															>
																Probar de nuevo
															</button>
														)}
														<p className="correct-chars">
															<span className="default-text">{quizLangText.tuRespuesta} </span>
															<b className="colors-word">{parse(colorWords.answer)}</b>
														</p>
														<p
															className="correct-chars"
															style={{ cursor: "pointer" }}
															onClick={() =>
																listenWord(
																	quiz!.answerType === "selected-lang" ||
																		quiz!.answerType === "kanji"
																		? "normal"
																		: "selected-lang"
																)
															}
														>
															<span className="default-text">
																{quizLangText.respuestaCorrecta}{" "}
															</span>
															<b className="colors-word">{parse(colorWords.correct)}</b>
														</p>
														<p className="default-text-main">
															<span className="default-text">Categoría: </span>
															<b className="category-text">
																{qualify?.category.map((cat, index) => {
																	if (cat !== null) return cat + " ";
																})}{" "}
															</b>
														</p>
														{qualify!.subCategory.length > 0 && (
															<p className="default-text-main">
																<span className="default-text">Sub categoría: </span>
																<b>
																	{qualify?.subCategory.map((cat, index1) => {
																		if (cat !== null)
																			return (
																				<>
																					{cat.includes(".") ? (
																						cat
																							.replaceAll(".", ";.;")
																							.split(";")
																							.map((char, index) => {
																								if (char === "mf" || char === "fm") {
																									return (
																										<>
																											<span
																												className="sub-cat"
																												style={{
																													color: dictionarySubColor[char[0]],
																												}}
																											>
																												{char[0]}
																											</span>
																											<span
																												style={{
																													color: dictionarySubColor[char[1]],
																												}}
																											>
																												{char[1] +
																													(qualify.subCategory.length > 1 &&
																													index1 <=
																														qualify.subCategory.length - 2 &&
																													index ===
																														cat.replaceAll(".", ";.;").split(";")
																															.length -
																															1
																														? ", "
																														: "")}
																											</span>
																										</>
																									);
																								} else {
																									return (
																										<span
																											className="sub-cat"
																											key={"char-color-sub-category-" + index}
																											style={{
																												color: dictionarySubColor[char]
																													? dictionarySubColor[char]
																													: "#000000",
																											}}
																										>
																											{char +
																												(qualify.subCategory.length > 1 &&
																												index1 <= qualify.subCategory.length - 2 &&
																												index ===
																													cat.replaceAll(".", ";.;").split(";")
																														.length -
																														1
																													? ", "
																													: "")}
																										</span>
																									);
																								}
																							})
																					) : (
																						<span
																							className="sub-cat"
																							style={{
																								color: dictionarySubColor[cat]
																									? dictionarySubColor[cat]
																									: "#000000",
																							}}
																						>
																							{cat +
																								(qualify.subCategory.length > 1 &&
																								index1 <= qualify.subCategory.length - 2
																									? ", "
																									: "")}
																						</span>
																					)}
																				</>
																			);
																	})}
																</b>
															</p>
														)}
														{actualSegmentQuiz[wordIndex].timesSeen >= 4 &&
															quiz!.incorrectWords.findIndex((e) => e.id === qualify?.word.id) !==
																-1 && (
																<div className="d-flex justify-content-center flex-wrap">
																	<p className="text-dark">
																		Se excluirá este término de la ronda de estudio. No afectará tu
																		progreso
																	</p>
																	<button className="action-btn linear-bg mt-2" onClick={noMore}>
																		Saltar definitivamente
																	</button>
																</div>
															)}

														<WarningTypeSelector
															setWarningType={setWarningType}
															word={actualSegmentQuiz[wordIndex]}
															warningType={warningType}
														/>
														{actualSegmentQuiz[wordIndex][warningType] !== "undefined" &&
															actualSegmentQuiz[wordIndex][warningType] !== undefined &&
															actualSegmentQuiz[wordIndex][warningType].length > 0 &&
															actualSegmentQuiz[wordIndex][warningType] !==
																'<p class="pre"><br></p>' &&
															actualSegmentQuiz[wordIndex][warningType] !==
																'<p class="pre"><br></p><p class="pre"><br></p>' && (
																<div
																	className={"warning-container"}
																	style={{ borderColor: returnWarningBorderColor(warningType) }}
																>
																	<h3 style={{ color: returnWarningBorderColor(warningType) }}>
																		{returnWarningTypeTitle(warningType)}
																	</h3>
																	{/* <ReactQuill readOnly theme="bubble" value={actualSegmentQuiz[wordIndex][warningType]} modules={modules} /> */}
																	<div className="ql-editor">
																		{parse(actualSegmentQuiz[wordIndex][warningType], {
																			trim: false,
																		})}
																	</div>
																	{returnWarningTypeImage(warningType)}
																</div>
															)}
													</div>
												</>
											)}
											<div className="test-info-container">
												{!loadingGrade && (
													<>
														{!checkWord ? (
															<>
																<div className="word-audio-container">
																	<div onClick={handleClickEventAudio} style={{ height: 40 }}>
																		{!loadAudio ? (
																			<div
																				className={
																					"listen-to-word-container " + (autoPlay ? "green" : "red")
																				}
																			>
																				<img
																					className="speaker-image"
																					src={VolumeIconWhite}
																					alt="icono de volumen"
																				/>
																				<img
																					className="arrow-image"
																					src={RepeatArrow}
																					alt="icono de de flecha en forma de circulo"
																				/>
																			</div>
																		) : (
																			<div className="text-center" style={{ width: "100%" }}>
																				<div
																					className="spinner-border text-dark text-center"
																					role="status"
																				>
																					<span className="sr-only">Loading...</span>
																				</div>
																			</div>
																		)}
																	</div>
																	<div>
																		<button
																			onClick={() => setSlow(!slow)}
																			className={"slow-audio-btn " + (!slow ? " normal" : "")}
																		>
																			{slow ? "0.75x" : "Normal"}
																		</button>
																	</div>
																</div>
															</>
														) : (
															<>
																<div
																	className="comment-button"
																	onClick={() =>
																		setCommentModal({
																			show: true,
																			word_data_id: actualSegmentQuiz![wordIndex].id,
																			seminary: num,
																			vocabularyUserId: user.vocabularyUserId,
																			user_id: user.id,
																			user_name: user.name,
																			user_color: user.color,
																			word: actualSegmentQuiz[wordIndex].native_word,
																		})
																	}
																>
																	!
																</div>
																<CommentModal
																	show={commentModal.show}
																	word_data_id={commentModal.word_data_id}
																	seminary={commentModal.seminary}
																	vocabularyUserId={commentModal.vocabularyUserId}
																	user_id={commentModal.user_id}
																	user_name={commentModal.user_name}
																	user_color={commentModal.user_color}
																	word={actualSegmentQuiz[wordIndex].native_word}
																	onHide={() =>
																		setCommentModal({
																			show: false,
																			word_data_id: {},
																			seminary: {},
																			vocabularyUserId: {},
																			user_id: {},
																			user_name: {},
																			user_color: {},
																			word: {},
																		})
																	}
																/>
																{quiz!.incorrectWords.findIndex(
																	(e) => e.id === qualify?.word.id
																) !== -1 && (
																	<div
																		className="is-right-container-btn d-flex flex-column align-items-center"
																		style={{ gap: 10 }}
																	>
																		<button
																			id="isRight-btn"
																			onClick={() => isRight(actualSegmentQuiz[wordIndex])}
																			className="btn-acerte"
																		>
																			{quizLangText.buttons.acerte}
																		</button>
																		<div className="hot-key">
																			<span>1</span>
																		</div>
																	</div>
																)}
															</>
														)}
													</>
												)}
												<div className="word-lang-container-text">
													{quiz?.language === "chinese" && checkWord ? (
														<span
															className="default-text"
															style={
																!document.getElementById("isRight-btn") &&
																actualSegmentQuiz[wordIndex]?.optional_word?.length! < 2
																	? { display: "flex", width: "160px", justifyContent: "flex-end" }
																	: !document.getElementById("isRight-btn") &&
																	  actualSegmentQuiz[wordIndex]?.optional_word?.length! >= 2 &&
																	  actualSegmentQuiz[wordIndex]?.optional_word?.length! <= 5
																	? { display: "flex", width: "180px", justifyContent: "flex-end" }
																	: {}
															}
														>
															{actualSegmentQuiz[wordIndex].optional_word}{" "}
														</span>
													) : (
														""
													)}
												</div>
												<div className="word-lang-container ml-auto">
													{quiz?.language === "chinese" && checkWord
														? actualSegmentQuiz[wordIndex].optional_word!.length >= 5
															? ""
															: returnFlag(quiz!.language)
														: returnFlag(quiz!.language)}
													{quiz?.language === "chinese" && checkWord ? (
														actualSegmentQuiz[wordIndex]?.optional_word!.length >= 5 ? (
															""
														) : quiz!.answerType !== "normal" ? (
															<h3>Idioma de la respuesta</h3>
														) : (
															<h3>Idioma del término</h3>
														)
													) : quiz!.answerType !== "normal" ? (
														<h3>Idioma de la respuesta</h3>
													) : (
														<h3>Idioma del término</h3>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="test-footer">
										{!checkWord ? (
											<>
												{!loadingGrade ? (
													<>
														{quiz!.answerType !== "normal" && (
															<div
																className={
																	"test-special-chars-card " +
																	(specialKeyboard.active ? "test-special-chars-card-active" : "")
																}
															>
																<div
																	className={"card-char special-keyboard "}
																	onClick={() =>
																		setSpecialKeyboard({
																			...specialKeyboard,
																			active: !specialKeyboard.active,
																		})
																	}
																>
																	<h2>Teclado especial</h2>
																</div>
																<div
																	className={
																		"card-char upper-case " +
																		(specialKeyboard.toUpper ? "active" : "")
																	}
																	onClick={() =>
																		setSpecialKeyboard({
																			...specialKeyboard,
																			toUpper: !specialKeyboard.toUpper,
																		})
																	}
																>
																	<img src={NextArrow} alt="flecha apuntando hacia la izq" />
																</div>
																{specialCharacters[quiz!.language].map((char, index) => {
																	return (
																		<div
																			className="card-char"
																			key={"char-" + index}
																			onClick={() =>
																				handleSpecialKeyboard(
																					specialKeyboard.toUpper
																						? char.toUpperCase()
																						: char.toLowerCase(),
																					"answer"
																				)
																			}
																		>
																			<h2>
																				{specialKeyboard.toUpper
																					? char.toUpperCase()
																					: char.toLowerCase()}
																			</h2>
																		</div>
																	);
																})}
															</div>
														)}
														<div className="input-container">
															<h3>
																Ingresa la respuesta en{" "}
																{getLangSpanish(quiz!.language, quiz!.answerType)?.toLowerCase()}
															</h3>
															<div className="input-btn">
																<div className="input">
																	{answerText.length === 0 && (
																		<div className="term-input-information-container">
																			<TestWordContainer
																				actualSegmentQuiz={actualSegmentQuiz[wordIndex]}
																				listenWord={listenWord}
																				seeInfo={seeInfo}
																				setSeeInfo={setSeeInfo}
																				dictionarySubColor={dictionarySubColor}
																				quiz={quiz!}
																			/>
																		</div>
																	)}
																	<input
																		type="text"
																		autoComplete="off"
																		autoFocus
																		value={answerText}
																		name="answer"
																		id="answer"
																		onChange={(e) => setAnswerText(e.target.value)}
																	/>
																</div>
																<button id="qualify-word-btn" onClick={gradeAnswer}>
																	{answerText.length === 0
																		? quizLangText!.buttons.nolose
																		: quizLangText.buttons.revisar}
																</button>
															</div>
														</div>
													</>
												) : (
													<div className="d-flex justify-content-center w-100">
														<div className="spinner-border text-danger" role="status">
															<span className="sr-only">Loading...</span>
														</div>
													</div>
												)}
											</>
										) : (
											<>
												{/* <button className="prev-btn">Término anterior</button> */}
												{quiz!.incorrectWords.findIndex((e) => e.id === qualify?.word.id) !== -1 ? (
													<div
														className="d-flex w-100 align-items-flex-end incorrects-responsive"
														style={{ gap: 20 }}
													>
														{quiz!.answerType !== "normal" && (
															<div
																className={
																	"test-special-chars-card mt-auto " +
																	(specialKeyboard.active ? "test-special-chars-card-active" : "")
																}
															>
																<div
																	className={"card-char special-keyboard "}
																	onClick={() =>
																		setSpecialKeyboard({
																			...specialKeyboard,
																			active: !specialKeyboard.active,
																		})
																	}
																>
																	<h2>Teclado especial</h2>
																</div>
																<div
																	className={
																		"card-char upper-case " +
																		(specialKeyboard.toUpper ? "active" : "")
																	}
																	onClick={() =>
																		setSpecialKeyboard({
																			...specialKeyboard,
																			toUpper: !specialKeyboard.toUpper,
																		})
																	}
																>
																	<img src={NextArrow} alt="flecha apuntando hacia la izq" />
																</div>
																{specialCharacters[quiz!.language].map((char, index) => {
																	return (
																		<div
																			className="card-char"
																			key={"char-" + index}
																			onClick={() =>
																				handleSpecialKeyboard(
																					specialKeyboard.toUpper
																						? char.toUpperCase()
																						: char.toLowerCase(),
																					"incorrect-input-text"
																				)
																			}
																		>
																			<h2>
																				{specialKeyboard.toUpper
																					? char.toUpperCase()
																					: char.toLowerCase()}
																			</h2>
																			.
																		</div>
																	);
																})}
															</div>
														)}
														<div className="input-container justify-content-start">
															<h3 className="mb-2">
																Ingresa la respuesta en{" "}
																{getLangSpanish(quiz!.language, quiz!.answerType)?.toLowerCase()}
															</h3>
															<div className="input-btn align-items-end">
																<div className="input">
																	<h2 className={"alert-text " + (isWrong ? "is-wrong" : "")}>
																		{quizLangText.escribeTerminoCorrectamente}
																	</h2>
																	{isRightText.length === 0 && (
																		<div className="term-input-information-container">
																			<TestWordContainer
																				actualSegmentQuiz={actualSegmentQuiz[wordIndex]}
																				listenWord={listenWord}
																				seeInfo={seeInfo}
																				setSeeInfo={setSeeInfo}
																				dictionarySubColor={dictionarySubColor}
																				quiz={quiz!}
																				answerType={
																					quiz!.answerType === "kanji" ||
																					quiz!.answerType === "selected-lang"
																						? "normal"
																						: "selected-lang"
																				}
																			/>
																		</div>
																	)}
																	<input
																		autoFocus
																		id={"incorrect-input-text"}
																		type="text"
																		value={isRightText}
																		onChange={(e) => setIsRightText(e.target.value)}
																	/>
																</div>
																<div className="d-flex flex-column">
																	{isRightText.length === 0 ? (
																		<button
																			id="pass-word-btn"
																			className="next-btn"
																			onClick={() => {
																				nextAnswer();
																			}}
																			type="button"
																		>
																			{quizLangText.buttons.saltar}
																			<div className="hot-key">
																				<span>2</span>
																			</div>
																		</button>
																	) : (
																		<button id="qualify-word-btn" onClick={isRightTextCorrect}>
																			{quizLangText.buttons.revisar}
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>
												) : (
													<div className="options-btns">
														<button
															id="next-word-btn"
															className="next-btn"
															onClick={() => {
																nextAnswer();
															}}
															type="button"
														>
															Siguiente {wordIndex < actualSegmentQuiz.length - 1 ? "término" : ""}
														</button>
													</div>
												)}
											</>
										)}
									</div>
								</>
							) : (
								<>
									{middleScreen ? (
										<>
											<div className="middle-screen-container">
												{loadingMiddleScreen ? (
													<LoaderTest />
												) : (
													<>
														<MiddleScreen
															answerType={quiz!.answerType}
															actualSegmentQuiz={actualSegmentQuiz}
															headerText={quizLangText.middleScreen}
															terminosBloque={quizLangText.terminosBloque}
															actualSegment={actualSegment}
															language={quiz!.language}
															correctsAnswers={quiz!.correctWords}
															totalWords={totalWords}
															listenWord={listenWord}
															setCountRefresh={setCountRefresh}
															countRefresh={countRefresh}
															setMiddleScreenEditWordModal={setMiddleScreenEditWordModal}
														/>
														<button
															className="action-btn linear-bg"
															id="middle-screen-continue"
															onClick={() => {
																setMiddleScreen(false);
																if (autoPlay) {
																	setTimeout(() => {
																		listenWord(quiz!.answerType, wordIndex);
																	}, 100);
																}
															}}
														>
															{quizLangText.buttons.continuar}
														</button>
													</>
												)}
											</div>
										</>
									) : (
										<QuizResults
											totalWords={totalWords}
											realTotalWords={realTotalWords}
											incorrectWords={incorrectWords}
											correctWords={correctWords}
											errorsCounter={quiz!.errorsCounter}
											time={quiz!.timeToAnswer}
											repeats={repeats}
											languageWords={allWordsUser[quiz!.language]}
											language={quiz!.language}
											repeatI={repeatI}
											setView={setView}
											quizLangText={quizLangText}
											quizWords={quiz!.quizWords}
											quizIncorrects={quiz!.incorrectWords}
											quizCorrects={quiz!.correctWords}
											quizErrors={quiz!.errorsWords}
											dominatedWords={quiz!.dominatedWords}
											quiz={quiz!}
											setCountRefresh={setCountRefresh}
											countRefresh={countRefresh}
											subCatColores={dictionarySubColor}
										/>
									)}
								</>
							)}
							{audio.length > 0 && !loadAudio && (
								<audio
									style={{ position: "absolute", zIndex: -100, opacity: 0 }}
									id="listen"
									controls
								>
									<source src={"data:audio/mp3;base64," + audio} />
								</audio>
							)}
						</div>
					</>
				)
			)}
		</div>
	);
}

export default Test;
