import { db } from "initFirebase";
import { SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";

import {
	getNumsAndDatesByGenId,
	Seminary,
	getGens,
	Gens,
	getGenerationNameById,
} from "services/GenerationService";
import PhoneInput from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import {
	CheckmarkIcon,
	StreakFlameIcon,
	Students,
	SurveyIcon1,
	SurveyIcon2,
	TrashIcon,
} from "assets/Imports";
import "./UserProfileModal.scss";
import { StreakModal } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/StreakModal";
import FormModal from "@components/ModalFormUser/FormModal";
import FormModal2 from "@components/ModalFormUser/FormModal2";
import Tooltip from "functions/Tooltip/Tooltip";
import { useStreak } from "@hooks/useStreak";
import { Spinner20vh, SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { DayInfoModal } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/components/DayInfoModal";
import { dayInfoModalInterface } from "interfaces/StreakCalendar";
import { StreakFlame } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/components/StreakFlame";
import moment, { isMoment } from "moment";
import useCollaboratorLogs from "@hooks/collaborators/useCollaboratorLogs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { isGenInPerson, isGenOnline } from "interfaces/Generations";

export function UserProfileModal(props) {
	// make sure to receive a prop if you want to update the parent component

	const [userProfile, setUserProfile] = useState<any>(null); // All the information on the user
	const [gen, setGen] = useState<any>(null); // All the generation information
	const [materialInfo, setMaterialInfo] = useState<Array<Seminary> | null>(null); // Generation seminaries info (date, num, id)
	const [paymentInfo, setPaymentInfo] = useState<Array<Payments | null>>([null]); // modules paid info (to pay, paid, or still not passed)
	const [paymentHistory, setPaymentHistory] = useState<Array<PaymentsHistory>>([]); // history of payments (date, amount, concept, image link)
	const [totalPaid, setTotalPaid] = useState<string>(""); // total amount paid by the user
	const [gens, setGens] = useState<Array<Gens | null>>([null]); // Array containing id and name info from all generations, used to modify the user´s generation
	const [genSelect, setGenSelect] = useState<string>(); // The generation currently selected for the user
	const [loader, setLoader] = useState<boolean>(false);
	const [matricula, setMatricula] = useState<string>();
	const [streakModal, setStreakModal] = useState<boolean>(false);
	const [formModal, setFormModal] = useState<boolean>(false);
	const [formModal2, setFormModal2] = useState<boolean>(false);
	const [newDate, setNewDate] = useState<any>();
	const { setVocabularyStreak } = useStreak();
	const [oldGen, setOldGen] = useState(""); // The original generation of the user
	const { genLog } = useCollaboratorLogs();
	const [noUserAccount, setnoUserAccount] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const { setUserId, vocabularyStreak, isLoading, studiedDays } = useStreak();

	const handleMouseEnter = () => setShowTooltip(true);
	const handleMouseLeave = () => setShowTooltip(false);

	interface Payments {
		name: string;
		status: string;
	}

	interface PaymentsHistory {
		date: string;
		amount: number | string;
		image: string;
		concept: string;
		dateMilliseconds: number;
		id: string;
		acceptedByName: string;
		acceptedById: string;
		acceptDate: string;
		type: string;
	}

	const getAllGensInfo = async () => {
		getGens().then((res) => {
			setGens(res);
		});
	};

	const getUserInfo = async (id: string) => {
		// Get the user doc for the id passed to the component
		const q = doc(db, "users", id);
		const qSnap = await getDoc(q);
		if (qSnap.exists()) {
			const data: any = {
				id: qSnap.id,
				...qSnap.data(),
			};
			setUserProfile(data);
			setMatricula(data.matricula);
			await getGenInfo(data.generationId);
		} else {
			console.error("Ocurrió un error o el usuario no existe en la base de datos");
			// Si el usuario no existe, puedes establecer el perfil con props.user si está disponible
			if (props.user) {
				setUserProfile(props.user);
			}
		}
	};

	const typesPayments = {
		verify_conekta: "pago con tarjeta, verificado por conekta",
		verify_stripe: "pago con tarjeta, verificado por stripe",
		request_by_admin: "solicitado por administrador",
		request_by_user: "solicitado por usuario",
		web_hook: "verificado por webhook Conekta oxxo y spei",
	};
	function getPaymentType(paymentType, acceptedByName, acceptDate) {
		switch (paymentType) {
			case typesPayments.request_by_admin:
			case typesPayments.request_by_user:
				return acceptedByName !== undefined
					? acceptDate !== undefined
						? `Pago registrado por: ${acceptedByName} el ${acceptDate}`
						: `Pago registrado por: ${acceptedByName}. Fecha de aceptación no encontrada.`
					: acceptDate !== undefined
					? `Datos de quien aceptó el pago no encontrados. Fecha de aceptación: ${acceptDate}`
					: "Ningún dato encontrado.";
			default:
				return `${paymentType}  (Automatizado).`;
		}
	}

	const checkPaymentInfo = async (id: string) => {
		// In this function we get the payment information by userId, and process whether he/she is behind on payments or not
		const q = query(
			collection(db, "paymentHistory"),
			where("userId", "==", id),
			where("verified", "==", true)
		); // Retreive all verified payments for the current user
		const querySnapshot = await getDocs(q);
		let tempTotalPaid: number = 0;
		let paidModules: number = 0;
		let tempPaymentsHistory: PaymentsHistory[] = [];
		if (querySnapshot.docs) {
			querySnapshot.forEach((element: any) => {
				if (typeof element.data().date === "string") {
					let NewDateS = moment(element.data().date, "DD-MM-YYYY").valueOf();
					let date1: Date = new Date(NewDateS);
					let DateString1: string =
						date1.getDate() + "/" + (date1.getMonth() + 1) + "/" + date1.getFullYear();
					setNewDate(DateString1);
				}
				let date: Date = new Date(element.data().date); // create date object from milliseconds
				let dateString: string =
					date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(); // Create date string mexican format
				let tempAmount: number = parseInt(element.data().amount);
				tempPaymentsHistory.push({
					date: dateString,
					amount: tempAmount.toLocaleString("en-US", { style: "currency", currency: "USD" }),
					image: element.data().image,
					concept: element.data().concept,
					dateMilliseconds: element.data().date,
					id: element.id,
					acceptedByName: element.data().acceptedByName,
					acceptedById: element.data().acceptedById,
					acceptDate: element.data().acceptDate,
					type: element.data().type,
				});
				tempTotalPaid += parseInt(element.data().amount);
			});
			tempPaymentsHistory.sort((a, b) => {
				// sort payments by date from most recent to least recent
				const nameA = a.dateMilliseconds;
				const nameB = b.dateMilliseconds;
				if (nameA < nameB) {
					return 1;
				}
				if (nameA > nameB) {
					return -1;
				}
				return 0;
			});

			setPaymentHistory(tempPaymentsHistory);
			setTotalPaid(tempTotalPaid.toLocaleString("en-US", { style: "currency", currency: "USD" })); // format amount to currency style
		}
		if (
			(querySnapshot.size === 1 && tempTotalPaid === 12000 && isGenOnline(gen.type)) || // Check for full 1 instance payments, or special promotion
			(querySnapshot.size === 1 && tempTotalPaid === 15000 && isGenInPerson(gen.type)) ||
			userProfile.promotion === true
		) {
			paidModules = 5;
		} else {
			// here we check for the total modules paid
			if (tempTotalPaid >= 3000 && tempTotalPaid < 7500) {
				paidModules = 1;
			} else if (tempTotalPaid >= 7500 && tempTotalPaid < 12000) {
				paidModules = 2;
			} else if (tempTotalPaid >= 12000 && tempTotalPaid < 15000) {
				paidModules = 3;
			} else if (tempTotalPaid >= 15000 && tempTotalPaid < 18000) {
				paidModules = 4;
			} else if (tempTotalPaid == 18000) {
				paidModules = 5;
			}
		}
		let today: Date = new Date();
		let seminariesPassed: number = 0;
		let stopper: boolean = false;
		let modulesToPay: number = 0;

		materialInfo?.forEach((e) => {
			// Check how many seminaries have passed
			if (stopper === false) {
				let dateSplit: Array<string> = e.date.split("/");
				let tempDate: string = parseInt(dateSplit[1]) + 1 + "/" + dateSplit[0] + "/" + dateSplit[2];
				let date = new Date(tempDate);
				if (date < today) {
					seminariesPassed += 1;
				} else {
					stopper = true;
				}
			}
		});
		if (seminariesPassed <= 10) {
			// Figure out how many modules should have been paid to this day
			modulesToPay = 1;
		} else if (seminariesPassed > 10 && seminariesPassed <= 25) {
			modulesToPay = 2;
		} else if (seminariesPassed > 25 && seminariesPassed <= 40) {
			modulesToPay = 3;
		} else if (seminariesPassed > 40 && seminariesPassed <= 50) {
			modulesToPay = 4;
		} else if (seminariesPassed > 50 && seminariesPassed <= 60) {
			modulesToPay = 5;
		}
		let tempPaymentInfo: Payments[] = []; // Fill the paymentInfo state that dictates whether a module is paid or not, or wheter it should not be paid yet
		for (let i = 0; i < 5; i++) {
			let tempStatus: string =
				modulesToPay <= i ? "Por pagar" : paidModules > i ? "Pagado" : "Retrasado";
			if (paidModules === 5) {
				tempStatus = "Pagado";
			}
			tempPaymentInfo.push({ name: "Módulo " + (i + 1), status: tempStatus });
		}

		setPaymentInfo(tempPaymentInfo);
	};

	const getGenInfo = async (id: string) => {
		// Retrieve the generation information to which the current user belongs
		if (id?.length < 2 || id === undefined) {
			setGenSelect("noGenAssigned");
			setOldGen("noGenAssigned");
			return;
		}
		const q = doc(db, "generations", id);
		const qSnap = await getDoc(q);
		if (qSnap.exists()) {
			setGen(qSnap.data());
			setGenSelect(qSnap.data().id);
			setOldGen(qSnap.data().id);
		} else {
			return null;
		}
	};

	const getMaterialDates = async (id: string) => {
		// Retrieve an array containing all the dates for the seminaries for the user's generation
		let materials = await getNumsAndDatesByGenId(id);
		setMaterialInfo(materials);
	};

	const getAge = (birthdate: string): string => {
		// Based on the date of birth of the user, return a formated date and age
		let dobArray: Array<string> = birthdate.split("-");
		let dobString: string | Date =
			dobArray[2] + "/" + dobArray[1] + "/" + dobArray[0][2] + dobArray[0][3];
		let dob: Date = new Date(
			dobArray[1] + "/" + dobArray[2] + "/" + dobArray[0][2] + dobArray[0][3]
		);
		let today: Date = new Date();
		let years: number = today.getFullYear() - dob.getFullYear();
		if (today < new Date(today.getFullYear(), dob.getMonth(), dob.getDate())) {
			years--;
		}
		return `${years}`;
	};
	const deleteUser = async () => {
		setLoader(true);
		if (
			window.confirm("¿Estas seguro que deseas eliminar este estudiante de su generación actual?")
		) {
			const studentRef = doc(db, "users", userProfile.id);
			await updateDoc(studentRef, {
				generationId: "",
				matricula: "",
				schedule: "",
			});
			const { collaborator, user } = props;
			const tempUser = { id: user.id, name: user.name, email: user.email };
			const tempCollaborator = {
				name: collaborator.name,
				id: collaborator.id,
				...(collaborator.userType === 4
					? { role: collaborator.collaborator_rol }
					: { role: "admin" }),
				email: collaborator.email,
			};
			let tempGenName = await getGenerationNameById(user.generationId);
			const tempGen = { id: user.generationId, name: tempGenName };
			await genLog(tempCollaborator, tempUser, undefined, tempGen);
			const genRef = doc(db, "generations", gen.id);
			await updateDoc(genRef, {
				limit: gen.limit + 1,
			});
			if (props.setRefresh && props.refresh) {
				props.setRefresh(!props.refresh);
			}
			props.onHide();
		}
		setLoader(false);
	};

	const saveStudent = async () => {
		setLoader(true);
		if (!window.confirm("¿Estás seguro que deseas guardar los cambios?")) {
			setLoader(false);
			return;
		}

		try {
			let studentRef = doc(db, "users", userProfile.id);
			const q = query(collection(db, "users"), where("matricula", "==", userProfile.matricula));
			const querySnapshot = await getDocs(q);

			if (querySnapshot.size > 0 && matricula !== userProfile.matricula) {
				window.alert("Ya existe esta matrícula");
				setLoader(false);
				return;
			}

			const updatedData = {
				birthdate: userProfile.birthdate ?? "",
				name: userProfile.name,
				secondaryEmail: userProfile.secondaryEmail ?? "",
				phone: userProfile.phone,
				generationId: userProfile.generationId,
				matricula: userProfile.matricula,
				promotion: userProfile.promotion,
				adminNote: userProfile.adminNote !== undefined ? userProfile.adminNote : "",
			};

			if (userProfile.generationId !== oldGen && genSelect !== undefined) {
				const { collaborator, user } = props;
				const tempUser = { id: userProfile.id, name: userProfile.name, email: userProfile.email };
				const tempCollaborator = {
					name: collaborator.name,
					id: collaborator.id,
					role: collaborator.userType === 4 ? collaborator.collaborator_rol : "admin",
					email: collaborator.email,
				};
				const tempOldGen =
					oldGen === "noGenAssigned"
						? undefined
						: { id: oldGen, name: await getGenerationNameById(oldGen) };
				const tempNewGen = userProfile.generationId
					? { id: genSelect, name: await getGenerationNameById(genSelect) }
					: undefined;
				await genLog(tempCollaborator, tempUser, tempNewGen, tempOldGen);
			}

			if (userProfile.type === "pre-register") {
				const email = userProfile.email.trim().toLowerCase();
				const q = query(collection(db, "users"), where("email", "==", email));
				const otherUserSnapshot = await getDocs(q);

				if (otherUserSnapshot.size > 0) {
					const nonPreRegisterUser = otherUserSnapshot.docs.find(
						(doc) => doc.data().type !== "pre-register"
					);
					if (nonPreRegisterUser) {
						studentRef = doc(db, "users", nonPreRegisterUser.id);
						await updateDoc(studentRef, { adminNote: userProfile.adminNote });
					} else {
						Object.keys(updatedData).forEach(
							(key) => updatedData[key] === undefined && delete updatedData[key]
						);
						await updateDoc(studentRef, updatedData);
					}
				} else {
					Object.keys(updatedData).forEach(
						(key) => updatedData[key] === undefined && delete updatedData[key]
					);
					await updateDoc(studentRef, updatedData);
				}
			} else {
				await updateDoc(studentRef, updatedData);
			}

			if (props.updateGDT) props.updateGDT(gen);
			if (props.setRefresh && props.refresh) props.setRefresh(!props.refresh);
			setLoader(false);
			props.onHide();
		} catch (error) {
			console.error("Error al guardar los cambios:", error);
			setLoader(false);
		}
	};

	const deletePayment = async (id: string) => {
		if (window.confirm("¿Estás seguro que deseas eliminar este pago?")) {
			await deleteDoc(doc(db, "paymentHistory", id)).then(() => {
				if (props.setRefresh && props.refresh) {
					props.setRefresh(!props.refresh);
				}
				props.onHide();
			});
		}
	};

	const handleGenSelect = (genId: string) => {
		if (genId !== "noGenAssigned") {
			let tempGen: any = gens.filter((e) => e?.id === genId);
			setGenSelect(genId);
			setUserProfile({ ...userProfile, generationId: genId, schedule: tempGen[0].schedule });
			return;
		} else {
			setGenSelect("noGenAssigned");
			setUserProfile({ ...userProfile, generationId: "", schedule: "" });
			return;
		}
	};

	useEffect(() => {
		if (props.id && props.show === true) {
			getUserInfo(props.id);
			getAllGensInfo();
		}
	}, [props.show]);

	useEffect(() => {
		if (props.user && props.show === true) {
			getAllGensInfo();
			setUserProfile(null);
			if (!userProfile) {
				setUserProfile(props.user);
				setMatricula(props.user.matricula);
				getGenInfo(props.user.generationId);
			}
		} else if (props.show === false) {
			setUserProfile(null);
			setVocabularyStreak([]);
		}
	}, [props.show]);

	useEffect(() => {
		if (gen !== null && userProfile !== null && !materialInfo) {
			setUserId(userProfile.id);
			getMaterialDates(userProfile.generationId);
		}
		if (materialInfo !== null && userProfile !== null && gen !== null) {
			setnoUserAccount(true);
			const userId = props.user?.userId || props.user?.id || props.id;
			checkPaymentInfo(userId);
		}
	}, [gen, userProfile, materialInfo]);

	return (
		<Modal show={props.show} onHide={props.onHide} dialogClassName="user-profile-modal">
			<Modal.Header className="header" closeButton>
				<Modal.Title className="title">
					<h3 style={{ fontSize: "2rem !important" }}>Detalles</h3>
					<div className="surveys">
						<Tooltip content="Ver formulario de registro" direction="left">
							{userProfile?.formAnswered === true ? (
								<img
									src={SurveyIcon1}
									alt="Icono de encuesta 1"
									onClick={() => setFormModal(true)}
								/>
							) : (
								""
							)}
						</Tooltip>
						<Tooltip content="Ver encuesta de experiencia" direction="left">
							{userProfile?.formAnswered2 === true ? (
								<img
									src={SurveyIcon2}
									alt="Icono de encuesta 2"
									onClick={() => setFormModal2(true)}
								/>
							) : (
								""
							)}
						</Tooltip>
					</div>
				</Modal.Title>
			</Modal.Header>
			{userProfile !== null && (
				<Modal.Body className="modal-body">
					<div className="name-streak">
						<h2>{userProfile?.name}</h2>
						{noUserAccount ? (
							<StreakFlame
								user={userProfile}
								vocabularyStreak={vocabularyStreak}
								isLoading={isLoading}
								studiedDays={studiedDays}
							/>
						) : null}
					</div>
					<label className="modal-labels" htmlFor="student-name">
						Nombre:
					</label>
					<div>
						<input
							className="input1"
							name="student-name"
							defaultValue={userProfile?.name}
							id="student-name"
							type="text"
							placeholder="Nombre del estudiante"
							onChange={(e) => setUserProfile({ ...userProfile, name: e.target.value })}
						/>
					</div>
					{userProfile?.birthdate !== undefined && userProfile?.birthdate.length > 0 && (
						<>
							<label className="modal-labels" htmlFor="student-age">
								Edad:
							</label>
							<div className="input-age">
								<input
									type="date"
									defaultValue={userProfile?.birthdate ? userProfile.birthdate : ""}
									onChange={(e) => setUserProfile({ ...userProfile, birthdate: e.target.value })}
								/>
								&nbsp;&nbsp;
								{userProfile.birthdate ? "(" + getAge(userProfile.birthdate) + ")" + " años" : ""}
							</div>
						</>
					)}
					<label className="modal-labels" htmlFor="student-matricula">
						Matrícula:
					</label>
					<div>
						<input
							className="input1"
							name="student-matricula"
							defaultValue={userProfile?.matricula ? userProfile?.matricula : ""}
							id="student-matricula"
							type="text"
							onChange={(e) => setUserProfile({ ...userProfile, matricula: e.target.value })}
						/>
					</div>
					<label className="modal-labels" htmlFor="student-email">
						Correo:
					</label>
					<div>
						<input
							className="input1"
							name="student-email"
							defaultValue={userProfile?.email}
							id="student-email"
							type="text"
							style={{ border: "2px solid transparent" }}
							readOnly
						/>
					</div>
					<label className="modal-labels" htmlFor="student-secondaryEmail">
						Correo de grabaciones:
					</label>
					<div>
						<input
							className="input1"
							name="student-secondaryEmail"
							defaultValue={userProfile?.secondaryEmail ? userProfile?.secondaryEmail : ""}
							id="student-secondaryEmail"
							type="text"
							onChange={(e) => setUserProfile({ ...userProfile, secondaryEmail: e.target.value })}
						/>
					</div>
					<label className="modal-labels" htmlFor="phone">
						Celular:
					</label>
					<div>
						<PhoneInput
							labels={es}
							international={true}
							name="phone"
							id="phone"
							value={userProfile?.phone}
							onChange={(e) => setUserProfile({ ...userProfile, phone: e })}
							className="phone-input"
							limitMaxLength={true}
						/>
					</div>

					<p className="modal-labels">Estatus de pago</p>
					{paymentInfo !== null &&
						paymentInfo.map((payment, index) => {
							return (
								<div className="payments-info">
									<p className="module">{payment?.name}</p>
									{payment?.status === "Pagado" ? (
										<img src={CheckmarkIcon} />
									) : (
										<p
											className="module-status"
											style={
												payment?.status === "Retrasado" ? { color: "#CA5050" } : { color: "gray" }
											}
										>
											{payment?.status}
										</p>
									)}
								</div>
							);
						})}

					<p className="modal-labels">Historial de pagos</p>
					{userProfile?.promotion && (
						<p style={{ textAlign: "end" }}>
							<a className="promo-text">Usuario con promoción activa</a>
						</p>
					)}
					<div className="payments-history">
						<div className="total-pay">
							<p> {paymentHistory.length > 0 ? "Total" : " "}</p>
							<p>{totalPaid}</p>
						</div>
						<div className="payments">
							{paymentHistory.length > 0 && (
								<>
									{paymentHistory.map((e, index) => {
										return e.image !== "" ? (
											<a
												href={e.image}
												target="blank"
												style={{ color: "#000000" }}
												data-tooltip-id="my-first-tooltip"
												data-tooltip-content={getPaymentType(
													e.type,
													e.acceptedByName,
													e.acceptDate
												)}
												data-tooltip-place="top"
											>
												<div className="payment" key={"payment-" + index}>
													<img
														src={TrashIcon}
														onClick={() => deletePayment(e.id)}
														style={{
															cursor: "pointer",
															width: "1.25rem",
															marginRight: 5,
															filter:
																"invert(39%) sepia(41%) saturate(777%) hue-rotate(313deg) brightness(101%) contrast(101%)",
														}}
														alt={"delete_icon"}
													/>
													<p className="">
														Realización: {e.date === "NaN/NaN/NaN" ? newDate : e.date}
													</p>
													<p className="amount-and-concept">
														{e.amount} <br />
														{e.concept}
													</p>
													<ReactTooltip id="my-first-tooltip" />
												</div>
											</a>
										) : (
											<div
												className="payment"
												key={"payment-" + index}
												data-tooltip-id="my-second-tooltip"
												data-tooltip-content={getPaymentType(
													e.type,
													e.acceptedByName,
													e.acceptDate
												)}
												data-tooltip-place="top"
											>
												<img
													src={TrashIcon}
													onClick={() => deletePayment(e.id)}
													style={{
														cursor: "pointer",
														width: "1.25rem",
														marginRight: 5,
														filter:
															"invert(39%) sepia(41%) saturate(777%) hue-rotate(313deg) brightness(101%) contrast(101%)",
													}}
													alt="delete_icon"
												/>
												<p className="">Realización: {e.date}</p>
												<p className="amount-and-concept">
													{e.amount} <br />
													{e.concept}
												</p>
												<ReactTooltip id="my-second-tooltip" />
											</div>
										);
									})}
								</>
							)}
						</div>
					</div>

					{/* {gen !== null && gen.name !== undefined && genSelect !== undefined && (
            <> */}
					<label className="modal-labels" htmlFor="gen">
						Generación:
					</label>
					<select
						name="gen"
						id="gen"
						value={genSelect}
						onChange={(e) => handleGenSelect(e.target.value)}
						className="gen-selector"
					>
						{gens.map((element, index) => {
							return (
								<option value={element?.id}>
									{element?.name.replace(
										"Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ",
										""
									)}
								</option>
							);
						})}
						<option value={"noGenAssigned"}>Sin generación asignada</option>
					</select>
					{/* </>
        )} */}
					<label className="modal-labels" htmlFor="student-notes">
						Notas:
					</label>
					<div>
						<textarea
							rows={5}
							defaultValue={userProfile.adminNote}
							style={{ resize: "none" }}
							name="student-notes"
							id="student-notes"
							className="input1"
							onChange={(e) => setUserProfile({ ...userProfile, adminNote: e.target.value })}
							//maxLength={350}
						/>
					</div>
					<div className="promotion">
						<label className="promotion-label" htmlFor="promotion">
							Promoción:
						</label>
						<input
							type="checkbox"
							name="promotion"
							id="promotion"
							checked={userProfile.promotion}
							onChange={(e) => setUserProfile({ ...userProfile, promotion: e.target.checked })}
						/>
					</div>
					<div className="save-delete">
						<button className="delete-btn" onClick={deleteUser} disabled={loader}>
							Eliminar
						</button>
						<button className="save-btn" onClick={saveStudent} disabled={loader}>
							Guardar
						</button>
					</div>
					<FormModal
						show={formModal}
						user={userProfile}
						onHide={() => setFormModal(false)}
						read={true}
					/>
					<FormModal2
						show={formModal2}
						user={userProfile}
						onHide={() => setFormModal2(false)}
						read={true}
					/>
				</Modal.Body>
			)}
		</Modal>
	);
}
