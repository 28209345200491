import { useEffect, useState } from "react";
import "./Locations.scss";
import { ReactComponent as LocationIcon } from "@assets/icons/location.svg";
import { ReactComponent as ScheduleIcon } from "@assets/icons/calendar.svg";
import { ReactComponent as HourIcon } from "@assets/icons/hour.svg";
import { doc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useScrollContext } from "@hooks/HomeActions";
export const Locations = () => {
	const [locations, setLocations] = useState<Array<{ desc: string; name: string }>>([]);
	const { locationsRef } = useScrollContext();

	const getLocations = async () => {
		const docRef = doc(db, "dynamic_copies", "VD4rog46SD2Z3xgBEVRE", "location", "wV56s6CwtvwBrtTaB3to");
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const { locations, locations2 } = docSnap.data();
			const temp = [...locations, ...locations2];

			setLocations(temp);
		}
	};

	useEffect(() => {
		getLocations();
	}, []);

	return (
		<div className="locations-main-container" ref={locationsRef}>
			<ul className="locations-list-container">
				{locations.map((location, i) => {
					return <LocationItem location={location} key={location.name} />;
				})}
			</ul>
			<div className="map-container">
				<iframe
					title="ALI map"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.608992542102!2d-99.14957798455579!3d19.386076086910858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff0071448073%3A0xc38aa5d996d41ce2!2sEje%20Central%20L%C3%A1zaro%20C%C3%A1rdenas%20829%2C%20Narvarte%20Poniente%2C%20Benito%20Ju%C3%A1rez%2C%2003020%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1622169124699!5m2!1ses-419!2smx"
					width="100%"
					height="100%"
					loading="lazy"
					frameBorder="0"
				></iframe>
			</div>
		</div>
	);
};

const LocationItem = ({ location }) => {
	const [active, setActive] = useState<boolean>(false);

	return (
		<li className={active ? "active" : ""} onClick={() => setActive(!active)}>
			<p className="name">
				<LocationIcon /> {location.name}
			</p>
			{location.schedules?.map((schedule, i) => {
				return (
					<div className="schedule" key={schedule.day + "-" + i}>
						<p>
							<ScheduleIcon /> {schedule.day}
						</p>
						<p>
							{schedule.hours?.map((hour, i) => {
								return (
									<span key={hour + schedule.day + "-" + i}>
										<HourIcon />{" "}
										{hour + (i === schedule.hours.length - 1 ? "." : i === schedule.hours.length - 2 ? " y de " : ", ")}
									</span>
								);
							})}
						</p>
					</div>
				);
			})}
			{location.name.includes("Narvarte") && <span className="special">*Por el momento es la única sede física laborando.</span>}
		</li>
	);
};
