import "./WordInfoModal.scss";
import "react-tooltip/dist/react-tooltip.css";
import Modal from "react-bootstrap/Modal";
// import { Tooltip as BsTooltip } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { WordRowCard } from "../WordRowCard/WordRowCard";
import {
	getArmByDifficultByNumber,
	getDominatedIconByNumber,
	getDifficultStringSpanish,
	getInformationByLanguage,
} from "../PersonalizedTest/functions/functions";
import { returnFlag, romanize } from "functions/Functions";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { useEffect, useState } from "react";
import Tooltip from "functions/Tooltip/Tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { WarningTypeSelector } from "@containers/VocabularySeminaryContainer/components/Test/components/WarningTypeSelector/WarningTypeSelector";
import {
	returnWarningBorderColor,
	returnWarningTypeImage,
	returnWarningTypeTitle,
} from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import parse from "html-react-parser";

export function WordInfoModal(props) {
	const [warningType, setWarningType] = useState<string>("warning");
	const [tempWord, setTempWord] = useState<any>("");

	let firstStudied = "";
	let lastStudied = "";
	if (props.word.dominated === null) {
		props.word.dominated = 0;
	}
	const seminaries = props.word.seminarios !== undefined ? props.word.seminarios.split(", ") : [];
	const correctRatio = parseInt(((props.word.times_correct_answer / props.word.times_answered) * 100).toFixed());
	if (props.word.lastStudied) {
		lastStudied = props.word.lastStudied.split("/");
	}
	if (props.word.firstStudied) {
		firstStudied = props.word.firstStudied.split("/");
	}

	useEffect(() => {
		if (props.word.native_word) {
			setTempWord(props.word);
		}
	}, [props.word, props.show]);

	
	return (
		<Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header className="edit-word-info-modal-header" closeButton>
				<BsInfoCircleFill className="info-icon" />
				<Modal.Title id="contained-modal-title-vcenter">{getInformationByLanguage(props.word?.language)}</Modal.Title>
				{returnFlag(props.word?.language)}
			</Modal.Header>
			<Modal.Body>
				<WordRowCard
					word={props.word}
					language={props.word.language}
					answerType={"normal"}
					setState={props.setState}
					state={props.state}
					index={props.index}
					countRefresh={props.countRefresh}
					setCountRefresh={props.setCountRefresh}
				/>
				<div className="grid-info">
					<div
						className="grid-info-item item-category"
						style={props.word.singular_category === undefined || props.word.singular_category === null ? { display: "none" } : {}}
					>
						<b>Categoría:</b> &nbsp; {props.word.singular_category}
					</div>
					{props.word.times_answered === 0 && (
						<>
							<b style={{ textAlign: "center", gridRow: "1/3", gridColumn: "2" }}>Dificultad</b>
							<b style={{ textAlign: "center", gridRow: "2", gridColumn: "2", color: "gray" }}>Sin datos de estudio</b>
						</>
					)}
					<div className="grid-info-item item-difficulty" style={props.word?.times_answered === 0 ? { display: "none" } : {}}>
						<b>Dificultad</b>
						<div className="difficulty-container">
							<div className="diff-icon">{getArmByDifficultByNumber(props.word.custom_difficulty)} </div>
							<span>{getDifficultStringSpanish(props.word.custom_difficulty)}</span>
						</div>
						<ReactTooltip
							anchorSelect=".diff-icon"
							content={`${props.word.times_correct_answer}/${props.word.times_answered}`}
							data-tooltip-offset={10}
							place="top"
							style={{ opacity: 1, backgroundColor: "black", padding: "8px", fontSize: "0.7rem" }}
							
						/>
					</div>
					<div className="grid-info-item item-correct-ratio" style={isNaN(correctRatio) ? { display: "none" } : {}}>
						<b>Tasa de aciertos:</b>
						{correctRatio}%
					</div>

					<div
						className="grid-info-item item-subcategory"
						style={
							!props.word.sub_category || props.word.sub_category === undefined || props.word.sub_category === null
								? { display: "none" }
								: {}
						}
					>
						<b>Subcategoría:</b> &nbsp; {props.word.sub_category}
					</div>
					<div className="grid-info-item item-appearsin" style={!props.word.seminarios ? { display: "none" } : {}}>
						<b>Aparece en:</b> &nbsp;{" "}
						{seminaries &&
							seminaries.map((e, index) => {
								return romanize(e) + (seminaries.length - 1 > index ? ", " : "");
							})}
					</div>
					<div
						className="grid-info-item item-studied-last"
						style={props.word.firstStudied === undefined || props.word.firstStudied === null ? { display: "none" } : {}}
					>
						<b>Estudiada por primera vez:</b> &nbsp; {firstStudied[1]}/{firstStudied[0]}/{firstStudied[2]}
					</div>

					<div
						className="grid-info-item item-domain"
						style={!(props.word.dominated !== undefined && props.word.dominated !== null) ? { display: "none" } : {}}
					>
						<b>Nivel de dominio</b>
						<div className="domination-balls-container">
							{props.word.times_answered === 0 && props.word.dominated === 0 ? (
								<>
									<div
										className={"progress-ball one " + (props.word.dominated === -3 ? "active" : "")}
										style={{ backgroundColor: "rgb(71 71 71)" }}
									></div>
									<div
										className={"progress-ball two " + (props.word.dominated === -2 ? "active" : "")}
										style={{ backgroundColor: "rgb(110 110 110)" }}
									></div>
									<div
										className={"progress-ball three " + (props.word.dominated === -1 ? "active" : "")}
										style={{ backgroundColor: "rgb(150 150 150)" }}
									></div>
									<Tooltip content="Aún no has estudiado este término." direction="bottom">
										<div
											className={"progress-ball four " + (props.word.dominated === 0 ? "active" : "")}
											style={{
												backgroundColor: "rgb(190 190 190)",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												color: "white",
												fontFamily: "sans-serif",
												fontSize: "0.9rem",
											}}
										>
											?
										</div>
									</Tooltip>
									<div
										className={"progress-ball five " + (props.word.dominated === 1 ? "active" : "")}
										style={{ backgroundColor: "rgb(150 150 150)" }}
									></div>
									<div
										className={"progress-ball six " + (props.word.dominated === 2 ? "active" : "")}
										style={{ backgroundColor: "rgb(110 110 110)" }}
									></div>
									<div
										className={"progress-ball seven " + (props.word.dominated === 3 ? "active" : "")}
										style={{ backgroundColor: "rgb(71 71 71)" }}
									></div>
								</>
							) : (
								<>
									<div className={"progress-ball one " + (props.word.dominated === -3 ? "active" : "")}></div>
									<div className={"progress-ball two " + (props.word.dominated === -2 ? "active" : "")}></div>
									<div className={"progress-ball three " + (props.word.dominated === -1 ? "active" : "")}></div>
									<div className={"progress-ball four " + (props.word.dominated === 0 ? "active" : "")}></div>
									<div className={"progress-ball five " + (props.word.dominated === 1 ? "active" : "")}></div>
									<div className={"progress-ball six " + (props.word.dominated === 2 ? "active" : "")}></div>
									<div className={"progress-ball seven " + (props.word.dominated === 3 ? "active" : "")}></div>
								</>
							)}
						</div>
					</div>

					<div
						className="grid-info-item item-studied-first"
						style={props.word.lastStudied === undefined || props.word.lastStudied === null ? { display: "none" } : {}}
					>
						<b>Estudiada por última vez:</b> &nbsp; {lastStudied[1]}/{lastStudied[0]}/{lastStudied[2]}
					</div>
					<div
						className="grid-info-item item-studied-first"
						style={props.word.times_answered === undefined || props.word.times_answered === null ? { display: "none" } : {}}
					>
						<b>Total de veces estudiado:</b> &nbsp; {props.word.times_answered}
					</div>
					{/* <div className="grid-info-item empty"></div> */}
				</div>
				<WarningTypeSelector setWarningType={setWarningType} word={props.word} warningType={warningType} />
				<div className="warnings-parent-container">
					{props.word?.[warningType] !== undefined && (
						<>
							{props.word[warningType] !== "undefined" &&
								props.word[warningType].length > 0 &&
								props.word[warningType] !== '<p class="pre"><br></p>' &&
								props.word[warningType] !== '<p class="pre"><br></p><p class="pre"><br></p>' && (
									<div className={"warning-container "} style={{ borderColor: returnWarningBorderColor(warningType) }}>
										<h3 style={{ color: returnWarningBorderColor(warningType) }}>{returnWarningTypeTitle(warningType)}</h3>
										<div className="ql-editor">{parse(props.word[warningType], { trim: false })}</div>
										{returnWarningTypeImage(warningType)}
									</div>
								)}
						</>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer className="justify-content-center">
				<button className="action-btn linear-bg" onClick={props.onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
