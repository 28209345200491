import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { CountdownReference, Location } from "interfaces/Generations";

const MISC = "miscellaneous";
const GENS = "generations";
const DYNAMIC_COPIES = "dynamic_copies";

// Servicios relacionados a la coleccion "miscellaneous" (varios) en Firestore.

const generationsRef = doc(db, MISC, GENS);

export const getGenerationsLocations = async (): Promise<Location[]> => {
	try {
		const locationsReference = doc(db, MISC, GENS);
		const documentSnapshot = await getDoc(locationsReference);
		return documentSnapshot.data()?.sedes as Location[];
	} catch (error: any) {
		throw new Error(error.message);
	}
};

// Con esta funcion se obtiene la fecha limite de inscripcion como un objeto Date, o null
export const getEnrollmentLimitDate = async (): Promise<Date | null> => {
	const datesParentDocumentId = "Hh06tq4Yk4XhTThIabUp";
	const datesDocumentId = "sMMtjz1VAt73JdEgG2nt";
	const datesDocumentReference = doc(db, DYNAMIC_COPIES, datesParentDocumentId, "fechas", datesDocumentId);
	try {
		const datesDocumentData = (await getDoc(datesDocumentReference)).data();
		if (datesDocumentData != undefined) {
			return new Date(datesDocumentData.fechaInicioInscripcion);
		} else return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getCountDownReference = async ():Promise<CountdownReference|null> => {
	const countdownReferenceDocumentReference = doc(db,MISC,GENS);
	try {
		const countdownReferenceData = (await getDoc(countdownReferenceDocumentReference)).data()
		if (countdownReferenceData){
			return countdownReferenceData.countdownReference as CountdownReference
		} else return null
	} catch (error) {
		console.error(error)
		return null
	}
	
}

export const updateCountDownReference = async(newCountdownReference:string):Promise<void> => {
	const countdownReferenceDocumentReference = doc(db,MISC,GENS);
	try {
		await updateDoc(countdownReferenceDocumentReference, {countdownReference: newCountdownReference})
	} catch (error) {
		console.error(error)
	}
}
