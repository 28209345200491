import { useAuth } from "@hooks/useAuth";
import { differenceInCalendarDays } from "date-fns";
import { DocumentData, DocumentSnapshot, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

type StreakContextType = {
	vocabularyStreak: Array<string>;
	studiedDays: Array<string>;
	isLoading: boolean;
	validateStreak: () => void;
	addDateStreak: () => void;
};

const StreakContext = createContext<StreakContextType>({
	vocabularyStreak: [],
	studiedDays: [],
	isLoading: false,
	validateStreak: () => {},
	addDateStreak: () => {},
});

export const useStreakContext = () => {
	return useContext(StreakContext);
};

const VocabularyStreakContextProvider = ({ children }: { children: ReactNode }) => {
	const [vocabularyStreak, setVocabularyStreak] = useState<Array<string>>([]);
	const [studiedDays, setStudiedDays] = useState<Array<string>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { user } = useAuth();

	useEffect(() => {
		if (user) {
			getStreak();
		}
	}, [user]);

	useEffect(() => {
		if (vocabularyStreak.length > 0) {
			validateStreak();
		} else {
			setIsLoading(false);
		}
	}, [vocabularyStreak]);

	const getStreak = async () => {
		const userDocRef = doc(db, "users", user.id);
		setIsLoading(true);
		const qSnap: DocumentSnapshot<DocumentData> = await getDoc(userDocRef);
		if (qSnap.exists()) {
			const data: any = {
				id: qSnap.id,
				...qSnap.data(),
			};
			if (data.vocabularyStreak !== undefined) {
				setVocabularyStreak(data.vocabularyStreak);
			} else {
				await updateDoc(userDocRef, { vocabularyStreak: [] });
			}

			if (data.studiedDays !== undefined) {
				setStudiedDays(data.studiedDays);
			} else {
				await updateDoc(userDocRef, { studiedDays: [] });
				setIsLoading(false);
			}
		}
	};

	const clearStreak = async () => {
		const userDocRef = doc(db, "users", user.id);
		await updateDoc(userDocRef, { vocabularyStreak: [] });
		setVocabularyStreak([]);
	};

	const validateStreak = async () => {
		const temp = [...vocabularyStreak];
		const today = new Date();
		const lastDate = new Date(temp[temp.length - 1]);
		const differenceDays = differenceInCalendarDays(today, lastDate);
		console.log("days without studying", differenceDays);
		if (differenceDays >= 2) {
			console.log("you have lost your streak");
			clearStreak();
		}
		setIsLoading(false);
	};

	const addDateStreak = async () => {
		let temp = [...vocabularyStreak];
		let tempStudied = [...studiedDays];

		const date = new Date();
		let lastDate = temp.length > 0 ? new Date(temp[temp.length - 1]) : null;

		if (!lastDate || differenceInCalendarDays(date, lastDate) === 1) {
			const userDocRef = doc(db, "users", user.id);
			const stringDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

			temp.push(stringDate);
			tempStudied.push(stringDate);

			await updateDoc(userDocRef, {
				vocabularyStreak: temp,
				studiedDays: tempStudied,
			}).then(() => {
				setVocabularyStreak(temp);
				setStudiedDays(tempStudied);
			});
		}
	};

	return (
		<StreakContext.Provider
			value={{
				vocabularyStreak,
				isLoading,
				studiedDays,
				validateStreak,
				addDateStreak,
			}}
		>
			{children}
		</StreakContext.Provider>
	);
};

export default VocabularyStreakContextProvider;
