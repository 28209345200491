export type Asueto = {
	name: string;
	date: SimpleDate;
};

export type CreatedAt = {
	seconds: number;
	nanoseconds: number;
};

export enum GenTypes {
	InPerson = "in-person",
	OnlineAM = "online-am",
	OnlinePM = "online-pm",
	Online = "online",
}

export type GenTypeInPerson = GenTypes.InPerson;
export type GenTypeOnline = GenTypes.OnlineAM | GenTypes.OnlinePM | GenTypes.Online;
export type GenType = GenTypeInPerson | GenTypeOnline;

export type SimpleDate = `${string}/${string}/${string}` | undefined;

/// Tipado para generaciones, con id de Firebase
export type TGeneration = {
	id: string; // id del documento de la generacion en Firebase
	active: boolean; //	La generacion esta abierta a inscripciones?
	type: GenType;
	cirilic?: string; //	Caracter cirilico que identifica a la generacion
	initialDate: SimpleDate;
	genCopyId?: string | undefined | null; // Si la generacion es presencial, puede haberse creado con copia de materiales de otra generacion existente
	asuetos: Asueto[];
	classDay: string; // Dia que se imparte el curso en esta generacion
	startHour: string;
	visible: boolean;
	endHour: string;
	promoDate?: SimpleDate | undefined;
	name: string;
	sede: string;
	createdAt: CreatedAt | Date;
	schedule: string;
	limit: number;
	regularPrice: number;
	promoPrice: number;
	regularPriceUSD:number;
	promoPriceUSD:number;
	countdownEnabled?: boolean;	// Campo para activar o desactivar cuenta regresiva en tabla modalidades [Modes.tsx]
	hoursPerWeek: number; // Horas semanales de curso
	totalHours: number;
};

/// Tipado para generaciones nuevas (al crearlas)
export type TNewGeneration = Omit<TGeneration, "id">;

// **********************************************************************
// Funciones relacionadas a estos tipados

// Función para verificar si un tipo de generación es en línea.
export const isGenOnline = (value: string): value is GenTypeOnline => {
	return value === GenTypes.OnlineAM || value === GenTypes.OnlinePM || value === "online";
};

// Función para verificar si un tipo de generación es presencial.
export const isGenInPerson = (value: string): value is GenTypeInPerson => {
	return value === GenTypes.InPerson;
};

// Funcion para saber si un string es un GenType valido
export const isValidGenType = (value:string):boolean => {
	return Object.values(GenTypes).includes(value as GenTypes)
}

// Map de GenTypes con su texto en español
export const GenerationTypesToSpanishModalitiesTable: { [key: string]: string } = {
	[GenTypes.InPerson]: "PRESENCIAL",
	[GenTypes.OnlineAM]: "EN LÍNEA",
	[GenTypes.OnlinePM]: "EN LÍNEA",
	[GenTypes.Online]: "EN LÍNEA",
};

// Miscellaneous

export type Location = {
	name: string;
	type: "online" | "presencial";
};

export const GenerationTypesToSpanish: { [key: string]: string } = {
	[GenTypes.InPerson]: "Presencial",
	[GenTypes.OnlineAM]: "En línea matutino",
	[GenTypes.OnlinePM]: "En línea vespertino",
	[GenTypes.Online]: "En línea",
};

export enum CountdownReference {
	InitialGenerationDate = "initialDate",
	EnrollmentLimitDate = "enrollmentLimitDate",

}