import axios from "axios";
import { quizWords } from "interfaces/Interfaces";

export const getUsers = async (userId: string) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `user/${userId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
		});
};

export const createUser = async (firebaseId: string, name: string) => {
	return axios.post(process.env.REACT_APP_API_URL + "user", { firebaseId, name }).then((res) => {
		return res.data.data;
	});
};

export const getWordSeminaryInfo = async (word_seminary_id: number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `words/word_info/${word_seminary_id}`)
		.then((res) => {
			return res.data.word;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const addUserProgress = async (user_id: number, words: Array<quizWords>, date: string, timeToAnswer: number) => {
	return axios
		.post(process.env.REACT_APP_API_URL + "user/progress", { user_id, words, date, seconds: timeToAnswer })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getAllSeminaries = async (userId: number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary/${userId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getSeminaryData = async (userId: Number, seminaryId: Number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary/${userId}/${seminaryId === 0 ? 61 : seminaryId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getCategories = async (seminaryNum: Number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `categories/${seminaryNum}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
export const getAllCategories = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `categories`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getSubCategories = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `subcategories`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getSubCategoriesBySeminary = async (seminaryNum: number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `subcategories/${seminaryNum}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const wordsSeminary = async (seminaryNum: Number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary/admin/${seminaryNum}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const wordsSeminaryBookmarked = async (seminaryNum: Number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary/admin/${seminaryNum}`)
		.then((res) => {
			let bookmarkedWords: any = [];
			let ids: any = new Set();
			res.data.seminary.map((word, index) => {
				if (word.bookmark === 1 && !ids.has(word.id)) {
					ids.add(word.id);
					bookmarkedWords.push(word);
				}
			});
			return bookmarkedWords.length;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getSeminaries = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getLanguages = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `language/totalWords`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
export const getLanguage = async (id) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `language/${id}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const addWordDb = async (word) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `words`, { word })
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const addWordReport = async (date, seminary, word_id, comment, user_id) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `reports`, {
			date: date,
			seminary: seminary,
			word_data_id: word_id,
			comment: comment,
			user_id: user_id,
		})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getWordReports = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `reports`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const updateChecks = async (check, id) => {
	// Update checked status on a single report
	return axios
		.put(process.env.REACT_APP_API_URL + `reports/${id}`, { checked: check })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const deleteReport = async (id) => {
	return axios
		.delete(process.env.REACT_APP_API_URL + `reports/${id}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const deleteWord = async (id) => {
	return axios
		.delete(process.env.REACT_APP_API_URL + `words/${id}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const updateWord = async (word) => {
	return axios
		.put(process.env.REACT_APP_API_URL + `words/${word.word_data_id}`, { word })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
export const updateWordTest = async (word) => {
	return axios
		.put(process.env.REACT_APP_API_URL + `words/editWord`, { word: word })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const allMeanings = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `meanings`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getAllWords = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `words`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
export const getWordsSubFamily = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `words/develop/words_sub_family`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const excelWords = async (words: Array<any>) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `words/excel`, { words })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const getFamilies = async () => {
	return axios
		.get(process.env.REACT_APP_API_URL + `seminary/familias/duplicados/g`)
		.then((res) => {
			return res.data.familias;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const deletearFamiliasR = async (id: number) => {
	return axios
		.delete(process.env.REACT_APP_API_URL + `seminary/familias/repetidas/${id}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const saveAudio = async (wordId: number, audio: string) => {
	return axios
		.put(process.env.REACT_APP_API_URL + `words/audio/${wordId}`, { audio: audio })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
export const bookmarkWord = async (wordId: number, note: string) => {
	return axios
		.put(process.env.REACT_APP_API_URL + `words/bookmark/${wordId}`, { note: note })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const bookmarkWordFalse = async (wordId: number) => {
	return axios
		.put(process.env.REACT_APP_API_URL + `words/bookmark/false/${wordId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const isWordBookmarked = async (wordId: number) => {
	return axios
		.get(process.env.REACT_APP_API_URL + `words/bookmark/${wordId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const areWordsDominated = async (user_id: number, words: Array<quizWords>) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `words/dominatedWordsByArray/${user_id}`, { words: words })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};

export const importWordsZero = async (words: Array<any>, category: string) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `words/importWordsZero`, { words: words, category: category })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
