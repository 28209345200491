import { CheckmarkCircle } from "assets/Imports";
import "./PercentageBar.scss";

interface PercentageI {
	percentage: any;
	ViewPercentage?: boolean;
}
function PercentageBar({ percentage, ViewPercentage = true }: PercentageI) {
	return (
		<div className="percentage-bar-container">
			{percentage === 100 && <img src={CheckmarkCircle} alt="palomita de completado" />}
			{ViewPercentage ? <h3 style={percentage === 100 ? { color: "#ca5050" } : {}}>{Math.floor(percentage)}%</h3> : null}
			<div className="bar-container">
				<div className={"bar-progress " + (percentage === 100 ? "gold-bar" : "")} style={{ width: percentage + "%" }}>
					<div className="point-progress"></div>
				</div>
			</div>
		</div>
	);
}

export default PercentageBar;
