import { loadStripe } from "@stripe/stripe-js";
import "./styles.scss";
import React from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	Elements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");

const errorMessages = {
	payment_intent_invalid_parameter:
		"El plan de pagos seleccionado no es compatible con este método de pago.",
	card_declined: "Tarjeta declinada. Favor de contactarse con su banco para mayor información.",
	generic_decline: "Declinación genérica. Por favor, intente con otro método de pago.",
	insufficient_funds: "Fondos insuficientes. Intente con otra tarjeta o método de pago.",
	lost_card: "Tarjeta reportada como perdida. Por favor, contacte a su banco.",
	stolen_card: "Tarjeta reportada como robada. Por favor, contacte a su banco.",
};

export const StripeCheckoutModal = ({ isOpen, error, setConektaCardError, setError }) => {
	if (!isOpen) return null;

	const handleInputChange = (event) => {
		if (error !== "" && event.complete) {
			setError("");
			setConektaCardError(false);
		}
	};
	return (
		<>
			<div className="new-card-form-component">
				<div className="stripe-elements-container">
					<div className="input-container">
						<label className="fs-14 fw-bolder mb-1" htmlFor="card-number" style={{fontWeight: "bold"}}>
							Número de la tarjeta
						</label>
						<div className={error ? "is-invalid" : "input-element-parent"}>
							<CardNumberElement
								onChange={handleInputChange}
								id="card-number"
								
								options={{
									style: {
										base: {},
									},
									showIcon: true,
								}}
							/>
						</div>
					</div>
					<div className="card-complement">
						<div className="input-container">
							<label className="fs-14 fw-bolder mb-1" htmlFor="exp-date" style={{fontWeight: "bold"}}>
								Mes y año de expiración
							</label>
							<div className={error ? "is-invalid" : "input-element-parent"}>
								<CardExpiryElement options={{placeholder: "mm/yy"}} id="exp-date"  />
							</div>
						</div>
						<div className="input-container">
							<label className="fs-14 fw-semibold mb-1" htmlFor="cvc" style={{fontWeight: "bold"}}>
								CVC
							</label>
							<div className={error ? "is-invalid" : "input-element-parent"}>
								<CardCvcElement id="cvc" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default function StripeProviderWrapper({ children }) {
	return <Elements stripe={stripePromise}>{children}</Elements>;
}
