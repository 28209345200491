import { createContext, useContext, useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { consults } from "../containers/ConsultPostContainer/Interfaces/Interfaces";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";

export const SeminariesServiceContext = createContext({
	pastSeminaries: [],
	nextSeminaries: [],
	paidSeminaries: 0,
	seminaries: [],
});
const SeminariesServiceProvider = ({ children }) => {
	const history = useHistory();
	const location: any = useLocation();

	let user = useAuth().user;
	if (user === null) {
		history.push("/ingresar");
	} else {
		if (getAuth().currentUser === null) {
			history.push("/ingresar");
		} else {
			user = { ...user, id: getAuth().currentUser!.uid };
		}
	}
	const [pastSeminaries, setPastSeminaries] = useState([]);
	const [nextSeminaries, setNextSeminaries] = useState([]);
	const [paidSeminaries, setPaidSeminaries] = useState(0);
	const [seminaries, setSeminaries] = useState([]);
	const [loading, setLoading] = useState(true);

	function compare2(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	function compare(a, b) {
		if (a.num > b.num) {
			return -1;
		}
		if (a.num < b.num) {
			return 1;
		}
		return 0;
	}

	useEffect(() => {
		const getGen = async () => {
			if (user.generationId !== undefined && user.generationId !== "") {
				const docRef = doc(db, "generations", location.state && location.state.genId ? location.state.genId : user.generationId);
				const docSnap = await getDoc(docRef);
				getMaterials({ id: docSnap.id, ...docSnap.data() });
			} else {
				setLoading(false);
			}
		};

		const getMaterials = async (data) => {
			let nextMaterial: any = [];
			let pastMaterial: any = [];
			let allMaterial: any = [];
			const date = new Date();

			const q = query(collection(db, "generations", data.id, "material"));

			const querySnapshot = await getDocs(q);

			let i = 0;

			querySnapshot.forEach((doc) => {
				let materialDate = new Date();
				i++;
				// doc.data() is never undefined for query doc snapshots
				const data2: any = {
					...doc.data(),
					id: doc.id,
				};

				let materialDateSplit = data2.date.split("/");
				materialDate.setDate(materialDateSplit[0]);
				materialDate.setMonth(materialDateSplit[1]);
				materialDate.setFullYear(materialDateSplit[2]);
				materialDate.setHours(0, 0, 0);

				allMaterial.push(data2);
				if (date < materialDate) {
					nextMaterial.push(data2);
				} else {
					pastMaterial.push(data2);
				}
				if (i === querySnapshot.size) {
					nextMaterial.sort(compare2);
					pastMaterial.sort(compare2);
					allMaterial.sort(compare2);
					setNextSeminaries(nextMaterial);
					setSeminaries(allMaterial);
					getPayments(pastMaterial);
				}
			});
		};

		const getPayments = async (pastMaterial) => {
			const qry = query(collection(db, "paymentHistory"), where("userId", "==", user.id), where("verified", "==", true));
			const qrySnapshot = await getDocs(qry);

			let i = 0;
			let total = 0;
			let sesionesPagadas = 0;
			let sesionesActuales = pastMaterial.length;

			qrySnapshot.forEach(async (doc) => {
				i++;
				total = total + parseInt(doc.data().amount);
				if (i === qrySnapshot.size) {
					//sesiones pagadas, sesion actual,
					if (((total === 12000 || total === 15000) && qrySnapshot.size === 1) || user.promotion === true) {
						total = 18000;
					}
					sesionesPagadas = Math.floor(total / 300);

					setPaidSeminaries(sesionesPagadas);
					setPastSeminaries(pastMaterial.sort(compare));
					setLoading(false);
				}
			});
			if ((qrySnapshot.size === 0 && user.promotion === true) || user.userType === 2 || user.userType === 4) {
				total = 18000;
				sesionesPagadas = Math.floor(total / 300);
				setPaidSeminaries(sesionesPagadas);
				setPastSeminaries(pastMaterial.sort(compare));
				setLoading(false);
			} else if (qrySnapshot.size === 0) {
				setLoading(false);
			}
		};
		getGen();
	}, [location.state]);

	if (loading) {
		return (
			<div style={{ width: "100%", display: "flex", height: "50vh", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<SeminariesServiceContext.Provider
			value={{
				pastSeminaries: pastSeminaries,
				nextSeminaries: nextSeminaries,
				paidSeminaries: paidSeminaries,
				seminaries: seminaries,
			}}
		>
			{children}
		</SeminariesServiceContext.Provider>
	);
};

export default SeminariesServiceProvider;

export const useSeminariesService = () => useContext(SeminariesServiceContext);
